@charset "utf-8";
@import "./pretendardvariable.css";
/* TYPE, COMMON */
*{box-sizing:border-box !important;-webkit-box-sizing:border-box;-moz-box-sizing:border-box;-o-box-sizing:border-box;}
html,body{width:100%;height:100%;font-family:'Pretendard Variable';position:relative;}
html{overflow-y:auto;}
body,div,dl,dt,dd,ul,ol,ul,li,h1,h2,h3,h4,h5,form,fieldset,p,button,input,legend{margin:0;padding:0; border: 0;}
/* body{*word-break:break-all;word-break:break-all;-webkit-word-break:break-all;-moz-word-break:break-all;-o-word-break:break-all;} css 오류로 주석 처리 */
body{word-break:break-all}

body,h1,h2,h3,h4,h5,h6,input,textarea,select,option,button,pre{color:#222224;font-family: 'Pretendard Variable','돋움',dotum,helvetica,sans-serif;font-size:16px; outline:none;}
/* body,h1,h2,h3,h4,h5,h6,input,textarea,select,option,button,pre{color:#222224;font-family: "NotoSansJP", sans-serif;font-size:16px; outline:none;} */

span,label{display:inline-block;vertical-align:middle;}
img,fieldset,iframe{border:0 none !important;}
ul,li{list-style:none;}
i{text-decoration:none;}
input,select,button{vertical-align:middle;outline:none;}
img{vertical-align:middle;}
em,address,dfn,cite{font-style:normal}
a{color:#222224;text-decoration:none;display:block;outline:none !important;}
a:link,a:visited,a:active,a:hover,a:focus{color:#222224;text-decoration:none;}
label,button{cursor:pointer}
button{margin:0;padding:0;}
caption,legend{width:0;height:0;line-height:0;overflow:hidden;visibility:hidden;font-size:0;}
textarea{width:100%;height:110px;min-height:110px;padding:10px;border:1px solid #E6E6EB;margin:0;resize:none;}
textarea:hover{background:#e9eefc;}
textarea:focus{border:1px solid #2658E2 !important;}
textarea:active{border:1px solid #2658E2 !important;}
label,button {cursor:pointer;}
textarea, pre {overflow:auto;}
select::-ms-expand {display:none;/* 화살표 없애기 for IE10, 11*/ }
select{
	position:relative;padding:0 40px 0 16px;height:40px;font-size:16px;border:1px solid #E6E6EB;color:#313133;border-radius:8px;
    appearance:none;-webkit-appearance:none;-moz-appearance:none;-o-appearance:none;
	background: url('/public/images/icon_select.png') no-repeat right center #fff;
}
select:hover{background: url('/public/images/icon_select.png') no-repeat right center #e9eefc;}
select:focus{border:1px solid #2658E2 !important;background: url('/public/images/icon_select.png') no-repeat right center #fff;}
select:active{border:1px solid #2658E2 !important;background: url('/public/images/icon_select.png') no-repeat right center #fff;}


input{height:40px;padding:0 16px;border-bottom:1px solid #E6E6EB;vertical-align:top;}
input::-ms-input-placeholder{color:#77777A !important;font-size:16px;}
input::-webkit-input-placeholder{color:#77777A !important;font-size:16px;}
input::-moz-placeholder{color:#77777A !important;font-size:16px;}

/* input:hover{background:#e9eefc;} */
input:focus{background:#e1e7f5; border-radius: 5px;}
/* input:active{border:1px solid #2658E2 !important;background:#fff;} */

/*체크박스*/
input[type='checkbox']{display: none;/* 실제 체크박스 없애기 */}
input[type='checkbox'] + label::before {    /* 가짜 체크박스 만들기 */content:'';display:inline-block;width:17px;height:17px;vertical-align:middle;margin:0px 7.5px 0 0;background:url('/public/images/check_box_outline_blank.svg')no-repeat center;}
input[type='checkbox']:checked + label::before {    /* 가짜 체크박스 체크했을 때 */ background: url('/public/images/check_box.svg')no-repeat center;z-index:9;}

div.center{max-width:1400px;margin:0 auto;position:relative;padding:0 16px;}
div.center_search{width:90%;max-width:1648px;margin:0 auto;position:relative;padding:0 16px;}
/* div.recommend_detail_search_page div.center{width:100%;max-width:1648px;margin:0 auto;position:relative; padding: unset !important;} */

.onlyweb{display:block !important;}
.onlym{display:none !important;}
@media screen and (max-width: 1024px){
	.onlyweb{display:none !important;}
	.onlym{display:block !important;}
	.no_h_t header{display:none;}
	.no_h_t h1.board_tit{display:none;}
	.no_h_t section.board_area{padding-top:16px;}
	.no_h_t div.board_wrap{margin-top:0;}
}

@media screen and (max-width: 1024px){
	div.board_wrap_file{margin-top:0;}
}
@media (max-width: 768px){
	div.center{width:100%;}
	div.center_search{width:100%;padding-top: unset !important;padding-bottom: unset !important;}
	
}

.sc_stop{overflow:hidden !important;}

/* 버튼들 스타일 */
button.st_basic{border:1px solid #F0F0F5 !important;background:#F0F0F5;} /*height:36px;padding:0 16px;border-radius:24px;font-size:16px;color:#222224;font-weight:500;*/
button.st_basic:hover{background:#D7D7DB !important;}
button.st_basic:selected{background:#D7D7DB !important;}
button.st_basic:focus{background:#222224 !important;border:1px solid #fff !important;outline:1px solid #222224 !important;color:#fff !important;}
button.st_basic:active{background:#A8A8AD !important;outline:0 !important;color:#222224 !important;}
button.st_basic.on{background:#222224 !important;color:#fff;}
button:disabled{opacity:0.5;}
button.st_icon{border:0 !important;background:none !important;}
button.st_icon.gnb_search_bt{border:0 !important;background:#2658e2ba!important;}
button.st_icon.gnb_search_bt > img.white{filter: grayscale(100%) brightness(1000%) contrast(100%) !important;}
button.st_icon.gnb_search_bt > img.white:hover{filter: unset !important;}
button.st_icon.on{background:#222224 !important;color:#fff;}
button.st_icon:focus{outline:1px solid #000 !important;}
button.st_icon:hover{background:#D7D7DB !important;}
button.st_icon:active{background:#A8A8AD !important;outline:0 !important;}
button.st_line{border:1px solid #2658c6 !important;background:#2658c640 ;color:#313133;}
.join_bt{border:1px solid #2658c6 !important;background:#2658c640 ;color:#313133;}
button.st_line:focus{outline:1px solid #000 !important;}
button.st_line:hover{background:#E6E6EB !important;border:1px solid #E6E6EB !important;}
.join_bt:hover{background:rgb(38 88 198 / 25%) !important;border:1px solid #2658C6 !important;}
button.st_line:active{background:#D7D7DB !important;border:1px solid #D7D7DB !important;outline:0 !important;}
button.st_line2{border:1px solid #8F8F94 !important;background:none !important;color:#5E5E61;border-radius:8px;}
button.st_line2:hover{background:#EBEBEB !important;border:1px solid #8F8F94 !important;}
button.st_line2:focus{outline:1px solid #000 !important;}
button.st_line2:active{background:#D6D6D6 !important;border:1px solid #8F8F94 !important;outline:0 !important;}
button.st_line3{border:1px solid #8F8F94 !important;background:none !important;color:#5E5E61;border-radius:8px;}
button.st_line3:hover{background:#E6E6EB !important;border:1px solid #8F8F94 !important;}
button.st_line3:focus{outline:1px solid #000 !important;}
button.st_line3:active{background:#D6D6D6 !important;border:1px solid #8F8F94 !important;outline:0 !important;}
button.st_line4{border:1px solid #E6E6EB !important;background:none !important;color:#313133;border-radius:8px;}
button.st_line4:hover{background:#E6E6EB !important;border:1px solid #E6E6EB !important;}
button.st_line4:focus{outline:1px solid #000 !important;}
button.st_line4:active{background:#D7D7DB !important;border:1px solid #D7D7DB !important;outline:0 !important;}
button.st_point{background:#2658E2 !important;border:1px solid #2658E2 !important;border-radius:24px !important;color:#fff !important;}
button.st_point:hover{background:#173588 !important;border:1px solid #173588 !important;outline:0 !important;}
button.st_point:focus{border:1px solid #fff !important;outline:1px solid #222224 !important;}
button.st_point:active{background:#0B1A44 !important;border:1px solid #0B1A44 !important;outline:0 !important;}
button.st_clear{border:1px solid #fff !important;background:#fff !important;color:#313133;}
button.st_clear:focus{outline:1px solid #000 !important;}
button.st_clear:hover{background:#F0F0F5 !important;border:1px solid #F0F0F5 !important;}
button.st_clear:active{background:#D7D7DB !important;border:1px solid #D7D7DB !important;outline:0 !important;}
.tag_theme{color:#313133;border:1px solid #5E5E61;border-radius:16px;}
.tag_theme.bright{color:#E9EEFC;border:1px solid #E9EEFC;}
/* 버튼들 스타일 끝 */

/* 버튼 tag */
.tag{line-height:26px;padding:0 6px;border-radius:6px;font-size:16px;font-weight:500;background:none;}
.tag_info{border:1px solid #2658E2;color:#2658E2;}
.tag_img{border:1px solid #FB620B;color:#FB620B;}
.tag_video{border:1px solid #BA4FEE;color:#BA4FEE;}
.tag_file{border:1px solid #049E48;color:#049E48;}
.tag_type{border:1px solid #77777A;color:#77777A;}
/* 버튼 tag */

/* 아이콘 */
i.icon{display:block;width:24px;height:24px;}
i.icon.call{background:url('/public/images/ri_icon_call.png')no-repeat center;}
i.icon.time{background:url('/public/images/ri_icon_schedule.png')no-repeat center;}
i.icon.closed{background:url('/public/images/ri_icon_event.png')no-repeat center;}
i.icon.charge{background:url('/public/images/ri_icon_won.png')no-repeat center;}
i.icon.parking{background:url('/public/images/ri_icon_parking.png')no-repeat center;}
i.icon.homepage{background:url('/public/images/ri_icon_web.png')no-repeat center;}

i.icon.age{background:url('/public/images/ri_icon_age.png')no-repeat center;}
i.icon.copyright{background:url('/public/images/ri_icon_copyright.png')no-repeat center;}
i.icon.mail{background:url('/public/images/ri_icon_mail.png')no-repeat center;}
i.icon.maker_video{background:url('/public/images/ri_icon_maker_video.png')no-repeat center;}
i.icon.maker_image{background:url('/public/images/ri_icon_maker_image.png')no-repeat center;}
i.icon.original{background:url('/public/images/ri_icon_original.png')no-repeat center;}
i.icon.provider{background:url('/public/images/ri_icon_Provider.png')no-repeat center;}
i.icon.tag{background:url('/public/images/ri_icon_tag.png')no-repeat center;}
i.icon.youtube{background:url('/public/images/ri_icon_youtube.png')no-repeat center;}

i.icon.interests{background:url('/public/images/ri_icon_interests.png')no-repeat center;}
i.icon.calendar_add_on{background:url('/public/images/ri_icon_calendar_add_on.png')no-repeat center;}
i.icon.code_blocks{background:url('/public/images/ri_icon_code_blocks.png')no-repeat center;}

/* 아이콘 끝 */

/* body{background:#F0F0F5;} */
/* header */
header{width:100%;background:#fff;position:relative;z-index:1;}
header div.center{height:96px;display:flex;align-items: center;justify-content:space-between;}
header div.center:after{display:none;}

header div.center_search{height:96px;display:flex;align-items: center;justify-content:space-between;}
header div.center_search:after{display:none;}

/* ul.gnb{font-size:0;}
ul.gnb li{display:inline-block;vertical-align:top;margin-left:64px;}
ul.gnb li:first-child{margin-left:0;}
ul.gnb li a{padding:35px 0px 62px 0px;height:36px;line-height:36px;font-size:20px;font-weight:700;}
ul.gnb li a:hover {border-bottom: 2px #2658E2;color: #2658E2; display: block;} */
/* ul.gnb li a:focus{outline:1px solid #000
	 !important;border-radius:4px;} */
/* ul.gnb li a:active{border-bottom:2px solid #173588;color:#173588;outline:0 !important;border-radius:0px;}
ul.gnb li a::selection{color:#2658E2;}

ul.gnb-introduce li{ float: left; font-size: large;}
ul.gnb-introduce li.introduce{margin-left: "10%"}
ul.gnb-introduce li.guide{margin-left: "23%"}
ul.gnb-tourism-contents.tourism-contents a{margin-left: "-25%"}
ul.gnb-tourism-contents.tourism-statistics a{margin-left: "40%"; width: "120px"}
ul.gnb-tourism-contents.tourism-recommend a{margin-left: "110%"; width: "120px"}
ul.gnb-center.announcement li{margin-left: "27%"}
ul.gnb-center.announcement li{margin-left: "22%"; width: "120px"} */

/* ul.gnb-introduce {position: absolute;float: left;display: none; padding: 2% 37%; border-bottom: solid 1px #cdcdcd; border-top: solid 1px #cdcdcd; width: 130%;margin-left: -54.5%;height: 100px; z-index: 2; background-color: white;}
ul.gnb-tourism-contents {position: absolute;float: left;display: none; padding: 2% 37%; border-bottom: solid 1px #cdcdcd; border-top: solid 1px #cdcdcd;width: 130%;margin-left:  -54.5%;height: 100px; z-index: 2; background-color: white;}
ul.gnb-online-service {position: absolute;display: none; padding: 2% 37%; border-bottom: solid 1px #cdcdcd; border-top: solid 1px #cdcdcd;width: 130%;margin-left:  -54.5%;height: 100px;z-index: 2; background-color: white;}
ul.gnb-center {position: absolute;float: left;display: none; padding: 2% 37%; border-bottom: solid 1px #cdcdcd; border-top: solid 1px #cdcdcd; width: 130%;margin-left:  -54.5%;height: 100px; z-index: 2; background-color: white;}
ul.gnb-tourism-contents li{ float: left; font-size: large;}
ul.gnb-online-service li{ float: left; font-size: large;}
ul.gnb-center li{ float: left; font-size: large;} */

/* The navigation menu */
.navbar {overflow: hidden;background-color: #ffffff;}
.navbar .gnb{height: 94px;;}
/* Navigation links */
.navbar a {float: left;font-size: 16px;color: rgb(255, 255, 255);text-align: center; padding: 40px 16px;text-decoration: none;}
/* The subnavigation menu */
.gnb {float: left;overflow: hidden; padding: 28px 5px;}
/* Subnav button */
.gnb .sub-gnb {font-size: 20px;font-weight: 700;border: none;outline: none;color: rgb(0, 0, 0);padding: 5px 30px;background-color: inherit;font-family: inherit;height: 36px;}
/* Add a red background color to navigation links on hover */
.navbar a:hover, .gnb:hover .sub-gnb, .gnb-introduce:hover {
	display: block;
}
.gnb:hover{
	border-bottom: 2px solid #2658E2 ;
}
  
  /* Style the subnav content - positioned absolute */
  .gnb-introduce {
	margin: 30px 0 0 0;
	display: none;
	position: absolute;
    z-index: 1;
	background-color: white;
	left: 0;
	width: 100%;
	border-top: 1px solid rgb(186, 186, 186);
	border-bottom: 1px solid rgb(186, 186, 186);
	height: 96px;
  }
  

  /* Style the subnav links */
  .gnb-introduce a {
	
	color: rgb(0, 0, 0);
	text-decoration: none;
	font-size: 20px;
  }

  .gnb-introduce div {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

  .sub-gnb a{
	float: left;
    font-size: 22px;
    color: black;
    text-align: center;
    text-decoration: none;
	padding: 0px;
  }

  /* Add a grey background color on hover */
  .gnb-introduce a:hover {
	color: black;
  }
  
  /* When you move the mouse over the subnav container, open the subnav content */
  .gnb:hover .gnb-introduce {
	background-color: white;
	display: block;
  }

div.menu_bts{display: flex; gap: 0 18px;}
div.menu_bts button{background:#fff;border-radius:50%;border:0;overflow:hidden;}
div.menu_bts button:first-child{margin-left:0;}
div.menu_bts button:hover{background:#ddd;}
div.menu_bts button:focus{outline:1px solid #000 !important;}
div.menu_bts button:active{border-bottom:2px solid #173588;color:#173588;outline:0 !important;}

@media (max-width: 1440px){
	.gnb {margin-left:8px;}
}
@media (max-width: 1280px){
	ul.gnb li{margin-left:30px;}
	div.gnb button.sub-gnb{padding:0 6px;font-size:18px;}
}

@media (max-width: 1024px){
	header .gnb{display:none;}
	section.main_visual_wrap div.login_box{display:none;}
}

@media (max-width: 1024px){
	header .gnb{display:none;}
	section.main_visual_wrap div.login_box_developer{display:none;}
}
@media (max-width: 768px){

	.main_visual_wrap{margin-top: 18px;}
	div.menu_bts{gap: 0 12px;}

}

@media (max-width: 480px) {

	div.menu_bts{gap: 0 10px;}
	
}

/* header 끝 */

/* 메뉴페이지 */
div.gnb_menu_page{position:fixed;top:0;left:0;width:100%;height:100%;overflow:hidden;z-index:99999;display:none;}
div.gnb_menu_page div.bg{position:fixed;top:0;left:0;width:100%;height:100%;overflow:hidden;z-index:-1;background:rgba(0,0,0,0.2);}
div.menu_body{position:relative;top:-80px;transition:all 0.25s ease;opacity:0;}
div.gnb_menu_page.active div.menu_body{top:0;opacity:1;}
div.menu_body div.center{background:#fff;padding:40px 32px 40px 32px;border-radius: 0px 0px 32px 32px;border:1px solid #E6E6EB;box-shadow: 0px 6px 8px 0px #0000003D;overflow:hidden;}
div.menu_body div.center_search{background:#fff;padding:40px 32px 40px 32px;border-radius: 0px 0px 32px 32px;border:1px solid #E6E6EB;box-shadow: 0px 6px 8px 0px #0000003D;overflow:hidden;}
ul.menu_list{float:left;display:flex;width:calc(100% - 352px);width:-webkit-calc(100% - 352px);width:-moz-calc(100% - 352px);}
ul.menu_list li{flex:1;margin-right:16px;}
ul.menu_list li:last-child{margin-right:0;}
ul.menu_list li h3 a{display:inline-block;font-size: 24px;font-weight: 900;line-height:1.3;color:#222224;border-bottom:2px solid #fff;padding:0 2px; width: 87px;}
ul.menu_list li h3 a:hover{color:#2658E2;border-bottom:2px solid #2658E2;}
ul.menu_list li h3 a:focus{color:#222224;outline:1px solid #222224;border-bottom:2px solid #fff;border-radius:4px;}
ul.menu_list li h3 a:active{color:#173588;border-bottom:2px solid #173588;outline:0 !important;border-radius:0 !important;}
ul.menu_list li div.sub_menu{margin-top:16px;}
ul.menu_list li div.sub_menu a{font-size: 18px;font-weight: 500;line-height:1.4;color:#77777A;display:inline-block;padding:0 2px;}
ul.menu_list li div.sub_menu a:hover{color:#2658E2;}
ul.menu_list li div.sub_menu a:focus{color:#222224;outline:1px solid #222224 !important;border-radius:4px !important;}
ul.menu_list li div.sub_menu a:active{color:#173588;border-radius:0 !important;outline:0 !important;}
div.menu_login_box{float:right;width:352px;padding:24px 32px;border-radius:16px;background:#F0F0F5;}
div.menu_login_box h2{font-size: 20px;font-weight: 700;line-height: 26px;letter-spacing:-0.2px;text-align: left;background:url('/public/images/login_bg.png')no-repeat right bottom;background-size: 55px;}
div.menu_login_box div.login_bts{display:flex;margin-top:32px;}
div.menu_login_box div.login_bts button{background:#fff;margin:0 0 0 16px;font-size:16px;height:40px;}
div.menu_login_box div.login_bts button:first-child{margin:0;}
/* div.menu_login_box div.login_bts button.combined_login_bt img{margin:0;} */
/* 메뉴페이지 끝 */

/* 검색페이지 */
div.search_page{position:fixed;top:0;left:0;width:100%;height:100%;overflow:hidden;z-index:99999;display:none;}
div.search_page header{background:#2658E2;}
div.search_page div.bg{position:fixed;top:0;left:0;width:100%;height:100%;overflow:hidden;z-index:-1;background:rgba(0,0,0,0.2);}
div.search_page div.menu_bts button{width:36px;height:36px;}

div.search_page div.search_page_body{position:relative;top:-80px;transition:all 0.25s ease;opacity:0;}
div.search_page.active div.search_page_body{top:0;opacity:1;}
div.search_page_body div.center{background:#fff;padding:40px 32px 40px 32px;border-radius: 0px 0px 32px 32px;border:1px solid #E6E6EB;box-shadow: 0px 6px 8px 0px #0000003D;}
div.search_page_body div.center_search{background:#fff;padding:40px 32px 40px 32px;border-radius: 0px 0px 32px 32px;border:1px solid #E6E6EB;box-shadow: 0px 6px 8px 0px #0000003D;}		
div.search_page_body div.word_wrap{overflow:hidden;padding:2px 0;}
div.search_page_body div.word_wrap div.hot{width:50%;display:block;float:left;font-size:0;}
div.search_page_body div.word_wrap div.latest{width:50%;display:block;float:left;font-size:0;}
div.search_page_body div.word_wrap h3{width:120px;font-size:18px;font-weight: 700;line-height:36px;text-align: left;color:#313133;display:inline-block;vertical-align:top;}
div.search_page_body div.word_wrap .list_box{display:inline-block;vertical-align:top;width:calc(100% - 120px);width:-webkit-calc(100% - 120px);width:-moz-calc(100% - 120px);}
div.search_page_body div.word_wrap .list_box button{display:inline-block;vertical-align:top;margin:4px 8px 4px 0;height:36px;padding:0 16px;}
div.search_page_body div.word_wrap .list_box .list_item:last-child{margin-right:0;}

div.search_page_body div.word_wrap div.latest div.list_box button{border-radius:24px;}
div.search_page_body div.word_wrap div.latest div.list_box button a{display:inline-block;vertical-align:top;padding:0 0 0 8px;}
div.search_page_body div.word_wrap div.latest div.list_box button a img{}

div.search_page_body div.key_word{margin-top:40px;border:1px solid #D7D7DB;border-radius:24px;overflow:hidden;}
div.search_page_body div.key_word > div{padding:32px;font-size:0;}
div.search_page_body div.key_word > div.language{border-top:1px solid #D7D7DB;}
div.search_page_body div.key_word > div h4{width:120px;display:inline-block;vertical-align:top;font-size:24px;font-weight: 700;line-height:29px;letter-spacing: -0.2px;color:#313133;margin-right:35px;}
div.search_page_body div.key_word > div div.list{display:inline-block;vertical-align:top;font-size:0;width:calc(100% - 155px);width:-webkit-calc(100% - 155px);width:-moz-calc(100% - 155px);}
div.search_page_body div.key_word > div div.list button{display:inline-block;vertical-align:top;margin:8px 16px 8px 0;height:36px;padding:0 16px;border-radius:24px;}
div.search_page_body div.key_word > div div.list button:last-child{margin-right:0;}

div.menu_login_box{width:352px;padding:24px 32px;border-radius:16px;background:#F0F0F5;}
div.menu_login_box div.login_bts{display:flex;margin-top:32px;}
div.menu_login_box div.login_bts button{margin:0 0 0 16px;font-size:16px;height:40px;}
div.menu_login_box div.login_bts button:first-child{margin:0;}
/* div.menu_login_box div.login_bts button.combined_login_bt img{margin:0;} */

div.search_form{width:70%;display:flex;}
div.search_form button{background:none;border:0;outline:0;}
div.search_form button.icon_search_option_bt{}
div.search_form label.search_input_label{flex:1;position:relative;padding-right:40px;}
div.search_form input.search_in{outline:0;width:100%;height:100%;background:none;color:#fff;font-size: 24px;font-weight:700;letter-spacing: -0.2px;}
div.search_form input.search_in:focus{border-radius: 0;}
div.search_form input.search_in::-ms-input-placeholder{color:#BECDF6 !important;font-size: 24px;font-weight: 700;line-height:1.4;letter-spacing: -0.2px;}
div.search_form input.search_in::-webkit-input-placeholder{color:#BECDF6 !important;font-size:24px;}
div.search_form input.search_in::-moz-placeholder{color:#BECDF6 !important;font-size:24px;}
div.search_form label.search_input_label button.clear_bt{width:40px;height:40px;text-align:center;position:absolute;top:0;right:0;}
div.search_form button.refresh_bt{width:40px;height:40px;margin:0 8px;}
div.search_form button.search_bt{background:#fff !important;border-radius:6px;height:40px;width:88px;font-size:16px;font-weight:500;}
div.search_form button.search_bt img{margin:-1px 5px 0 0;}
div.search_form button.search_bt:focus{outline:1px solid #000 !important;}
div.search_form button.search_bt:hover{background:#F0F0F5 !important;}
div.search_form button.search_bt:active{background:#D7D7DB !important;outline:0 !important;}

@media (max-width: 1280px){
	ul.menu_list{display:block;}
	ul.menu_list li{display:inline-block;width:28%;vertical-align:top;margin:0 16px 16px 0;}
}
@media (max-width: 1024px){
	ul.menu_list li{display:block;width:100%;margin:0 0 16px 0;}
	ul.menu_list li h3 a{font-size:20px;padding:0 8px;}
	div.search_form input.search_in::placeholder{font-size: 20px;}
}
@media (max-width: 768px){
	header div.center{height:74px;}
	header div.center_search{height:56px;}
	div.menu_body{height:calc(100% - 56px);overflow-y:auto;}
	div.menu_body div.center{min-height:100%;padding:24px 16px;box-shadow:none;border-radius:0;border:0;}
	div.menu_body div.center_search{min-height:100%;padding:24px 16px;box-shadow:none;border-radius:0;border:0;}
	h1.logo{width:50%;max-width:210px;}
	h1.logo img{width:100%;}
	div.menu_login_box{display:none;}
	ul.menu_list{width:100%;}
	ul.menu_list li{font-size:0;margin:0 0 24px 0;}
	ul.menu_list li div.sub_menu{margin:0;width:calc(50% - 4px);width:-webkit-calc(50% - 4px);width:-moz-calc(50% - 4px);display:inline-block;vertical-align:top;}
	ul.menu_list li div.sub_menu a{padding:16px 8px;display:block;}
	ul.menu_list li div.sub_menu:nth-child(2n){margin-left:8px;}
	div.sub_menu_wrap{background:#F7F7FC;padding:16px;margin-top:16px;border-radius:8px;}
	div.sub_menu_wrap div.sub_menu:first-child a{margin:0;}

	div.search_form{position:absolute;left:0;top:56px;width:100%;background:#2658E2;padding:8px 16px;}
	button.icon_search_option_bt,
	div.search_form button.refresh_bt,
	div.search_form label.search_input_label button.clear_bt,
	div.search_form button.search_bt img{display:none;}
	div.search_form button.search_bt{width:60px;}
	div.search_form input.search_in{font-size:18px;}
	div.search_form input.search_in::-ms-input-placeholder{font-size:18px;}
	div.search_form input.search_in::-webkit-input-placeholder{font-size:18px;}
	div.search_form input.search_in::-moz-placeholder{font-size:18px;}

	div.search_page div.search_page_body{height:calc(100% - 112px);height:-webkit-calc(100% - 112px);height:-moz-calc(100% - 112px);overflow-y:auto;margin-top:38px;}
	div.search_page div.search_page_body div.center{min-height:100%;padding:24px 16px 24px 16px;box-shadow:none;border-radius:0;border:0;}
	div.search_page div.search_page_body div.center_search{min-height:100%;padding:24px 16px 24px 16px;box-shadow:none;border-radius:0;border:0;}
	div.search_page_body div.word_wrap div.hot{width:100%;}
	div.search_page_body div.word_wrap div.latest{width:100%;margin-top:32px;}
	div.search_page_body div.word_wrap h3{vertical-align:middle;}
	div.search_page_body div.word_wrap .list_box{vertical-align:middle;}
	
	div.search_page_body div.key_word > div{padding:24px 16px;}
	div.search_page_body div.key_word > div h4{width:100%;}
	div.search_page_body div.key_word > div div.list{width:100%;margin-top:8px;}


	div.search_form input.search_in::placeholder{font-size: 16px;}
}
/* 검색페이지 끝 */

/* 로그인 페이지 */
div.login_page{position:fixed;top:0;left:0;width:100%;height:100%;overflow:hidden;z-index: 99999;}
div.login_page div.login_box_wrap{display:flex;align-items:center;justify-content:center;width:100%;height:100%;overflow:auto;min-height:600px;position:relative;}
div.login_page div.login_box_wrap div.bg{z-index:0;}
div.login_page header{display:none;}
div.login_page div.login_box_wrap div.login_box{position:relative;z-index:1;}
div.login_page div.login_box_wrap div.login_box_developer{position:relative;z-index:1;}
div.login_page div.login_box{margin:0 8px;}
div.login_page div.bg{position:fixed;top:0;left:0;width:100%;height:100%;overflow:hidden;z-index:-1;background:rgba(0,0,0,0.2);}

div.login_box.login h2{background:none;position:relative;padding-right:70px;}
div.login_box.login h2 button.my_bt{position:absolute;top:0;right:0;height:36px;padding:0 16px;}
div.login_box.login div.login_links{display:block;overflow:hidden;}
div.login_box.login div.login_links a{float:right;min-width:102px;text-align:center;}

div.login_box_developer.login h2{background:none;position:relative;padding-right:70px;}
div.login_box_developer.login h2 button.my_bt{position:absolute;top:0;right:0;height:36px;padding:0 16px;}
div.login_box_developer.login div.login_links{display:block;overflow:hidden;}
div.login_box_developer.login div.login_links a{float:right;min-width:102px;text-align:center;}

ul.my_links{margin-bottom:27px;min-height:300px}
ul.my_links li{margin-top:8px;}
ul.my_links li:hover{cursor: pointer;}
ul.my_links li:first-child{margin-top:0;}
ul.my_links li a{width:100%;height:92px;line-height:92px;padding:0 24px;border-radius:8px;overflow:hidden;background-repeat:no-repeat;background-position:90% center;background-color:#F0F0F5;font-size: 18px;font-weight: 700;}
ul.my_links li.work a{background-image:url('/public/images/my_icon_Pencil.png');}
ul.my_links li.dev a{background-image:url('/public/images/my_icon_Server.png');}
ul.my_links li.rms a{background-image:url('/public/images/my_icon_Dashboard.png');}
ul.my_links li a:hover{background-color:#E9EEFC;}
ul.my_links li a:focus{outline:1px solid #000 !important;}
ul.my_links li a:active{background-color:#D7D7DB;outline:0 !important;}

@media (max-width: 768px){
	div.login_page{overflow:auto;}
	div.login_page div.login_box_wrap{display:block;height:calc(100% - 56px);}
	div.login_page header{display:block;}
	div.login_page header div.center{justify-content: flex-end;}
	div.login_page header div.center_search{justify-content: flex-end;}
	div.login_page div.login_box{margin:0;width:100%;border-radius:0;}
	div.login_page div.login_box_developer{margin:0;width:100%;border-radius:0;}
}
@media (max-width: 360px){
	div.search_page_body div.word_wrap h3{width:100%;display:block;font-size:16px;}
	div.search_page_body div.word_wrap .list_box{width:100%;display:block;}
	div.search_page_body div.word_wrap div.latest{width:100%;margin-top:24px;}
	div.search_page_body div.key_word{margin-top:24px;}
	div.search_page_body div.key_word > div h4{font-size:16px;}
	div.search_page_body div.key_word > div div.list{margin-top:4px;}
	div.search_page_body div.key_word > div div.list button{margin:4px 8px 4px 0;}
}
/* 로그인 페이지 끝 */


/* 메인페이지 */
#contents.main{margin-top:16px;
	background-image:url('/public/images/main_bg1.jpg'), url('/public/images/main_bg2.png');
    background-size: 52% auto, 48% auto;
    background-position: 123% 4%, -40% 23%;
    background-repeat: no-repeat;
	/* background:url('/public/images/main_bg.png')no-repeat center top;background-size:contain; */
}
.detail_page_bg{margin-top:16px;
	background-image:url('/public/images/main_bg1.jpg'), url('/public/images/main_bg2.png');
    background-size: 52% auto, 48% auto;
    background-position: 123% 4%, -40% 23%;
    background-repeat: no-repeat;
	/* background:url('/public/images/main_bg.png')no-repeat center top;background-size:contain; */
}
#contents.blur{filter: blur(5px);-webkit-filter: blur(5px);}
@media (max-width: 1440px){
	#contents{
		background-size: 66% 25%, 45% 18%;
		background-position: 147% 4%, -38% 27%;
	}
}

@media (max-width: 1024px){
	#contents.main{
		margin-top: 0;
		background-size: 100% auto, 49% auto;
		background-position: 210px 3%, -50% 24%;
	}
}

@media (max-width: 768px){
	#contents.main{
		background-size: 90% auto, 49% auto;
		background-position: 250% 7%, -50% 24%;
	}
}

/* 공통 얼럿 박스 */
section.alert_desc ul {
	display: flex;
	margin: 40px 0px;
}
section.alert_desc ul li {
	flex: 1;
	border-radius: 32px;
	font-size: 18px;
	font-weight: 500;
	line-height: 27px;
	letter-spacing: -0.6px;
	word-break: break-all;
	background-size: 140px;
}


/* 메인 로그인 박스 */
section.top_desc ul {
	display: flex;
	margin: 40px 0px;
}
section.top_desc ul li {
	flex: 1;
	background: #f0f0f5;
	padding: 31px;
	border-radius: 32px;
	font-size: 18px;
	font-weight: 500;
	line-height: 27px;
	letter-spacing: -0.6px;
	word-break: break-all;
	background-size: 140px;
}

/* 메인 비쥬얼 */
section.main_visual_wrap{}
section.main_visual_wrap div.center{display:flex;align-items:stretch;justify-content: space-between;}
section.main_visual_wrap div.center_search{display:flex;align-items:stretch;justify-content: space-between;}
div.main_visual{flex:1;position:sticky;border-radius:32px;overflow:hidden;margin-right: 16px;}
div.main_visual div.main_visual_inner{width:100%;height:100%;padding:56px;position:sticky;z-index:99;}
div.main_visual video{position:absolute;top:0;left:0;width:100%;height:100%;padding:0;margin:0;object-fit: cover;}
div.main_visual h2{font-size:54px;line-height:1.2;color:#fff;font-weight:900;}
div.main_visual h2 img.icon{vertical-align:top;margin:0 0 0 8px;}

div.statistics_area{margin-top: 88px;}
div.statistics_area ul{}
div.statistics_area ul li{font-size:0;margin-top:8px}
div.statistics_area ul li:first-child{margin-top:0;}
div.statistics_area ul li div.statistics_info{font-size:0;display:inline-block;vertical-align:middle;margin-right:32px;}
div.statistics_area ul li div.statistics_info:last-child{margin-right:0px;}
div.statistics_area ul li h4{color:#fff;display:inline-block;vertical-align:middle;line-height:40px;padding:0 20px;border-radius:40px;background:#fff;color:#2658E2;font-size:18px;}
div.statistics_area ul li h4 span{font-size: 25px;}
div.statistics_area ul li h5{color:#fff;display:inline-block;vertical-align:middle;line-height:40px;padding:0 16px;border-radius:40px;font-size:18px !important;border:1px solid #E6E6EB;}
div.statistics_area ul li h5 span{font-size: 25px;}
div.statistics_area ul li h5 img{margin:0 8px 0 0;}
div.statistics_area ul li span{color:#fff;font-size:48px;font-weight:900;line-height:1.4;display:inline-block;vertical-align:middle;margin-left:8px;}
/* div.statistics_area ul li {align-items: center; color:#fff;font-size:20px;font-weight:900;line-height:1.4;display:inline-flex;vertical-align:baseline;} */
/* div.statistics_area ul li div.statistics_info{color:#fff;font-size:20px;font-weight:900;line-height:1.4;display:inline-block;vertical-align:middle;} */
/* div.statistics_area ul li.area-total {font-size: 20px; margin-bottom: 10px;} */

div.statistics_area span.add_txt{font-size:16px;color:#fff;font-weight:900;margin-left:32px;}
/* div.statistics_area ul li span.add_txt img{margin:0 5px 0 0;vertical-align:top;} */

/* div.statistics_area ul li.area-total span{font-size: 20px; font-weight: bold;} */
/* div.statistics_area ul li div.statistics_info span{font-size: 20px; font-weight: bold;} */
 
/* @media (max-width: 1600px){
	div.main_visual div.main_visual_inner{padding:24px 10px 24px 24px;}
	div.main_visual h2{font-size:36px;}
	div.main_visual h2 img.icon{width:18px;}
	div.statistics_area ul li h4{height:50px;line-height:50px;padding:0 18px;}
	div.statistics_area ul li h5{height:50px;line-height:50px;padding:0 12px;}
	div.statistics_area ul li h5 img{margin:-2px 3px 0 0;}
	div.statistics_area ul li{font-size:36px;}
	div.statistics_area ul li span.add_txt{margin-left:24px;}
}

@media (max-width: 1280px){
	div.main_visual div.main_visual_inner{padding:24px 10px 24px 24px;}
	div.main_visual h2{font-size:36px;}
	div.main_visual h2 img.icon{width:18px;}
	div.statistics_area ul li h4{height:50px;line-height:50px;padding:0 18px;}
	div.statistics_area ul li h5{height:50px;line-height:50px;padding:0 12px;}
	div.statistics_area ul li h5 img{margin:-2px 3px 0 0;}
	div.statistics_area ul li{font-size:36px;}
	div.statistics_area ul li span.add_txt{margin-left:24px;}
}

@media (max-width: 1024px){
	div.main_visual div.main_visual_inner{padding:24px 10px 24px 24px;}
	div.main_visual h2{font-size:36px;}
	div.main_visual h2 img.icon{width:18px;}
	div.statistics_area ul li h4{height:50px;line-height:50px;padding:0 18px;}
	div.statistics_area ul li h5{height:50px;line-height:50px;padding:0 12px;}
	div.statistics_area ul li h5 img{margin:-2px 3px 0 0;}
	div.statistics_area ul li{font-size:36px;}
	div.statistics_area ul li span.add_txt{margin-left:24px;}
}

@media (max-width: 768px){
	div.main_visual h2{font-size:32px;}
	div.statistics_area ul li h4{font-size:15px;}
	div.statistics_area ul li h5{font-size:13px;}
	div.statistics_area ul li {font-size:26px;}
} */

@media (max-width: 1440px){
	div.main_visual div.main_visual_inner{padding:40px;}
	div.main_visual h2{font-size:42px;}
	div.statistics_area{margin-top:12%;}
	div.statistics_area ul li span{font-size:40px;}
	div.statistics_area ul li div.statistics_info{display:block;margin:0;}
	div.statistics_area span.add_txt{margin-left: 2vw;}
}

@media (max-width: 1280px){
	div.statistics_area ul li h4{font-size:16px;}
	div.statistics_area ul li h5{font-size:16px;}
}

@media (max-width: 1024px){
	div.main_visual{margin-right: 0;}
	div.main_visual div.main_visual_inner{padding:24px 10px 24px 24px;}
	div.main_visual h2{font-size:36px;}
	div.main_visual h2 img.icon{width:18px;}
	div.statistics_area ul li h4{height:30px;line-height:30px;padding:0 18px;}
	div.statistics_area ul li h5{height:30px;line-height:30px;padding:0 12px;}
	div.statistics_area ul li h5 img{margin:0 3px 0 0;}
	div.statistics_area ul li span{font-size:36px;}
	div.statistics_area ul li span.add_txt{margin-left:24px;}
}

@media (max-width: 768px){
	div.main_visual h2{font-size:32px;}
	div.statistics_area ul li h4{font-size:13px;}
	div.statistics_area ul li h5{font-size:13px;}
	div.statistics_area ul li span{font-size:26px;}
}

div.main_login_box{display: contents;}
div.login_box form.pannel_from{height: 100%; display: flex; flex-wrap: wrap;}
div.main_login_box div.login_box{width:392px;background:#ffffffbf;border:1px solid #D7D7DB;border-radius:32px; padding: 32px 32px 20px;}
div.login_box{width:392px;background:#fff;border:1px solid #D7D7DB;padding:40px 32px 20px 32px;border-radius:32px;}
div.login_box h2{font-size:24px;letter-spacing:-0.2px;font-weight:700;height:80px;background:url('/public/images/login_bg.png')no-repeat right bottom;}
div.login_box h2.check_modal{font-size:24px;letter-spacing:-0.2px;font-weight:700;height:60px;background:url('/public/images/no_data.png')no-repeat right; background-size: 84px 84px;}
div.login_box div.input_box{position:relative;margin-top:16px;width:100%;height:48px;border-radius:8px;background: linear-gradient(0deg, #F7F7FC, #F7F7FC),linear-gradient(0deg, #D7D7DB, #D7D7DB);overflow:hidden;}
div.login_box div.input_box span.save{line-height:48px;position:absolute;top:0;right:0;}
div.login_box div.input_box span.save label{vertical-align:top;padding-right:16px;}
div.login_box div.input_box input{border:1px solid #D7D7DB;padding:0 110px 0 16px;height:100%;flex:1;border-radius:8px;background:none;width:100%;}
div.login_box div.input_box input.error{border:1px solid #FF3B30;}
div.login_box div.input_box input::placeholder{color:#A8A8AD;font-size:16px;font-weight:500;}
div.login_box div.input_box input:focus{outline:0 !important;}
div.login_box div.input_box button.visible_bt{border:0;outline:0;padding:0 16px;background:none;position:absolute;top:0;right:0;height:100%;}
div.login_box div.input_box input[type='checkbox'] + label::before{opacity:0.5;}
div.login_box div.input_box button.clear_bt{background:none;outline:0;border:0;position:absolute;top:0;right:0;height:100%;width:24px;}
div.login_box div.input_box.id button.clear_bt{right:80px;}
div.login_box div.input_box.pass button.clear_bt{right:50px;}
div.login_box div.error_box{height:60px;padding:12px 16px;}
div.login_box div.error_box p{font-size:12px;font-weight:500;line-height:1.4;letter-spacing: -0.4px;color:#FF3B30;}


div.main_login_box{display: contents;}
div.login_box_developer form.pannel_from{height: 100%; display: flex; flex-wrap: wrap;}
div.main_login_box div.login_box_developer{width:392px;background: #fff;border:1px solid #D7D7DB;border-radius:32px; padding: 32px 32px 20px;}
div.login_box_developer{width:392px;background:#fff;border:1px solid #D7D7DB;padding:40px 32px 20px 32px;border-radius:32px;}
div.login_box_developer h2{font-size:24px;letter-spacing:-0.2px;font-weight:700;height:80px;background:url('/public/images/login_bg.png')no-repeat right bottom;}
div.login_box_developer h2.check_modal{font-size:24px;letter-spacing:-0.2px;font-weight:700;height:60px;background:url('/public/images/no_data.png')no-repeat right; background-size: 84px 84px;}
div.login_box_developer div.input_box{position:relative;margin-top:16px;width:100%;height:48px;border-radius:8px;background: linear-gradient(0deg, #F7F7FC, #F7F7FC),linear-gradient(0deg, #D7D7DB, #D7D7DB);overflow:hidden;}
div.login_box_developer div.input_box span.save{line-height:48px;position:absolute;top:0;right:0;}
div.login_box_developer div.input_box span.save label{vertical-align:top;padding-right:16px;}
div.login_box_developer div.input_box input{border:1px solid #D7D7DB;padding:0 110px 0 16px;height:100%;flex:1;border-radius:8px;background:none;width:100%;}
div.login_box_developer div.input_box input.error{border:1px solid #FF3B30;}
div.login_box_developer div.input_box input::placeholder{color:#A8A8AD;font-size:16px;font-weight:500;}
div.login_box_developer div.input_box input:focus{outline:0 !important;}
div.login_box_developer div.input_box button.visible_bt{border:0;outline:0;padding:0 16px;background:none;position:absolute;top:0;right:0;height:100%;}
div.login_box_developer div.input_box input[type='checkbox'] + label::before{opacity:0.5;}
div.login_box_developerdiv.input_box button.clear_bt{background:none;outline:0;border:0;position:absolute;top:0;right:0;height:100%;width:24px;}
div.login_box_developer div.input_box.id button.clear_bt{right:80px;}
div.login_box_developer div.input_box.pass button.clear_bt{right:50px;}
div.login_box_developer div.error_box{height:60px;padding:12px 16px;}
div.login_box_developer div.error_box p{font-size:12px;font-weight:500;line-height:1.4;letter-spacing: -0.4px;color:#FF3B30;}


div.login_bts{}
div.login_bts button{background:#fff;border:1px solid #ddd;margin-bottom:16px;width:100%;height:52px;border-radius:8px;font-size:18px;font-weight:500;color:#313133;}
div.login_bts button.login_bt.ok{background:#2658E2 !important;color:#fff !important;font-weight:700 !important;}
div.login_bts button.combined_login_bt{border:1px solid #FB620B; width: 326px;}
div.login_bts button.combined_login_bt:focus{outline:1px solid #000 !important;border:0;}
div.login_bts button.combined_login_bt:hover{background:#F0F0F5 !important;}
div.login_bts button.combined_login_bt:active{background:#D7D7DB !important;outline:0 !important;border:1px solid #FB620B;}

div.login_bts button.combined_login_bt img{margin-left:8px;}
div.login_links{margin-top:2px;padding-top:12px;display:flex;border-top:1px solid #D7D7DB;}
div.login_links a{flex:1;margin-left:10px;text-align:center;line-height:40px;font-size:16px;color:#77777A;font-weight:500;}
div.login_links a:first-child{margin-left:0;}
div.login_links a:hover,
div.login_links a:active{color:#2658E2;}
div.login_links a:focus{color:#313133;}
/* 메인 비쥬얼 끝 */

/* 슬로건 */
section.slogan{text-align:center;margin-top:132px;}
section.slogan h3{font-size: 54px;font-weight: 900;line-height: 1.3;color:#000;}
section.slogan h3:nth-child(1) img{vertical-align:bottom;margin:0 8px 0 0;}
section.slogan h3:nth-child(2){line-height:120px;margin-top:16px;}
section.slogan h3:nth-child(2) img{margin:-10px 0 0 0;}
section.slogan h3:nth-child(3){line-height:130px;}
section.slogan h3:nth-child(3) img{margin:-10px 0 -12px 0;}
@media (max-width: 1024px){
	section.slogan{margin-top:80px;}
	section.slogan h3{font-size:25px;line-height:60px;}
	section.slogan h3:nth-child(1){line-height:1.2;}
	section.slogan h3:nth-child(2){line-height:60px;margin-top:8px;}
	section.slogan h3:nth-child(3){line-height:60px;}
	section.slogan h3:nth-child(1) img{width:130px;margin:0 4px 0 0;}
	section.slogan h3:nth-child(2) img{width:56px;}
	section.slogan h3:nth-child(3) img{width:67px;}
}
@media (max-width: 360px){
	section.slogan h3:nth-child(1) img{width:30%;}
	section.slogan h3:nth-child(2) img{width:15%;}
	section.slogan h3:nth-child(3) img{width:19%;}
}
/* 슬로건 끝 */

/* 무엇을 할 수 있나요 */
section.wcid{margin-top:128px;}	
section.wcid h3{font-size: 54px;font-weight: 900;line-height:1.2;}
section.wcid ul{display:flex;margin-top:76px;}
section.wcid ul li{flex:1;background:#F0F0F5;margin-left:16px;padding:32px 32px 164px 32px;border-radius:32px;font-size: 18px;font-weight: 500;line-height: 27px;letter-spacing: -0.6px;word-break:break-all;background-size:140px;}
section.wcid ul li:first-child{margin-left:0;}
section.wcid ul li:nth-child(1){background:url('/public/images/wcid_Search_File.png')no-repeat 95% 85% #F0F0F5;}
section.wcid ul li:nth-child(2){background:url('/public/images/wcid_Download_Image.png')no-repeat 95% 85% #F0F0F5;}
section.wcid ul li:nth-child(3){background:url('/public/images/Language_Translate.png')no-repeat 95% 85% #F0F0F5;}
section.wcid ul li:nth-child(4){background:url('/public/images/wcid_Profile_t.png')no-repeat 95% 85% #F0F0F5;}
section.wcid ul li span{font-weight: 800; font-size: 24px;}

@media (max-width: 1440px){
	section.wcid ul{display:block;font-size:0;}
	section.wcid ul li{display:inline-block;vertical-align:top;width:48%;margin:0 0 4% 0;}
	section.wcid ul li:nth-child(2n){margin:0 0 4% 4%;}
	section.wcid ul li span{font-weight: 800; font-size: 24px;}
}
@media (max-width: 1024px){
	section.wcid{margin-top:80px;}	
	section.wcid h3{font-size:25px;}
	section.wcid ul{margin-top:24px;border-radius:24px;overflow:hidden;}
	section.wcid ul li{display:block;width:100%;margin:0;background-size:80px !important;padding:50px 50px 50px 100px;border-radius:0;border-top:1px solid #D7D7DB;font-size:13px;line-height:1.4;}
	section.wcid ul li:first-child{border-top:0;}
	section.wcid ul li:nth-child(2n){margin:0;padding:50px 120px 50px 50px;background-position:95% center !important;}
	section.wcid ul li:nth-child(2n-1){background-position:5% center !important;padding-left:120px;}
	section.wcid ul li span{font-weight: 800; font-size: 20px;}
}
@media (max-width: 360px){
	section.wcid ul li{display:block;width:100%;margin:0;background-size:80px !important;padding:24px 24px 24px 100px;border-radius:0;border-top:1px solid #D7D7DB;font-size:13px;line-height:1.4;}
	section.wcid ul li:first-child{border-top:0;}
	section.wcid ul li:nth-child(2n){margin:0;padding:24px 120px 24px 24px;background-position:95% center !important;}
	section.wcid ul li:nth-child(2n-1){background-position:5% center !important;padding-left:120px;}
	section.wcid ul li span{font-weight: 800; font-size: 15px;}
}
/* 무엇을 할 수 있나요 끝 */

/* 추천 관광지 */
section.recommend{margin-top:120px;background:url('/public/images/recommend_bg.png')no-repeat center bottom;padding-bottom:40px;}
section.recommend h3{font-size: 54px;font-weight: 900;line-height:1.2;}
section.recommend h3 span{vertical-align:top;color:#2658E2;border-bottom:4px solid #2658E2;}



div.recommend_slide{margin-top:76px;padding-bottom:50px;}

div.recommend_slide div.swiper-slide{margin-right: 14px; border-radius: 24px;overflow:hidden;width:calc(25% - 12px) !important;width:-webkit-calc(25% - 12px) !important; width:-moz-calc(25% - 12px) !important;}
div.recommend_slide div.swiper-slide div.img_box{width:100%;overflow:hidden;}
div.recommend_slide div.swiper-slide div.img_box img{width:100%;transition:all 1s ease;transform: scale(1);}
div.recommend_slide div.swiper-slide div.info{background:#fff;padding:24px;}
div.recommend_slide div.swiper-slide div.info span{line-height:24px;padding:0 10px;display:inline-block;}
div.recommend_slide div.swiper-slide div.info h4{white-space:nowrap;text-overflow:ellipsis;overflow:hidden;margin-top:8px;font-size: 20px;font-weight: 700;line-height:1.4;letter-spacing: -0.2px;}
div.recommend_slide div.swiper-slide div.info p{margin-top:8px;font-size: 16px;font-weight: 500;line-height:1.4;letter-spacing: -0.2px;}
div.recommend_slide div.swiper-slide:hover div.img_box img{transform: scale(1.2);}

div.recommend_slide div.swiper-pagination{position: absolute; text-align: center;}
div.recommend_slide div.swiper-pagination span.swiper-pagination-bullet{width:12px;height:12px;background:#C3C3C7;border-radius:8px;transition:all 0.2s ease;opacity:1;}
div.recommend_slide div.swiper-pagination span.swiper-pagination-bullet.swiper-pagination-bullet-active{width:24px;background:#fff;}
div.recommend_slide_wrap{position:relative;}
div.recommend_slide_wrap div.swiper-button-next,
div.recommend_slide_wrap div.swiper-button-prev{width:60px;height:60px;background:#fff;border-radius:50%;box-shadow: 0px 4px 16px 0px #0000003D;margin-top:-40px;outline:0;}
div.recommend_slide_wrap div.swiper-button-next:hover,
div.recommend_slide_wrap div.swiper-button-prev:hover{background:#F0F0F5;}
div.recommend_slide_wrap div.swiper-button-next:focus,
div.recommend_slide_wrap div.swiper-button-prev:focus{background:#fff;border:1px solid #000;}
div.recommend_slide_wrap div.swiper-button-next:active,
div.recommend_slide_wrap div.swiper-button-prev:active{background:#D7D7DB;border:1px solid #D7D7DB;outline:0;}
div.recommend_slide_wrap div.swiper-button-next{left:auto;right:-14px;} 
div.recommend_slide_wrap div.swiper-button-prev{right:auto;left:-14px;}
div.recommend_slide_wrap div.swiper-button-prev:after,
div.recommend_slide_wrap div.swiper-button-next:after{display:none;}
div.recommend_slide_wrap div.swiper-button-disabled{display:none;}
/* 2번째 슬라이드 */
section.tr_list div.recommend_slide_new_theme{margin-top:48px;}
div.recommend_slide_new_theme{margin-top:76px;padding-bottom:50px;}
div.recommend_slide_new_theme div.swiper-slide{margin-right: 14px; border-radius: 24px;overflow:hidden;width:calc(25% - 12px) !important;width:-webkit-calc(25% - 12px) !important; width:-moz-calc(25% - 12px) !important;}
div.recommend_slide_new_theme div.swiper-slide div.img_box{width:100%;overflow:hidden;}
div.recommend_slide_new_theme div.swiper-slide div.img_box img{width:100%;transition:all 1s ease;transform: scale(1);}
div.recommend_slide_new_theme div.swiper-slide div.info{background:#fff;padding:24px;}
div.recommend_slide_new_theme div.swiper-slide div.info span{line-height:24px;padding:0 10px;display:inline-block;}
div.recommend_slide_new_theme div.swiper-slide div.info h4{white-space:nowrap;text-overflow:ellipsis;overflow:hidden;margin-top:8px;font-size: 20px;font-weight: 700;line-height:1.4;letter-spacing: -0.2px;}
div.recommend_slide_new_theme div.swiper-slide div.info p{margin-top:8px;font-size: 16px;font-weight: 500;line-height:1.4;letter-spacing: -0.2px;}
div.recommend_slide_new_theme div.swiper-slide:hover div.img_box img{transform: scale(1.2);}

div.recommend_slide_new_theme div.swiper-pagination{position: absolute; text-align: center;}
div.recommend_slide_new_theme div.swiper-pagination span.swiper-pagination-bullet{width:12px;height:12px;background:#C3C3C7;border-radius:8px;transition:all 0.2s ease;opacity:1;}
div.recommend_slide_new_theme div.swiper-pagination span.swiper-pagination-bullet.swiper-pagination-bullet-active{width:24px;background:#fff;}
div.recommend_slide_wrap_new_theme{position:relative;}

div.recommend_slide_wrap_new_theme div.swiper-button-prev_new_theme,
div.recommend_slide_wrap_new_theme div.swiper-button-next_new_theme{
	position: absolute;
    top: var(--swiper-navigation-top-offset, 50%);
    width: calc(var(--swiper-navigation-size) / 44* 27);
    height: var(--swiper-navigation-size);
    margin-top: calc(0px -(var(--swiper-navigation-size) / 2));
    z-index: 10;
    cursor: pointer;
    display: flex
;
    align-items: center;
    justify-content: center;
    color: var(--swiper-navigation-color, var(--swiper-theme-color));
}

div.recommend_slide_wrap_new_theme div.swiper-button-next_new_theme,
div.recommend_slide_wrap_new_theme div.swiper-button-prev_new_theme{width:60px;height:60px;background:#fff;border-radius:50%;box-shadow: 0px 4px 16px 0px #0000003D;margin-top:-40px;outline:0;}
div.recommend_slide_wrap_new_theme div.swiper-button-next_new_theme:hover,
div.recommend_slide_wrap_new_theme div.swiper-button-prev_new_theme:hover{background:#F0F0F5;}
div.recommend_slide_wrap_new_theme div.swiper-button-next_new_theme:focus,
div.recommend_slide_wrap_new_theme div.swiper-button-prev_new_theme:focus{background:#fff;border:1px solid #000;}
div.recommend_slide_wrap_new_theme div.swiper-button-next_new_theme:active,
div.recommend_slide_wrap_new_theme div.swiper-button-prev_new_theme:active{background:#D7D7DB;border:1px solid #D7D7DB;outline:0;}
div.recommend_slide_wrap_new_theme div.swiper-button-next_new_theme{left:auto;right:-14px;}
div.recommend_slide_wrap_new_theme div.swiper-button-prev_new_theme{right:auto;left:-14px;}
div.recommend_slide_wrap_new_theme div.swiper-button-prev_new_theme:after,
div.recommend_slide_wrap_new_theme div.swiper-button-next_new_theme:after{display:none;}
div.recommend_slide_wrap_new_theme div.swiper-button-disabled{display:none;}
section.tr_list div.recommend_slide_new_theme{margin-top:48px;}
section.tr_list div.recommend_slide_new_theme div.swiper-pagination span.swiper-pagination-bullet.swiper-pagination-bullet-active{width:24px;background:#313133;}
section.tr_list div.recommend_slide_new_theme ul li div.info{background:#2658E2;}
section.tr_list div.recommend_slide_new_theme ul li div.tag_box{display:flex;align-items:center;justify-content:space-between;}
section.tr_list div.recommend_slide_new_theme ul li div.tag_box img.logo{max-width:50%;}
section.tr_list div.recommend_slide_new_theme ul li div.info span{line-height:24px;padding:0 10px;display:inline-block;}
section.tr_list div.recommend_slide_new_theme ul li div.info h4{color:#fff;}
section.tr_list div.recommend_slide_new_theme ul li div.info p{color:#ffffff;}
section.tr_list div.recommend_slide_new_theme ul li:hover{box-shadow: 0px 4px 16px 0px #0000003D;}

.recommend_slide_wrap_new_theme .swiper-slide{box-shadow: 2px 2px 5px 3px rgb(228, 228, 228);}
section.tr_list div.recommend_slide_new_theme div.info{background:#ffffff;}
section.tr_list div.recommend_slide_new_theme div.tag_box{display:flex;align-items:center;justify-content:space-between;}
section.tr_list div.recommend_slide_new_theme div.tag_box img.logo{max-width:50%;}
section.tr_list div.recommend_slide_new_theme div.info span{line-height:24px;padding:0 10px;display:inline-block;}
section.tr_list div.recommend_slide_new_theme div.info h4{color:#000000;}
section.tr_list div.recommend_slide_new_theme div.info p{color:#000000;}
section.tr_list div.recommend_slide_new_theme div.swiper-slide div:hover {box-shadow: 0px 4px 16px 0px #0000003D;}
/* 2번째 슬라이드 끝 */
div.day_tab_wrap{margin-top:32px;}	
div.day_tab_wrap div.day_tab_bts{font-size:0;overflow-x:auto;padding:1px;}
div.day_tab_wrap div.day_tab_bts button{margin-left:8px;display:inline-block;vertical-align:middle;min-width:74px;height:36px;border-radius:50px;font-size:16px;font-weight:500;text-align:center;}
div.day_tab_wrap div.day_tab_bts button:first-child{margin-left:0;}
div.day_tab_wrap ul.day_tab_bts li a{background:#F0F0F5;}
div.day_tab_wrap ul.day_tab_bts li.on a{background:#222224;color:#fff;}

div.day_tab_contents{margin-top:24px;}
div.day_tab_contents div.dt_con{display:none;flex-wrap: nowrap;justify-content: space-between;}
div.day_tab_contents div.dt_con.on{display:flex;}
div.day_tab_contents div.dt_con div.map_img_box{flex:0 0 50%;max-width:calc(50% - 4px);max-width:-webkit-calc(50% - 4px);max-width:-moz-calc(50% - 4px);border-radius:16px;overflow:hidden;}
div.day_tab_contents div.dt_con div.map_img_box img{max-width:100%;width:100%;}
div.day_tab_contents div.dt_con ul.day_info_wrap{flex:0 0 50%;max-width:calc(50% - 4px);max-width:-webkit-calc(50% - 4px);max-width:-moz-calc(50% - 4px);}
div.day_tab_contents div.dt_con ul.day_info_wrap li{padding:16px 16px 16px 36px;background: #F0F0F5;border-radius:16px;margin-top:8px;}
div.day_tab_contents div.dt_con ul.day_info_wrap li:first-child{margin-top:0;}
div.day_tab_contents div.dt_con ul.day_info_wrap li div.inner{padding-left:20px;position:relative;border-left:1px dashed #173588;display:flex;justify-content: space-between;}
div.day_tab_contents div.dt_con ul.day_info_wrap li div.inner div.day_info{flex:1;max-width: calc(100% - 96px);max-width:-webkit-calc(100% - 96px);max-width:-moz-calc(100% - 96px);padding-right:10px}
div.day_tab_contents div.dt_con ul.day_info_wrap li div.inner div.day_info span.num{display:block;width:24px;height:24px;line-height:24px;position:absolute;top:5px;left:-13px;background:#2658E2;border-radius:50%;text-align:center;color:#fff;font-size: 18px;font-weight: 700;letter-spacing: -0.2px;outline:5px solid #F0F0F5;}
div.day_tab_contents div.dt_con ul.day_info_wrap li div.inner div.day_info h4{font-size: 24px;font-weight: 700;line-height:1.4;letter-spacing: -0.2px;white-space:nowrap;text-overflow:ellipsis;overflow:hidden;}
div.day_tab_contents div.dt_con ul.day_info_wrap li div.inner div.day_info h4 img{margin-top:-4px;}
div.day_tab_contents div.dt_con ul.day_info_wrap li div.inner div.day_info p{margin-top:8px;font-size: 18px;font-weight: 500;line-height:1.4;letter-spacing: -0.2px;color:#77777A;white-space:nowrap;text-overflow:ellipsis;overflow:hidden;}
div.day_tab_contents div.dt_con ul.day_info_wrap li div.inner div.day_info span.time{margin-top:8px;display:inline-block;border-radius:4px;border:1px solid #77777A;padding:6px;font-size: 14px;font-weight: 500;line-height:1;color:#77777A;}
div.day_tab_contents div.dt_con ul.day_info_wrap li div.inner div.img_box{width:96px;height:96px;border-radius:8px;overflow:hidden;}
div.day_tab_contents div.dt_con ul.day_info_wrap li div.inner div.img_box img{width:100%;border-radius:8px;}

.reciente_container .swiper-scrollbar{

}
.reciente_container .swiper{
	padding-bottom: 12px;
}

@media (max-width: 1440px){
	div.day_tab_contents div.dt_con{flex-wrap: wrap;justify-content: flex-start;flex-direction: column;}
	div.day_tab_contents div.dt_con div.map_img_box{flex:0 0 100%;max-width:100%;}
	div.day_tab_contents div.dt_con ul.day_info_wrap{flex:0 0 100%;max-width:100%;margin-top:16px;}

	div.recommend_slide{margin-top:50px;}
	div.recommend_slide div.swiper-slide{width:calc(33% - 7.5px) !important ;width:-webkit-calc(33% - 7.5px);width:-moz-calc(33% - 7.5px);}

	div.recommend_slide_new_theme{margin-top:50px;}
	div.recommend_slide_new_theme div.swiper-slide{width:calc(33% - 7.5px) !important ;width:-webkit-calc(33% - 7.5px);width:-moz-calc(33% - 7.5px);}
}
@media (max-width: 1024px){
	section.recommend{margin-top:80px;padding-bottom:10px;background-size:100% 50% !important;}
	section.recommend h3{font-size:25px;line-height:1.4;}
	section.recommend h3 span{vertical-align:middle;margin-top:-4px;border-bottom:2px solid #2658E2;}
	section.recommend h3 img{width:24px;margin:0 0 0 3px;}
	div.recommend_slide div.swiper-slide{width:calc(49.4% - 8px) !important;width:-webkit-calc(49.4% - 8px) !important;width:-moz-calc(49.4% - 8px) !important;}
	div.recommend_slide div.swiper-pagination{bottom:10px;}

	div.recommend_slide_new_theme div.swiper-slide{width:calc(49.4% - 8px) !important;width:-webkit-calc(49.4% - 8px) !important;width:-moz-calc(49.4% - 8px) !important;}
	div.recommend_slide_new_theme div.swiper-pagination{bottom:10px;}
}
@media (max-width: 768px){
	section.recommend div.center{width:100%;padding:0;}
	section.recommend div.center_search{width:100%;padding:0;}
	section.recommend h3{padding:0 16px;}
	div.recommend_slide{margin-top:23px;}
	div.recommend_slide div.swiper-slide{width:47% !important;}
	div.recommend_slide div.swiper-slide div.info{padding:18px;}
	div.recommend_slide div.swiper-slide div.info span{padding:0 7.5px;height:19px;line-height:19px;font-size:12px;}
	div.recommend_slide div.swiper-slide div.info h4{font-size:16px;margin-top:6px;}
	div.recommend_slide div.swiper-slide div.info p{font-size:12px;margin-top:6px;}
	div.recommend_slide_wrap{padding-left:5px;}
	div.recommend_slide_wrap div.swiper-button-next,
	div.recommend_slide_wrap div.swiper-button-prev{display:none;}
	div.recommend_slide div.swiper-pagination span.swiper-pagination-bullet{width:9px;height:9px;}

	div.recommend_slide_new_theme{margin-top:23px;}
	div.recommend_slide_new_theme div.swiper-slide{width:47% !important;}
	div.recommend_slide_new_theme div.swiper-slide div.info{padding:18px;}
	div.recommend_slide_new_theme div.swiper-slide div.info span{padding:0 7.5px;height:19px;line-height:19px;font-size:12px;}
	div.recommend_slide_new_theme div.swiper-slide div.info h4{font-size:16px;margin-top:6px;}
	div.recommend_slide_new_theme div.swiper-slide div.info p{font-size:12px;margin-top:6px;}
	div.recommend_slide_wrap_new_theme{padding-left:5px;}
	div.recommend_slide_wrap_new_theme div.swiper-button-next_new_theme,
	div.recommend_slide_wrap_new_theme div.swiper-button-prev_new_theme{display:none;}
	div.recommend_slide_new_theme div.swiper-pagination span.swiper-pagination-bullet{width:9px;height:9px;}
}
/* 추천 관광지 끝 */

/* 게시판 및 홈페이지 링크 */
section.board_preview{padding:120px 0;background:#F0F0F5;}
section.board_preview div.center{display:flex;align-items:stretch;justify-content: space-between;}
section.board_preview div.center_search{display:flex;align-items:stretch;justify-content: space-between;}
div.bp_list{width:528px;}
div.bp_list div.board_box{margin-top:50px;}
div.bp_list div.board_box:first-child{margin-top:0;}
div.bp_list div.board_box h3.tit{font-size: 36px;font-weight: 900;line-height:72px;letter-spacing: -0.02px;overflow:hidden;}
div.bp_list div.board_box h3.tit a{float:right;font-size:18px;font-weight: 500;line-height:72px;padding:0 12px;}
div.bp_list div.board_box h3.tit a img{width:24px;}
div.bp_list div.board_box ul{border-top:1px solid #77777A;}
div.bp_list div.board_box ul li{border-bottom:1px solid #D7D7DB;}
div.bp_list div.board_box ul li a{position:relative;padding-right:100px;cursor: pointer;}
div.bp_list div.board_box ul li h4{line-height:60px;font-size: 18px;font-weight: 500;white-space:nowrap;text-overflow:ellipsis;overflow:hidden;}
div.bp_list div.board_box ul li span{position:absolute;top:0;right:0;line-height:60px;text-align:right;color:#77777A;font-size:18px;font-weight:500;letter-spacing:-0.02px;}

div.home_links{flex:0 0 936px;margin-left:32px;display:flex;align-items:stretch;justify-content: space-between;}
div.home_links div.links{flex:1;display:flex;flex-direction: column;}
div.home_links div.links a{padding:40px 35% 40px 40px;border-radius: 24px;background:#fff;margin-top:16px;flex:1;cursor: pointer;}
div.home_links div.links a:first-child{margin-top:0;}
div.home_links div.links a h3{font-size:32px;font-weight:800;line-height:1.2;}
div.home_links div.links a p{margin-top:18px;color:#77777A;font-size:16px;font-weight:500;line-height:1.4;letter-spacing: -0.02px;}
div.home_links div.links a:nth-child(1){background:url('/public/images/bp_workspace.png')no-repeat 93% center #fff;background-size:39%;}
div.home_links div.links a:nth-child(2){background:url('/public/images/bp_Developer_Center.png')no-repeat 93% center #fff;background-size:39%;}

div.home_link_r{width:392px;margin-left:16px;background:#fff;border-radius: 24px;overflow:hidden;}
div.home_link_r ul li{padding:40px 24px 40px 40px;height:100%;}
div.home_link_r ul li h3{font-size:32px;font-weight:800;line-height:1.2;display:inline-block;cursor: pointer;}
div.home_link_r ul li p{margin-top:16px;color:#77777A;font-size:16px;font-weight:500;line-height:1.4;letter-spacing: -0.02px;}

div.home_link_r ul li.home_link_r_bg01{background:url('/public/images/home_link_r_bg01.png')no-repeat 85% 85% #fff;}
div.home_link_r ul li.home_link_r_bg02{background:url('/public/images/home_link_r_bg02.png')no-repeat 85% 85% #fff;}
div.home_link_r ul li.home_link_r_bg03{background:url('/public/images/home_link_r_bg03.png')no-repeat 85% 85% #fff;}
div.home_link_r ul li.home_link_r_bg04{background:url('/public/images/home_link_r_bg04.png')no-repeat 85% 85% #fff;}
div.home_link_r ul li.home_link_r_bg05{background:url('/public/images/home_link_r_bg05.png')no-repeat 85% 85% #fff;}


div.home_link_slide{height:100%;}
div.home_link_slide div.arrow{position:absolute;z-index:99;top:40px;left:205px;transition:all 0.7s ease-in-out;}
div.home_link_slide div.pagination_box{position:absolute;bottom:40px;text-align:left;padding-left:40px;font-size:0;width:100%;}
div.home_link_slide .swiper-pagination{position:relative;bottom:30px;left:40px;display:inline-block;width:auto;z-index: 1;}
div.home_link_slide .swiper-pagination span.swiper-pagination-bullet{width:12px;height:12px;background:#C3C3C7;border-radius:8px;opacity:1;transition:all 0.2s ease;opacity:1;}
div.home_link_slide .swiper-pagination span.swiper-pagination-bullet.swiper-pagination-bullet-active{width:24px;background:#313133;}
div.home_link_slide button.auto_slide_bt{display:inline-block;position:relative;z-index:1;background:none;outline:0;border:0;padding:0 4px;}

/* div.home_link_slide button.auto_slide_bt img{width:16px;}
div.home_link_slide button.auto_slide_bt img.play_img{display:none;}
div.home_link_slide button.auto_slide_bt img.stop_img{display:block;}
div.home_link_slide button.auto_slide_bt.stop img.play_img{display:block;}
div.home_link_slide button.auto_slide_bt.stop img.stop_img{display:none;} */

@media (max-width: 1740px){
	div.bp_list{width:35%;}
	div.home_links{flex:0 0 60%;margin-left:5%;}
	div.home_link_r{width:325px;}
	div.home_link_r ul li{background-size:55% !important;}
}
@media (max-width: 1440px){
	div.bp_list div.board_box h3.tit{font-size:27px;}
	div.bp_list div.board_box ul li h4{font-size:16px;line-height:50px;}
	div.bp_list div.board_box ul li span{font-size:16px;}

	div.home_links div.links a{padding:25px 35% 25px 25px;}
	div.home_links div.links a h3{font-size:27px;}
	div.home_link_r{width:250px;}
	div.home_link_r ul li{padding:25px 14px 25px 25px;}
	div.home_link_r ul li h3{font-size:27px;}
	div.home_link_slide div.arrow{top:20px;}

}

@media (max-width: 1280px){
	section.board_preview div.center{display:block;}
	section.board_preview div.center_search{display:block;}
	div.bp_list{width:100%;font-size:0;}
	div.bp_list div.board_box{width:48%;display:inline-block;vertical-align:top;margin:0;}
	div.bp_list div.board_box:first-child{margin-right:4%;}
	div.home_links{margin:4% 0 0 0;}
	div.home_link_r{width:48%;margin-left:4%;}
	div.home_links div.links a{}
	div.home_link_r ul li{background-size:30% !important;}
}

@media (max-width: 1024px){
	section.board_preview{padding:35px 0;}
	section.board_preview div.center{display:block;}
	section.board_preview div.center_search{display:block;}
	div.bp_list{width:100%;font-size:0;}
	div.bp_list div.board_box{width:100%;margin-top:42px;}
	div.bp_list div.board_box:first-child{margin:0;}
	div.bp_list div.board_box h3.tit{line-height:53px;}
	div.bp_list div.board_box h3.tit a{line-height:53px;font-size:13px;}
	div.bp_list div.board_box h3.tit a img{width:19px;}
	div.bp_list div.board_box ul li a{padding-right:80px;}
	div.bp_list div.board_box ul li h4{font-size:13px;line-height:44px;}
	div.bp_list div.board_box ul li span{font-size:13px;line-height:44px;}
	div.bp_list div.board_box ul li span img{width:30px;}
	div.home_links{margin:42px 0 0 0;;display:block;}
	div.home_links div.links{display:block;}
	div.home_links div.links a{display:block;width:100%;margin-top:8px;background-size:105px !important;}
	div.home_links div.links a h3{font-size:24px;}
	div.home_links div.links a h3 img{width:30px;}
	div.home_links div.links a p{font-size:12px;margin-top:8px;}
	div.home_link_r{width:100%;margin:8px 0 0 0;}
	div.home_link_r ul li{background-position:90% 80% !important;padding:25px 14px 55px 25px;}
	div.home_link_r ul li.home_link_r_bg01{background-size:70px !important;background-position:90% 70% !important;}
	div.home_link_r ul li.home_link_r_bg02{background-position:90% 70% !important;background-size:70px !important;}
	div.home_link_r ul li.home_link_r_bg03{background-size:80px !important;}
	div.home_link_r ul li.home_link_r_bg04{background-size:80px !important;}
	div.home_link_r ul li.home_link_r_bg05{background-size:80px !important;}
	div.home_link_r ul li h3{font-size:24px;}
	div.home_link_r ul li p{font-size:12px;margin-top:8px;}
	div.home_link_slide div.arrow{top:25px;}
	div.home_link_slide div.arrow img{width:30px;}
	div.home_link_r div.swiper-pagination{bottom:25px;padding-left:0px;}
	div.home_link_slide .swiper-pagination span.swiper-pagination-bullet{width:9px;height:9px;}
	div.home_link_slide .swiper-pagination span.swiper-pagination-bullet.swiper-pagination-bullet-active{width:18px;}
	div.home_link_slide button.auto_slide_bt img{width:12px;}
}
/* 게시판 및 홈페이지 링크 끝 */	
/* 메인페이지 끝 */

/* 추천관광지 상세 팝업 */
div.recommend_detail_page{position:fixed;top:0;left:0;width:100%;height:100%;z-index:88888;padding:8px;display:none;}
div.recommend_detail_page div.bg{position:fixed;top:0;left:0;width:100%;height:100%;overflow:hidden;z-index:-1;background:rgba(0,0,0,0.2);}
div.recommend_detail_page div.center{background:#fff;border-radius:32px;overflow:hidden;height:100%;padding:0;box-shadow: 0px 6px 8px 0px #0000003D;}
div.recommend_detail_page div.center button.btn_close{position:absolute;top:0;right:0;background:none;border:0;outline:0;padding:16px 19px 0 0;z-index:99999;}


div.recommend_detail_page div.center_search{background:#fff;border-radius:32px;overflow:hidden;height:100%;padding:0;box-shadow: 0px 6px 8px 0px #0000003D;}
div.recommend_detail_page div.center_search button.btn_close{position:absolute;top:0;right:0;background:none;border:0;outline:0;padding:16px 19px 0 0;z-index:9;}

/* 추천관광지 상세 팝업-통합검색 테마 */
/* div.recommend_detail_search_page{position:fixed;top:0;left:0;width:100%;height:100%;z-index:88888;padding:8px;display:none;} */
/* div.recommend_detail_search_page div.bg{position:fixed;top:0;left:0;width:100%;height:100%;overflow:hidden;z-index:-1;background:rgba(0,0,0,0.2);} */
/* div.recommend_detail_search_page div.center{background:#fff;border-radius:32px;overflow:hidden;height:100%;padding:0;box-shadow: 0px 6px 8px 0px #0000003D;} */
/* div.recommend_detail_search_page div.center button.btn_close{position:absolute;top:0;right:0;background:none;border:0;outline:0;padding:16px 19px 0 0;z-index:9;} */

/* 컨텐츠 리스트 */
/* div.recommend_detail_page div.center div.scroll_area{display:flex; overflow:auto; width:100%;height:100%;position:relative;justify-content: stretch;align-items: flex-start;background-image:url("/public/images/left_bg.png");background-repeat: repeat-y;background-size:544px;background-position:left top;background-color:#fff;} */
div.recommend_detail_page div.center div.scroll_area{display:flex; width:100%;height:100%;position:relative;justify-content: stretch;align-items: flex-start;background-image:url("/public/images/left_bg.png");background-repeat: repeat-y;background-size:544px;background-position:left top;background-color:#fff;}
/* div.recommend_detail_page div.center div.scroll_area > div.left{flex:0 0 544px;max-width:544px;min-height:100%;padding:16px;background:#F0F0F5;} */

/* contents 모달 스크롤바 스타일 */
div.recommend_detail_page div.center div.scroll_area > div.left{flex:0 0 544px;max-width:544px;min-height:100%;padding:16px;background:#F0F0F5; overflow: scroll; height: 100%; -ms-overflow-style: none; scrollbar-width: none;}
div.recommend_detail_page div.center div.scroll_area > div.left::-webkit-scrollbar{display: none}

div.recommend_detail_page div.center div.scroll_area > div.left div.m_head{display:none;padding:1px 1px 16px 1px;}
div.recommend_detail_page div.center div.scroll_area > div div.m_head{overflow:hidden;}
div.recommend_detail_page div.center div.scroll_area > div div.m_head button{background:none;border:0;outline:0;}
div.recommend_detail_page div.center div.scroll_area > div div.m_head button.rd_view_prev_bt{float:left;}
div.recommend_detail_page div.center div.scroll_area > div div.m_head button.close_bt{float:right;}
div.recommend_detail_page div.center div.scroll_area > div div.m_head button img{width:24px;}

div.recommend_detail_page div.center_search div.scroll_area{display:flex; overflow:auto; width:100%;height:100%;position:relative;justify-content: stretch;align-items: flex-start;background-image:url("/public/images/left_bg.png");background-repeat: repeat-y;background-size:544px;background-position:left top;background-color:#fff;}
div.recommend_detail_page div.center_search div.scroll_area > div.left{flex:0 0 544px;max-width:544px;min-height:100%;padding:16px;background:#F0F0F5;}
div.recommend_detail_page div.center_search div.scroll_area > div.left div.m_head{display:none;padding:1px 1px 16px 1px;}
div.recommend_detail_page div.center_search div.scroll_area > div div.m_head{overflow:hidden;}
div.recommend_detail_page div.center_search div.scroll_area > div div.m_head button{background:none;border:0;outline:0;}
div.recommend_detail_page div.center_search div.scroll_area > div div.m_head button.rd_view_prev_bt{float:left;}
div.recommend_detail_page div.center_search div.scroll_area > div div.m_head button.close_bt{float:right;}
div.recommend_detail_page div.center_search div.scroll_area > div div.m_head button img{width:24px;}

/* 컨텐츠 리스트-통합검색 테마 */
/* div.recommend_detail_search_page div.center div.scroll_area{display:flex;width:100%;height:100%;overflow:auto;position:relative;justify-content: stretch;align-items: flex-start;background-image:url("/public/images/left_bg.png");background-repeat: repeat-y;background-size:544px;background-position:left top;background-color:#fff;} */
/* div.recommend_detail_search_page div.center div.scroll_area{display:flex;width:100%;height:100%;overflow:auto;position:relative;justify-content: stretch;align-items: flex-start;background-repeat: repeat-y;background-size:544px;background-position:left top;background-color:#fff;}
div.recommend_detail_search_page div.center div.scroll_area > div.left{flex:0 0 310px;max-width:310px;min-height:100%;padding:16px;background:#F0F0F5; overflow-y: scroll; height: 1100px;}
div.recommend_detail_search_page div.center div.scroll_area > div.left div.m_head{display:none;padding:1px 1px 16px 1px;}
div.recommend_detail_search_page div.center div.scroll_area > div div.m_head{overflow:hidden;}
div.recommend_detail_search_page div.center div.scroll_area > div div.m_head button{background:none;border:0;outline:0;}
div.recommend_detail_search_page div.center div.scroll_area > div div.m_head button.rd_view_prev_bt{float:left;}
div.recommend_detail_search_page div.center div.scroll_area > div div.m_head button.close_bt{float:right;}
div.recommend_detail_search_page div.center div.scroll_area > div div.m_head button img{width:24px;} */

div.video_area{width:100%;position:relative;border-radius:24px;overflow:hidden;}
div.video_area:after{content:'';display:block;position:absolute;top:0;left:0;background:rgba(0,0,0,0.5);width:100%;height:100%;}
div.video_area img{width:100%;}
div.video_area div.va_top_bts{display: flex; gap: 0 6px; position:absolute;top:0;right:0;font-size:0;z-index:1;padding:24px 24px 0 0;}
div.video_area div.va_top_bts button{width:32px;height:32px; background:url("/public/images/content_list_btn_action.png")no-repeat center center;background-position:0 0;border:0;outline:0;}
div.video_area div.va_top_bts button:first-child{margin-left:0;}
div.video_area div.va_top_bts button.share{background-position:-16px -16px;}
div.video_area div.va_top_bts button.share:hover{background-position:-16px -60px;}
div.video_area div.va_top_bts button.share:focus{background-position:-16px -104px;}
div.video_area div.va_top_bts button.share:active{background-position:-16px -104px;}
div.video_area div.va_top_bts .MuiCircularProgress-root > svg{width: 20px; height: 24px; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);}
div.video_area div.va_top_bts button.excel{background-position:-60px -16px;}
div.video_area div.va_top_bts button.excel:hover{background-position:-60px -60px;}
div.video_area div.va_top_bts button.excel:focus{background-position:-60px -104px;}
div.video_area div.va_top_bts button.excel:active{background-position:-60px -104px;}
div.video_area div.va_top_bts button.pdf{background-position:-104px -16px;}
div.video_area div.va_top_bts button.pdf:hover{background-position:-104px -60px;}
div.video_area div.va_top_bts button.pdf:focus{background-position:-104px -104px;}
div.video_area div.va_top_bts button.pdf:active{background-position:-104px -104px;}

div.video_area div.va_info{position:absolute;bottom:0;left:0;padding:0 24px 24px 24px;z-index:1;width:100%;}
.list_wrap .left.fixed div.video_area div.va_info h3{padding-right: 121px;}
div.video_area div.va_info h3{font-size: 24px;font-weight: 700;line-height: 29px;letter-spacing: -0.2px;color:#fff;overflow:hidden;text-overflow:ellipsis;white-space:nowrap;}
div.video_area div.va_info p.date{margin-top:4px;font-size: 16px;font-weight: 500;line-height: 21px;letter-spacing: -0.2px;color:#D7D7DB;}
div.video_area div.va_info div.count{margin-top:8px;overflow:hidden;}
div.video_area div.va_info div.count h5{font-size: 18px;font-weight:700;float:left;color:#D7D7DB;}
div.video_area div.va_info div.count span{letter-spacing: -0.2px;font-size: 16px;font-weight: 500;display:block;float:right;color:#D7D7DB;padding-left:8px;position:relative;}
/* div.video_area div.va_info div.count span:before{content:'';display:block;width:2px;height:14px;position:absolute;top:50%;left:-2px;margin-top:-7px;background:#D7D7DB;z-index:1;} */

ul.recommend_list{margin-top:16px;width:100%;}
ul.recommend_list li{border-radius:24px;overflow:hidden;margin-top:4px;}
ul.recommend_list li:first-child{margin-top:0px;}
ul.recommend_list li a{display:flex !important;padding:16px;border-radius:24px;border:1px solid transparent;}
ul.recommend_list li a:hover{background:#E6E6EB;}
ul.recommend_list li a:focus{background:none;border:1px solid #000;}
ul.recommend_list li a:active{background:#C3C3C7;border:1px solid #C3C3C7 !important;}
ul.recommend_list li.on a{background:#fff !important;}

ul.recommend_list li div.thum{flex:0 0 120px;width:120px;height:120px;overflow:hidden;border-radius:16px;}
ul.recommend_list li div.thum img{width:100%;height: 100%; object-fit: cover;}
ul.recommend_list li div.info{flex:1;padding-left:16px;max-width:calc(100% - 120px);max-width:-webkit-calc(100% - 120px);max-width:-moz-calc(100% - 120px);}
ul.recommend_list li div.info div.tag_list{font-size:0;}
ul.recommend_list li div.info div.tag_list span{margin-right:6px;}
ul.recommend_list li div.info div.tag_list span:last-child{margin-right:0;}
ul.recommend_list li div.info h3{margin-top:16px;font-size:18px;font-weight:700;line-height:1.3;letter-spacing:-0.2px;overflow:hidden;text-overflow:ellipsis;white-space:nowrap;}
ul.recommend_list li div.info p{margin-top:7px;font-size:14px;font-weight:500;line-height:1.3;letter-spacing:-0.2px;color:#77777A;}
/* 컨텐츠 리스트 끝 */

/* 컨텐츠 디테일 뷰 */
/* div.recommend_detail_page div.center div.scroll_area > div.right{flex:1;padding:32px 0 32px 32px;width:calc(100% - 544px);width:-webkit-calc(100% - 544px);width:-moz-calc(100% - 544px);min-height:100%;position:relative;} */
div.recommend_detail_page div.center div.scroll_area > div.right{overflow-y: scroll; height: 100%;flex:1;padding:32px 0 32px 32px;width:calc(100% - 544px);width:-webkit-calc(100% - 544px);width:-moz-calc(100% - 544px);min-height:100%;position:relative;}
div.recommend_detail_page div.center div.scroll_area > div.right::-webkit-scrollbar{display: none}
div.recommend_detail_page div.center div.scroll_area > div.right div.m_head{display:none;}
div.recommend_detail_page div.center div.scroll_area > div.right div.rd_view_list{overflow:hidden;position:relative;}
div.recommend_detail_page div.center div.scroll_area > div.right div.rd_view_list div.rd_view_item{opacity:0;width:0;height:0;position:absolute;top:-10px;left:-10px;overflow:hidden;}
div.recommend_detail_page div.center div.scroll_area > div.right div.rd_view_list div.rd_view_item.active{opacity:1;width:100%;height:100%;position:relative;top:0;left:0;overflow:unset;}

div.recommend_detail_page div.center_search div.scroll_area > div.right{flex:1;padding:32px 0 32px 32px;width:calc(100% - 544px);width:-webkit-calc(100% - 544px);width:-moz-calc(100% - 544px);min-height:100%;position:relative;}
div.recommend_detail_page div.center_search div.scroll_area > div.right div.m_head{display:none;}
div.recommend_detail_page div.center_search div.scroll_area > div.right div.rd_view_list{overflow:hidden;position:relative;}
div.recommend_detail_page div.center_search div.scroll_area > div.right div.rd_view_list div.rd_view_item{opacity:0;width:0;height:0;position:absolute;top:-10px;left:-10px;overflow:hidden;}
div.recommend_detail_page div.center_search div.scroll_area > div.right div.rd_view_list div.rd_view_item.active{opacity:1;width:100%;height:100%;position:relative;top:0;left:0;overflow:unset;}

/* 컨텐츠 디테일 뷰-통합검색 테마 */
/* div.recommend_detail_search_page div.center div.scroll_area > div.right{flex:1;padding:0px 0px 0px 10px;width:calc(100% - 544px);width:-webkit-calc(100% - 544px);width:-moz-calc(100% - 544px);min-height:100%;position:relative;}
div.recommend_detail_search_page div.center div.scroll_area > div.right div.m_head{display:none;}
div.recommend_detail_search_page div.center div.scroll_area > div.right div.rd_view_list{overflow:hidden;position:relative;}
div.recommend_detail_search_page div.center div.scroll_area > div.right div.rd_view_list div.rd_view_item{opacity:0;width:0;height:0;position:absolute;top:-10px;left:-10px;overflow:hidden;}
div.recommend_detail_search_page div.center div.scroll_area > div.right div.rd_view_list div.rd_view_item.active{opacity:1;width:100%;height:100%;position:relative;top:0;left:0;overflow:unset;} */

/* div.language_list{padding:2px;margin-bottom:24px;overflow-x: auto;} */

.share_copy {position: absolute;right: 30px;white-space: nowrap;height: 40px;padding: 0 20px;color: white;border-radius: 5px;background-color: rgba(0, 0, 0, 0.5);font-size: 14px;z-index: 20;top : 120px;display: flex;align-items: center;}
.share_copy_theme {position: absolute;right: 30px;white-space: nowrap;height: 40px;padding: 0 20px;color: white;border-radius: 5px;background-color: rgba(0, 0, 0, 0.5);font-size: 14px;z-index: 20;top : 70px;display: flex;align-items: center;}
.share_copy_casual {position: absolute;right: 3px;white-space: nowrap;height: 40px;padding: 0 20px;color: white;border-radius: 5px;background-color: rgba(0, 0, 0, 0.5);font-size: 14px;z-index: 20;top: 36px;display: flex;align-items: center;}
.share_copy_video{position: absolute;right: 13px;white-space: nowrap;height: 40px;padding: 0 20px;color: white;border-radius: 5px;background-color: rgba(0, 0, 0, 0.5);font-size: 14px;z-index: 20;top: 73px;display: flex;align-items: center;}

div.language_list{padding:2px;margin-bottom:24px; overflow-x: auto;}
div.language_list::-webkit-scrollbar{width: 8px; height: 4px; background-color: #f5f5f5;}

div.language_list ul{margin-bottom: 10px;}
div.language_list ul li{width:auto !important;margin-right:8px;}
div.language_list button{padding:0 16px;height:36px;border-radius:24px;font-weight:500;font-size:13px;}

.css-1ald77x.Mui-focused{color: black !important;}

div.contents_detail_page{padding-right:32px;}
div.cdp_tit_area{display:flex;}
div.cdp_tit_area h2.cdp_tit{flex:1;font-size: 36px;font-weight: 900;line-height: 43px;letter-spacing: -0.2px; text-overflow: ellipsis; overflow: hidden; white-space: nowrap;}

div.cdp_tit_area h2.cdp_tit span.sub_cdp_tit{display:block;margin-top:24px;font-size:14px;color:#5E5E61;font-weight:500;line-height:1.4;}
div.cdp_tit_area h2.cdp_tit span.sub_cdp_tit span.line{display:inline-block;width:1px;height:14px;background:#C3C3C7;margin:0 22px;}
div.cdp_tit_area div.bts{}
div.cdp_tit_area div.bts button{width:32px;height:32px;display:inline-block;vertical-align:top;background:url("/public/images/content_list_btn_action_x.png")no-repeat center center;background-position:0 0;border:0;outline:0;margin-left:6px;}
div.cdp_tit_area div.bts button:first-child{margin-left:0;}
div.cdp_tit_area div.bts button.share{background-position:-16px -148px;}
div.cdp_tit_area div.bts button.share:hover{background-position:-16px -192px;}
div.cdp_tit_area div.bts button.share:focus{background-position:-16px -236px;}
div.cdp_tit_area div.bts button.share:active{background-position:-16px -236px;}

div.cdp_tit_area div.bts .MuiCircularProgress-root > svg{width: 20px; height: 24px; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); color: #0B1A44;}
div.cdp_tit_area div.bts .pdf-progress{width: 32;height: 32;color: #eaeaea;position: relative;margin-top: 2px;}
div.cdp_tit_area div.bts button.pdf-btn{background-position:-96px -144px;margin-top: 4px;margin-right: 4px;}
div.cdp_tit_area div.bts button.pdf-btn:hover{background-position:-96px -101px;margin-top: 4px;margin-right: 4px;}
div.cdp_tit_area div.bts button.pdf-btn:focus{background-position:-96px -101px;margin-top: 4px;margin-right: 4px;}
div.cdp_tit_area div.bts button.pdf-btn:active{background-position:-96px -101px;margin-top: 4px;margin-right: 4px;}

div.cdp_tit_area div.bts button.bmark{background-position:-60px -148px;}
div.cdp_tit_area div.bts button.bmark:hover{background-position:-60px -192px;}
div.cdp_tit_area div.bts button.bmark:focus{background-position:-60px -236px;}
div.cdp_tit_area div.bts button.bmark:active{background-position:-60px -236px;}

div.cdp_tit_area div.bts button.menu{background:url("../../public/images/content_list_btn_menu.png")no-repeat center center;}

div.rd_view_video_area{width:100%;max-width:640px;margin-top:24px;}
div.rd_view_video_area img{width:100%;}

div.rd_view_img_area{width:100%;max-width:720px;margin-top:24px;}
div.rd_view_img_area img{width:100%; height: auto!important;}

div.cdp_slide_wrap{margin-top:24px;}
div.cdp_slide_wrap ul li img{width:100%;}
div.cdp_slide_wrap div.cdp_slide_controll_box{position:relative;text-align:center;font-size:0;line-height:24px;margin-top:16px;}
div.cdp_slide_wrap div.cdp_slide_controll_box button{background:none;border:0;outline:0;display:inline-block;vertical-align:top;margin:0 4px;}
div.cdp_slide_wrap div.swiper-pagination{position:relative;bottom:0;display:inline-block;vertical-align:top;width:auto;}
div.cdp_slide_wrap div.swiper-pagination span.swiper-pagination-bullet{width:8px;height:8px;opacity:1;background:#C3C3C7;margin:0 4px;}
div.cdp_slide_wrap div.swiper-pagination span.swiper-pagination-bullet.swiper-pagination-bullet-active{background:#313133;}

div.cdp_slide_wrap.course{padding-top:24px;border-top:1px solid #D7D7DB;}
p.course_txt{margin-top:16px;font-size: 18px;font-weight: 500;line-height:1.6;letter-spacing: -0.2px;color:#000;}

div.contents_detail_info_area{margin-top:24px;}
div.category{font-size:0;width:auto;display:inline-block;}
div.category h3{background:#454547; display:inline-block;vertical-align:top;font-size:14px;color:#fff;line-height:32px;padding:0 16px;border-radius:8px 0 0 8px;border:1px solid #454547;font-weight:700;}
div.category ul{display:inline-block;vertical-align:top;font-size:0;border-radius:0 8px 8px 0;border:1px solid #454547;}
div.category ul li{display:inline-block;vertical-align:top;color:#454547;padding:0 16px;font-size:14px;color:#454547;line-height:32px;position:relative;}
div.category ul li:after{content:'';display:block;position:absolute;top:50%;left:-8px;width:16px;height:16px;background:url('/public/images/category_arrow.png')no-repeat center;margin-top:-8px;}
div.category ul li:first-child:after{display:none;}

h4.cdi_tit{font-size:16px;color:#222224;font-weight:700;}
h4.cdi_tit span{font-weight:500;margin-left:8px;margin-top:-1px;}
p.exp{margin-top:8px;word-break:keep-all;font-size: 16px;font-weight: 400;line-height:1.4;letter-spacing: -0.4px;color:#313133;}
p.exp p {
	text-align: left !important; /* text-align을 무시하고 왼쪽 정렬로 설정 */
	word-break: keep-all; /* 단어를 중간에서 잘라 줄 바꿈을 허용 */
}
div.contents_detail_info{display:flex; margin-bottom: 60px;}
/* div.contents_detail_info div.left{flex:1;max-width:calc(50% - 12px);max-width:-webkit-calc(50% - 12px);max-width:-moz-calc(50% - 12px);} */
div.contents_detail_info div.left{flex:1;width: 100%;}
div.contents_detail_info div.left h4.cdi_tit{margin-top:24px;}
div.contents_detail_info div.left h4.first-child{margin-top:0;}
div.contents_detail_info div.left p.exp.pre{white-space: break-spaces;}
div.contents_detail_info div.left p.exp.address{color:#222224;}
div.contents_detail_info div.left p.exp.copyright{color:#77777A;}
div.contents_detail_info div.left img.copyright_img{margin-top:16px;max-width:100%;}
div.contents_detail_info div.left div.map{margin-top:8px;width:100%;}
div.contents_detail_info div.left div.map img{width:100%;}
div.contents_detail_info div.left ul.registration_info{margin-top:8px;display:flex;}
div.contents_detail_info div.left ul.registration_info li{padding:20px 24px;border-radius: 8px;background:#F0F0F5;margin-left:8px;flex:1;max-width:auto;}
div.contents_detail_info div.left ul.registration_info li:first-child{margin-left:0;}
div.contents_detail_info div.left ul.registration_info li h5{font-size: 14px;font-weight: 700;line-height:1.2;color:#313133;}
div.contents_detail_info div.left ul.registration_info li p{margin-top:8px;font-size:14px;font-weight:400;line-height:1.2;letter-spacing: -0.4px;color:#5E5E61;}

div.contents_detail_info div.right{flex:1;margin:40px 0 0 24px;max-width:calc(50% - 12px);max-width:-webkit-calc(50% - 12px);max-width:-moz-calc(50% - 12px);}
ul.visit_info{}
ul.visit_info li{display:flex;margin-top:24px;}
ul.visit_info li:first-child{margin-top:0;}
ul.visit_info li i.icon{flex:0 0 24px;}
ul.visit_info li div.ex{flex:1;padding-left:8px;}
ul.visit_info li div.ex h5{font-size:16px;font-weight:700;line-height:1;margin-bottom:8px;}
ul.visit_info li div.ex p{}
ul.visit_info li div.ex p,
ul.visit_info li div.ex p a{font-size:16px;font-weight:400;line-height:1.5;letter-spacing:-0.4px;color:#5E5E61;}
ul.visit_info li div.ex p a{display:inline-block;}
ul.visit_info li div.ex p button.copy{display:inline-block;vertical-align:top;background:none;border:0;margin-left:8px;}

/* 인터뷰형 */
div.contents_detail_info_area.article_page{border-top:1px solid #D7D7DB;padding-top:24px;}
div.contents_detail_info_area.article_page div.left{max-width:747px;flex:1;}
div.contents_detail_info_area.article_page div.right{min-width:253px;flex:0 0 253px;margin:0 0 0 40px;}
div.contents_detail_info_area.article_page div.left div.article_img_box{margin-top:24px;}

p.article_txt1{font-size: 18px;font-weight: 700;letter-spacing: -0.2px;line-height:1.4;}
div.article_img_box{width:100%;}
div.article_img_box img{width:100%;}
div.article_img_box p.article_img_txt{font-size: 14px;font-weight: 400;line-height:1.4;letter-spacing: -0.4px;color:#5E5E61;margin-top:16px;}

div.interview_box{margin-top:24px;}
div.interview_box h4.interview_tit{font-size: 24px;font-weight: 900;line-height:1.4;color:#000;}
div.interview_box h5.q{font-size:16px;font-weight:500;line-height:1.4;letter-spacing: -0.4px;color:#5E5E61;}
div.interview_box p.a{margin-top:13px;font-size:16px;font-weight:700;line-height:1.4;letter-spacing: -0.4px;}
div.interview_box p.a.line{padding-left:24px;border-left:1px solid #5E5E61;}

div.contents_detail_info_area.article_page p.point_txt{padding:24px;margin-top:24px;background: #2658E2;font-size: 18px;font-weight: 700;line-height:1.6;letter-spacing: -0.2px;border-radius:8px;color:#fff;}
div.contents_detail_info_area.article_page p.point_txt2{padding:24px;margin-top:24px;background: #F0F0F5;font-size: 18px;font-weight: 700;line-height:1.6;letter-spacing: -0.2px;border-radius:8px;color:#313133;}

div.visit_info_link_box{margin-top:40px;}
div.visit_info_link_box a.visit_info_link{padding:12px 16px;background: #F0F0F5;border-radius:8px;display:inline-block;}

div.copyright_box{margin-top:24px;}
div.copyright_box img.copyright_img{margin-top:16px;max-width:100%;}

@media (max-width: 1600px){
	div.contents_detail_info_area.article_page{}
	div.contents_detail_info_area.article_page div.contents_detail_info{flex-direction: column;}
	div.contents_detail_info_area.article_page div.left{max-width:100%;flex:1;}
	div.contents_detail_info_area.article_page div.right{min-width:100%;flex:1;margin:40px 0 0 0;}
	div.contents_detail_info_area.article_page div.right ul.visit_info{display:flex;flex-wrap: wrap;padding:0 4px;}
	div.contents_detail_info_area.article_page div.right ul.visit_info li{flex:0 0 50%;margin:0;padding:12px;}
}
@media (max-width: 768px){
	div.contents_detail_info_area.article_page div.right ul.visit_info{display:flex;flex-wrap: wrap;padding:0 4px;}
	div.contents_detail_info_area.article_page div.right ul.visit_info li{flex:0 0 100%;margin:0;padding:12px;}
	div.cdp_tit_area h2.cdp_tit span.sub_cdp_tit span.line{margin:0 5px;}
	div.cdp_tit_area.course{flex-direction: column-reverse;}
	div.cdp_tit_area.course div.bts{text-align: right;}
	div.cdp_tit_area.course h2.cdp_tit{margin-top:8px;}
	div.cdp_tit_area.course h2.cdp_tit span.sub_cdp_tit{margin-top:14px;}
}
/* 인터뷰형 끝 */
/* 컨텐츠 디테일 뷰 끝 */

@media (max-width: 1440px){
	div.recommend_detail_page div.center div.scroll_area{background-size:40%;}
	div.recommend_detail_page div.center div.scroll_area > div.left{flex:0 0 40%;max-width:40%;}

	div.recommend_detail_page div.center_search div.scroll_area{background-size:40%;}
	div.recommend_detail_page div.center_search div.scroll_area > div.left{flex:0 0 40%;max-width:40%;}

	/* 통합검색 테마 */
	/* div.recommend_detail_search_page div.center div.scroll_area{background-size:40%; overflow: hidden;}
	div.recommend_detail_search_page div.center div.scroll_area > div.left{flex:0 0 40%;max-width:40%;} */

	div.video_area div.va_info h3{font-size:20px;}
	div.video_area div.va_info p.date{font-size:14px;margin-top:0;}
	div.video_area div.va_info div.count{margin-top:4px;}
	div.video_area div.va_info div.count h5{font-size:16px;}
	div.video_area div.va_info div.count span{font-size:14px;}

	ul.recommend_list li div.thum{flex:0 0 100px;width:100px;height:100px;}
	ul.recommend_list li div.info{max-width:calc(100% - 100px);max-width:-webkit-calc(100% - 100px);max-width:-moz-calc(100% - 100px);}
	ul.recommend_list li div.info h3{font-size:20px;}
	ul.recommend_list li div.info p{font-size:16px;}

	div.cdp_tit_area h2.cdp_tit{font-size:30px;line-height:32px;}

	div.contents_detail_info div.left ul.registration_info{flex-wrap: wrap; margin-bottom: 20px;}
	div.contents_detail_info div.left ul.registration_info li:nth-child(1){flex:0 0 50%;max-width:calc(50% - 4px);max-width:-webkit-calc(50% - 4px);max-width:-moz-calc(50% - 4px);}
	div.contents_detail_info div.left ul.registration_info li:nth-child(2){flex:0 0 50%;max-width:calc(50% - 4px);max-width:-webkit-calc(50% - 4px);max-width:-moz-calc(50% - 4px);}
	div.contents_detail_info div.left ul.registration_info li:nth-child(3){flex:0 0 100%;margin:8px 0 0;}
}

@media (max-width: 1280px){
}
@media (max-width: 1024px){
	.tag{line-height:22px;height:22px;font-size:14px;}
	div.recommend_detail_page{padding:0; padding-top: unset !important; padding-bottom: unset !important;}
	div.recommend_detail_page div.center{border-radius:0;width:100% !important;}
	div.recommend_detail_page div.center div.scroll_area{background:#fff;display:block;}
	div.recommend_detail_page div.center div.scroll_area > div.left{background:#fff;width:100%;flex:0 0 100%;max-width:100%;}
	div.recommend_detail_page div.center div.scroll_area > div.left div.m_head{display:block;}

	div.recommend_detail_page div.center_search{border-radius:0;width:100% !important;}
	div.recommend_detail_page div.center_search button.btn_close{display:none;}
	div.recommend_detail_page div.center_search div.scroll_area{background:#fff;display:block;}
	div.recommend_detail_page div.center_search div.scroll_area > div.left{background:#fff;width:100%;flex:0 0 100%;max-width:100%;}
	div.recommend_detail_page div.center_search div.scroll_area > div.left div.m_head{display:block;}

	div.recommend_detail_page.m_detail_open div.center div.scroll_area > div.left{overflow:hidden;height:100%;}

	div.recommend_detail_page.m_detail_open div.center_search div.scroll_area > div.left{overflow:hidden;height:100%;}

	/* 통합검색 테마 */
	/* div.recommend_detail_search_page{padding:0;}
	div.recommend_detail_search_page div.center{border-radius:0;width:100%;}
	div.recommend_detail_search_page div.center button.btn_close{display:none;}
	div.recommend_detail_search_page div.center div.scroll_area{background:#fff;display:block;}
	div.recommend_detail_search_page div.center div.scroll_area > div.left{background:#fff;width:100%;flex:0 0 100%;max-width:100%;}
	div.recommend_detail_search_page div.center div.scroll_area > div.left div.m_head{display:block;} */
	div.recommend_detail_search_page.m_detail_open div.center div.scroll_area > div.left{overflow:hidden;height:100%;}

	div.recommend_detail_search_page.m_detail_open div.center_search div.scroll_area > div.left{overflow:hidden;height:100%;}
	

	ul.recommend_list{margin-top:8px;}
	ul.recommend_list li a{padding:12px;}
	ul.recommend_list li a:hover{background:#F0F0F5 !important}
	ul.recommend_list li.on a{background:#F0F0F5 !important;}
	ul.recommend_list li div.thum{flex:0 0 80px;width:80px;height:80px;}
	ul.recommend_list li div.info{max-width:calc(100% - 80px);max-width:-webkit-calc(100% - 80px);max-width:-moz-calc(100% - 80px);}
	ul.recommend_list li div.info h3{font-size:16px;}
	ul.recommend_list li div.info p{font-size:14px;margin-top:4px;}

	/* div.recommend_detail_page div.center div.scroll_area > div.right{position:absolute;top:0;left:0;padding:0;width:100%;height:100%;overflow-y:auto;background:#fff;z-index:99;} */
	div.recommend_detail_page div.center div.scroll_area > div.right{position:absolute;top:0;left:0;padding:0;width:100%;height:100%;background:#fff;z-index:99;}
	div.recommend_detail_page div.center div.scroll_area > div.right div.m_head{display:block;padding:16px;}

	div.recommend_detail_page div.center_search div.scroll_area > div.right{position:absolute;top:0;left:0;padding:0;width:100%;height:100%;background:#fff;z-index:99;}
	div.recommend_detail_page div.center_search div.scroll_area > div.right div.m_head{display:block;padding:16px;}

	/* 통합검색 테마 */
	/* div.recommend_detail_search_page div.center div.scroll_area > div.right{position:absolute;top:0;left:0;padding:0;width:100%;height:100%;background:#fff;z-index:99;}
	div.recommend_detail_search_page div.center div.scroll_area > div.right div.m_head{display:block;padding:16px;} */

	div.language_list{margin-bottom:16px;}

	div.contents_detail_page{margin-top:0;padding:0 16px 16px 16px;}
	div.cdp_tit_area h2.cdp_tit{font-size:24px;line-height:28px;}
	div.contents_detail_info{display:block;}
	div.contents_detail_info div.left{width:100%;max-width:100%;}
	div.contents_detail_info div.right{width:100%;max-width:100%;margin:32px 0 0 0;}
	div.rd_view_video_area{margin-top:16px;}
	div.rd_view_img_area{max-width: none; margin-top:16px;}
	div.category h3{font-size:12px;}
	div.category ul li{padding:0 8px;font-size:12px;}
}
/* 추천관광지 상세 팝업 끝 */

/* 소개 페이지 */
div#contents.intro{}
section.intro_visual_wrap{padding:0;background:url('/public/images/intro_visual_bg.png')no-repeat center top #fff;background-size:contain;}
ul.nav{padding-top:8px;}
ul.nav li{display:inline-block;vertical-align:top;position:relative;padding:0 16px;color:#77777A;font-size:14px;font-weight:500;line-height:32px;}
ul.nav li:before{content:'';display:block;width:16px;height:16px;position:absolute;top:50%;left:-12px;background:url('/public/images/icon_nav_move_next_S.png')no-repeat center;margin-top:-8px;}
ul.nav li:first-child{padding-left:0;}
ul.nav li:first-child:before{display:none;}
ul.nav li i.icon{width:16px;height:16px;display:inline-block;vertical-align:middle;margin:-1px 0 0 4px;}
ul.nav li i.icon.home{background:url('/public/images/icon_nav_home.png')no-repeat center;}
ul.nav li i.icon.down_circle{background:url('/public/images/icon_nav_down_circle.png')no-repeat center;}
ul.nav li i.icon.up_circle{background:url('/public/images/icon_nav_up_circle.png')no-repeat center;}
ul.nav li span{color:#313133;}

ul.nav.white li{color:#E9EEFC;}
ul.nav.white li:before{background:url('/public/images/icon_nav_move_next_S_w.png')no-repeat center;}
ul.nav.white li i.icon.home{background:url('/public/images/icon_nav_home_w.png')no-repeat center;}
div.intro_visual{padding:120px 0 0 136px;}
div.intro_visual img.m_bg_img{display:none;}
div.intro_visual p{font-size: 24px;font-weight: 900;line-height:29px;color:#77777A;}
div.intro_visual h1{font-size:30px;font-weight: 900;line-height:58px;margin-top:10px;}
div.intro_visual div.links{margin-top:56px;font-size:0;}
div.intro_visual div.links button{display:inline-block;vertical-align:top;margin-left:16px;height:52px;padding:0 16px;border-radius:8px !important;}
div.intro_visual div.links button:first-child{margin-left:0;}

div.overview{padding:185px 0 136px 0;}
div.overview ul{display:flex;}
div.overview ul li{flex:1;max-width:100%;margin-left:16px;padding:40px;border-radius: 24px;background:#F0F0F5;}
div.overview ul li:first-child{margin-left:0;}
div.overview ul li span.icon{}
div.overview ul li h2{margin-top:24px;font-size: 36px;font-weight: 900;line-height: 43px;letter-spacing: -0.2px;}
div.overview ul li p{margin-top:24px;font-size:18px;font-weight: 500;line-height:27px;letter-spacing: -0.2px;}

@media (max-width: 1440px){
	section.intro_visual_wrap{padding:0;background:url('/public/images/intro_visual_bg.png')no-repeat center top #fff;background-size:110%;}
	div.intro_visual{padding:8% 0 0 5%;}
	div.intro_visual p{font-size:20px;line-height:1.4;}
	div.intro_visual h1{font-size:30px;line-height:1.3;margin-top:35px;}
	div.intro_visual div.links{margin-top:56px;}

	div.overview{padding:13% 0 10% 0;}
	div.overview ul li{padding:30px;}
	div.overview ul li h2{margin-top:20px;font-size:30px;line-height:1.4;}
	div.overview ul li p{margin-top:20px;font-size:16px;line-height:1.4;}

	div.copyright_policy_content{}
}

div.intro_visual span.intro-tit{font-size: 35px;}


@media (max-width: 1024px){
	section.intro_visual_wrap{padding:0;background:url('/public/images/intro_visual_bg_m.png')no-repeat right top #fff;background-size:unset;}
	div.nav_wrap{display:none;}
	div.intro_visual{padding:22px 0 0 0;}
	div.intro_visual p{font-size:16px;}
	div.intro_visual h1{font-size:24px;margin-top:16px;}
	div.intro_visual div.links{margin-top:180px;}
	div.intro_visual div.links button{margin:0 30% 16px 0;height:39px;padding:0 12px;}
	div.intro_visual div.links button:last-child{margin-bottom:0;}
	div.intro_visual div.links button img{width:18px;margin-top:-2px;}

	div.overview{padding:40px 0;}
	div.overview ul{display:block;}
	div.overview ul li{padding:24px;margin:12px 0 0 0;}
	div.overview ul li:first-child{margin-top:0;}
	div.overview ul li span.icon img{width:30px;}
	div.overview ul li h2{margin-top:8px;font-size:27px;}
	div.overview ul li p{margin-top:8px;font-size:13px;}
}
@media (max-width: 768px){
	section.intro_visual_wrap{padding:0;background:url('/public/images/intro_visual_bg_m.png')no-repeat right -20px #fff;background-size:unset;}
	div.intro_visual h1{font-size:18px;}
	div.intro_visual span.intro-tit{font-size:32px;}
	div.intro_visual span.intro_des{font-size:16.5px;}
	div.intro_visual div.links button{font-size:14px;}
}
@media (max-width: 360px){
	section.intro_visual_wrap{background:none;}
	div.intro_visual img.m_bg_img{display:block;width:calc(100% + 16px);width:-webkit-calc(100% + 16px);width:-moz-calc(100% + 16px);margin:16px -16px 0 0;}
	div.intro_visual div.links{margin-top:-10%;}
	div.intro_visual div.links button{margin:0 10% 16px 0;}
}

section.copyright_policy{background:#F0F0F5;padding:96px 0 120px 0;}
section.copyright_policy div.tit_area{display:flex;align-items:center;}
section.copyright_policy div.tit_area h3{flex:0 0 273px;font-size: 36px;font-weight: 900;line-height: 43px;letter-spacing: -0.2px;}
section.copyright_policy div.tit_area p{flex:1;font-size: 24px;font-weight: 700;line-height: 29px;letter-spacing: -0.2px;color:#5E5E61;}

div.copyright_policy_content{padding-left:273px;margin-top:82px;}
div.copyright_policy_content div.cpc_inner{display:flex;flex-wrap:wrap;}
div.copyright_policy_content div.cp_box{padding: 48px;border-radius: 24px;background:#fff;}
div.copyright_policy_content div.cp_box1{flex:1.63;}
div.copyright_policy_content div.cp_box2{flex:1;margin-left:16px;}
div.copyright_policy_content div.cp_box3{flex:0 0 100%;margin-top:16px;}
div.copyright_policy_content div.cp_box p{font-size:14px;font-weight: 700;line-height: 21px;letter-spacing: -0.4px;color:#313133;}
div.copyright_policy_content div.cp_box1 div.img_box{margin-top:56px;text-align:center;}
div.copyright_policy_content div.cp_box1 div.img_box img{max-width:100%;}
div.copyright_policy_content div.cp_box1 div.img_box img.pc{display:inline-block;}
div.copyright_policy_content div.cp_box1 div.img_box img.m{display:none;}
div.copyright_policy_content div.cp_box3 div.ex_box{margin-top:41px;}
div.ex_box{padding: 8px;border-radius: 8px;background: #E9EEFC;display:flex;align-items:center;}
div.ex_box span{border:1px solid #2658E2;border-radius:6px;padding:0 6px;line-height:26px;color:#2658E2;font-size:16px;}
div.ex_box p{margin-left:16px;font-size:14px;font-weight: 400;line-height: 26px;letter-spacing: -0.4px;color:#173588 !important;}

@media (max-width: 1440px){
	div.copyright_policy_content{padding-left:0;margin-top:60px;}
	div.copyright_policy_content div.cp_box{padding:35px;}
	div.copyright_policy_content div.cp_box1{flex:0.6;}
	div.copyright_policy_content div.cp_box2{flex:0.4;margin-left:16px;}
}

@media (max-width: 1024px){
	section.copyright_policy{padding:40px 0 27px 0;}
	section.copyright_policy div.tit_area{display:block;}
	section.copyright_policy div.tit_area h3{width:100%;font-size:27px;line-height:1.2;}
	section.copyright_policy div.tit_area p{margin:16px 0 0 0;width:100%;font-size:18px;line-height:1.4;}

	div.copyright_policy_content{margin-top:32px;}
	div.copyright_policy_content div.cpc_inner{display:block;}
	div.copyright_policy_content div.cp_box{padding:24px;}
	div.copyright_policy_content div.cp_box1{width:100%;}
	div.copyright_policy_content div.cp_box2{width:100%;margin:10px 0 0 0;padding-bottom:86px;}
	div.copyright_policy_content div.cp_box3{width:100%;}

	div.copyright_policy_content div.cp_box1 p{font-weight:400;}
	div.copyright_policy_content div.cp_box1 div.img_box{margin-top:32px;text-align:center;}
	div.copyright_policy_content div.cp_box3 div.ex_box{margin-top:16px;}
	div.copyright_policy_content div.cp_box p{line-height:1.4;}
	div.copyright_policy_content div.cp_box3 p{font-size:10px;}
	div.ex_box span{line-height:19px;font-size:12px;}
	div.ex_box p{margin-left:8px;}
}

@media (max-width: 768px){
	div.copyright_policy_content div.cp_box1 div.img_box img.pc{display:none;}
	div.copyright_policy_content div.cp_box1 div.img_box img.m{display:inline-block;}
}

div.cpc_bottom{margin-top:64px;}
div.cpc_bottom div.cpc_content ul{display:flex;}
div.cpc_bottom div.cpc_content ul li{margin-left:23px;border-top:1px solid #77777A;padding-top:16px;}
div.cpc_bottom div.cpc_content ul li:first-child{margin-left:0;}
div.cpc_bottom div.cpc_content ul li h5{font-size: 14px;font-weight: 700;line-height: 21px;letter-spacing: -0.4px;margin-bottom:4px;}
div.cpc_bottom div.cpc_content ul li h5:first-child{margin-bottom:32px;}
div.cpc_bottom div.cpc_content ul li p{margin-bottom:32px;font-size: 14px;font-weight: 400;line-height: 21px;letter-spacing: -0.4px;color: #313133;}
div.cpc_bottom div.cpc_content ul li p a{display:inline-block;font-size: 14px;font-weight: 400;line-height: 21px;letter-spacing: -0.4px;color: #313133;}
div.cpc_bottom div.cpc_content p.add_txt{font-size: 14px;font-weight: 400;line-height: 21px;letter-spacing: -0.4px;color: #313133;}

@media (max-width: 1024px){
	div.cpc_bottom div.cpc_content ul{display:block;}
	div.cpc_bottom div.cpc_content ul li{margin:33px 0 0 0;}
	div.cpc_bottom div.cpc_content ul li:first-child{margin-top:0;}
	div.cpc_bottom div.cpc_content p.add_txt{margin-top:77px;}
}
/* 소개 페이지 끝 */

/* 이용가이드 */
div#contents.usage_guide{background:url('/public/images/usage_guide_bg.png')no-repeat right top #fff;padding-bottom:153px;}
div#contents.usage_guide ul.nav{padding-top:16px;}
section.page_tit_area{padding:120px 0 102px 0;}
section.page_tit_area div.center{display:flex;align-items:center;}
section.page_tit_area div.center h1{flex:0 0 411px;font-size: 48px;font-weight: 900;line-height: 58px;padding-right:15px;}
section.page_tit_area div.center p{flex:1;font-size: 24px;font-weight: 700;line-height: 29px;letter-spacing: -0.2px;color:#5E5E61;}

section.page_tit_area div.center_search{display:flex;align-items:center;}
section.page_tit_area div.center_search h1{flex:0 0 411px;font-size: 48px;font-weight: 900;line-height: 58px;padding-right:15px;}
section.page_tit_area div.center_search p{flex:1;font-size: 24px;font-weight: 700;line-height: 29px;letter-spacing: -0.2px;color:#5E5E61;}

section.ug_area{}
section.ug_area div.ug_content{display:flex;}
section.ug_area div.ug_content ul.tab_menu{flex:0 0 256px;}
section.ug_area div.ug_content ul.tab_menu li{margin-top:16px;}
section.ug_area div.ug_content ul.tab_menu li:first-child{margin-top:0;}
section.ug_area div.ug_content ul.tab_menu li a{padding:24px 24px 16px 24px;border-radius:16px;background:#F0F0F5;overflow:hidden;font-size: 18px;font-weight: 700;color:#454547;}
section.ug_area div.ug_content ul.tab_menu li a img{float:right;width:80px;}
section.ug_area div.ug_content ul.tab_menu li a h4{float:left;}

section.ug_area div.ug_content ul.tab_menu li:hover a{background:#BECDF6;}
section.ug_area div.ug_content ul.tab_menu li:focus a{background:#173588;}
section.ug_area div.ug_content ul.tab_menu li:focus a h4{color:#fff !important;}
section.ug_area div.ug_content ul.tab_menu li:active a{background:#173588;}
section.ug_area div.ug_content ul.tab_menu li:active a h4{color:#fff !important;}

section.ug_area div.ug_content ul.tab_menu li.on{}
section.ug_area div.ug_content ul.tab_menu li.on a{background:#2658E2;}
section.ug_area div.ug_content ul.tab_menu li.on a h4{color:#fff;}
section.ug_area div.ug_content div.tab_contents{flex:1;max-width:calc(100% - 411px);max-width:-webmit-calc(100% - 411px);max-width:-moz-calc(100% - 411px);margin-left:155px;background:#E9EEFC;border-radius:16px;padding:80px;}
section.ug_area div.ug_content div.tab_contents div.tab_con{display:none;}
section.ug_area div.ug_content div.tab_contents div.tab_con.on{display:block;}
section.ug_area div.ug_content div.tab_contents div.tab_con div.guide_box{display:flex;margin-top:80px;}
section.ug_area div.ug_content div.tab_contents div.tab_con div.guide_box:first-child{margin-top:0;}
section.ug_area div.ug_content div.tab_contents div.tab_con div.guide_box div.exp_box{flex:0 0 50%;max-width:50%;padding-right:25px;}
section.ug_area div.ug_content div.tab_contents div.tab_con div.guide_box div.exp_box span.icon{}
section.ug_area div.ug_content div.tab_contents div.tab_con div.guide_box div.exp_box h3{margin-top:24px;font-size: 36px;font-weight: 900;line-height: 43px;letter-spacing: -0.2px;}
section.ug_area div.ug_content div.tab_contents div.tab_con div.guide_box div.exp_box p{margin-top:24px;font-size:18px;font-weight: 500;line-height: 28px;letter-spacing: -0.2px;}
section.ug_area div.ug_content div.tab_contents div.tab_con div.guide_box div.exp_img_box{flex:0 0 50%;max-width:50%;}
section.ug_area div.ug_content div.tab_contents div.tab_con div.guide_box div.exp_img_box img{width:100%;border-radius:16px;}
section.ug_area div.ug_content div.tab_contents div.tab_con div.rd_view_item{margin-top:40px;background:#fff;padding:25px;border-radius:25px;}

div.download_area{margin-top:80px;overflow:hidden;padding:1px;}
div.download_area button{height:52px;padding:0 16px;color:#fff;float:right;font-size:18px;font-weight:700;border-radius:8px !important;}
div.download_area button img{margin-top:-2px;}

@media (max-width: 1440px){
	section.page_tit_area div.center h1{flex:0 0 350px;font-size:40px;line-height:1.4;}
	section.page_tit_area div.center p{font-size:22px;line-height:1.4;}

	section.page_tit_area div.center_search h1{flex:0 0 350px;font-size:40px;line-height:1.4;}
	section.page_tit_area div.center_search p{font-size:22px;line-height:1.4;}

	section.ug_area div.ug_content div.tab_contents{margin-left:95px;padding:50px;max-width:calc(100% - 351px);max-width:-webmit-calc(100% - 351px);max-width:-moz-calc(100% - 351px);}

	section.ug_area div.ug_content div.tab_contents div.tab_con div.guide_box div.exp_box h3{margin-top:16px;font-size:30px;line-height:1.4;}
	section.ug_area div.ug_content div.tab_contents div.tab_con div.guide_box div.exp_box p{margin-top:16px;font-size:18px;line-height:1.4;}
}
@media (max-width: 1280px){
	section.page_tit_area div.center h1{flex:0 0 320px;}

	section.page_tit_area div.center_search h1{flex:0 0 320px;}

	section.ug_area div.ug_content div.tab_contents{margin-left:65px;max-width:calc(100% - 2px);max-width:-webmit-calc(100% - 321px);max-width:-moz-calc(100% - 321px);}
	section.ug_area div.ug_content div.tab_contents div.tab_con div.guide_box{display:block;}
	section.ug_area div.ug_content div.tab_contents div.tab_con div.guide_box div.exp_box{max-width:100%;width:100%;}
	section.ug_area div.ug_content div.tab_contents div.tab_con div.guide_box div.exp_img_box{max-width:100%;width:100%;margin-top:32px;}
}
@media (max-width: 1024px){
	div#contents.usage_guide{background:none;padding-bottom:40px;}
	section.page_tit_area{padding:48px 0 29px 0;}
	section.page_tit_area div.center{display:block;}
	section.page_tit_area div.center h1{font-size:32px;}
	section.page_tit_area div.center p{margin-top:16px;font-size:18px;font-weight:500;line-height:1.6;}

	section.page_tit_area div.center_search{display:block;}
	section.page_tit_area div.center_search h1{font-size:32px;}
	section.page_tit_area div.center_search p{margin-top:16px;font-size:18px;font-weight:500;line-height:1.6;}

	section.ug_area div.ug_content{display:block;}
	section.ug_area div.ug_content ul.tab_menu{display:flex;}
	section.ug_area div.ug_content ul.tab_menu li{flex:1;margin:0 0 0 8px;}
	section.ug_area div.ug_content ul.tab_menu li:first-child{margin-left:0;}

	section.ug_area div.ug_content ul.tab_menu li a{padding:16px 16px 20px 20px;}
	section.ug_area div.ug_content ul.tab_menu li a img{float:none;width:48px;}
	section.ug_area div.ug_content ul.tab_menu li a h4{float:none;margin-top:7px;}

	section.ug_area div.ug_content div.tab_contents{margin:8px 0 0 0;padding:24px;}
	section.ug_area div.ug_content div.tab_contents div.tab_con div.guide_box{margin-top:40px;}
	section.ug_area div.ug_content div.tab_contents div.tab_con div.guide_box div.exp_box{padding:0;}
	section.ug_area div.ug_content div.tab_contents div.tab_con div.guide_box div.exp_box h3{margin-top:8px;font-size:24px;}
	section.ug_area div.ug_content div.tab_contents div.tab_con div.guide_box div.exp_box p{margin-top:8px;}
	div.download_area{margin-top:40px;}
	div.download_area button{width:100%;}
}
@media (max-width: 360px){
	section.page_tit_area div.center p{font-size:14px;}

	section.page_tit_area div.center_search p{font-size:14px;}
}
/* 이용가이드 끝 */

/* 유형별 관광콘텐츠 */
div#contents.tourism_contents{background:url('/public/images/tourism_contents_bg.png')no-repeat right top;padding-bottom:83px;}
div#contents.tourism_contents.background_none{background: none;}
div#contents.tourism_contents.background_none_pd_bottom0{background: none; padding-bottom: 0px;}
div#contents.tourism_contents div.tab_contents{padding:64px;}
div.tab_contents div.tab_con h4{line-height:1.4;font-size:20px;color:#313133;font-weight:700;letter-spacing:-0.2px;}
div.tab_contents div.tab_con img.preview{margin-top:40px;max-width:100%;}
@media (max-width: 1024px){
	div#contents.tourism_contents{background:none;}
	div#contents.tourism_contents section.ug_area div.ug_content div.tab_contents{margin-top:0;display:none;}
	div#contents.tourism_contents section.ug_area div.ug_content ul.tab_menu{flex-wrap:wrap;}
	div#contents.tourism_contents section.ug_area div.ug_content ul.tab_menu li{flex:unset;width:calc(33% - 4px);width:-webkit-calc(33% - 4px);width:-moz-calc(33% - 4px);margin:0 0 8px 8px;}
	div#contents.tourism_contents section.ug_area div.ug_content ul.tab_menu li:nth-child(3n+1){margin-left:0;}
}
@media (max-width: 768px){
	div#contents.tourism_contents section.ug_area div.ug_content ul.tab_menu li{flex:unset;width:calc(50% - 4px);width:-webkit-calc(50% - 4px);width:-moz-calc(50% - 4px);}
	div#contents.tourism_contents section.ug_area div.ug_content ul.tab_menu li:nth-child(3n+1){margin-left:8px;}
	div#contents.tourism_contents section.ug_area div.ug_content ul.tab_menu li:nth-child(2n+1){margin-left:0px;}
}

div#contents.tourism_contents div.tab_contents_m.active{display:block;}
div.tab_contents_m{position:fixed;top:0;left:0;background:#E9EEFC;width:100%;height:100%;overflow-y:auto;z-index:99999;display:none;}
div.tab_contents_m div.head{padding:16px;overflow:hidden;background:#fff;}
div.tab_contents_m div.head button.close_bt{background:none;border:0;outline:0;float:right;}
div.tab_contents_m div.con{padding:24px 16px;background:#E9EEFC;}
div.tab_contents_m div.con h4{font-size:16px;font-weight:700;color:#313133;line-height:1.4;}
div.tab_contents_m div.con img.preview{margin-top:32px;width:100%;}
/* 유형별 관광콘텐츠 끝 */

/* 관광콘텐츠 통계 */
div#contents.tourism_statistics{background:url('/public/images/tourism_statistics_bg.png')no-repeat right top #fff;padding-bottom:108px;}
section.statistics_area{}
section.statistics_area ul.nav{padding-top:16px;}
section.statistics_area div.con_box{}
div.con_box{padding-top:120px;}
div.con_box h2.cb_tit{font-size: 30px;font-weight: 900;line-height:1.4;}
div.con_box p{margin-top:16px;font-size:16px;font-weight:500;line-height:1.4;color:#454547;letter-spacing:-0.2px;}

div.statistics_list{margin-top:48px;display:flex;}
div.statistics_list div.statistics_item{flex:1;margin-left:16px;padding:40px;border-radius:24px;background:#E9EEFC;}
div.statistics_list div.statistics_item:first-child{margin-left:0px;}
div.statistics_list div.statistics_item h3{font-size:24px;font-weight:900;line-height:1.4;color:#454547;overflow:hidden;}
div.statistics_list div.statistics_item h3 img{float:right;}
div.statistics_list div.statistics_item p{margin-top:16px;font-size:16px;font-weight:500;line-height:1.4;color:#313133;letter-spacing:-0.2px;}
div.statistics_list div.statistics_item p span{font-size:18px;font-weight:900;padding-right:8px;}
div.statistics_list div.statistics_item.all{background:#2658E2;}
div.statistics_list div.statistics_item.all h3{color:#fff;}
div.statistics_list div.statistics_item.all p{color:#fff;}
div.statistics_list div.statistics_item.all p span{color:#fff;}

div.statistics_graph_area{margin-top:80px;}
div.statistics_graph_area h3.sga_tit{font-size: 32px;font-weight: 800;line-height:1.4;}
div.statistics_graph_area h3.sga_tit div.tooltip{display:inline-block;vertical-align:top;font-size:12px;position:relative;}
div.statistics_graph_area h3.sga_tit div.tooltip ul.dot_list{position:absolute;top:50%;left:115%;min-width:670px;border-radius:4px;padding:12px;z-index:1;background:#fff;
box-shadow: 0px 0px 4px 0px #0000003D;
-webkit-box-shadow: 0px 0px 4px 0px #0000003D;
-moz-box-shadow: 0px 0px 4px 0px #0000003D;
transform:translateY(-50%);
-webkit-transform:translateY(-50%);
-moz-transform:translateY(-50%);
display:none;}
div.statistics_graph_area h3.sga_tit div.tooltip ul.dot_list:before{content:'';display:block;width:6px;height:10px;background:url('/public/images/ar_left.png')no-repeat center;position:absolute;top:50%;margin-top:-5px;left:-6px;}
div.statistics_graph_area h3.sga_tit div.tooltip ul.dot_list li{font-size:14px;font-weight:400;color:#313133;letter-spacing:-0.4px;padding-left:25px;line-height:2;}
div.statistics_graph_area h3.sga_tit div.tooltip ul.dot_list li:before{margin-top:0;top:12px;}

i.icon.tooltip{width:40px;height:40px;display:inline-block;vertical-align:middle;background:url('/public/images/icon_tooltip.png')no-repeat center;background-size:cover;}
div.statistics_graph_area div.statistics_graph{margin-top:40px;width:100%;overflow:auto;position:relative;}
div.statistics_graph_area div.statistics_graph img{width:100%;}


.reciente h2.cb_tit {
	font-size: 30px;font-weight: 900;line-height:1.4;
}
.reciente .rc_tit{
	font-size: 20px;font-weight: 500;line-height:2.4;
}
/* @media (max-width: 1500px){
	div.statistics_list{flex-wrap:wrap;}
	div.statistics_list div.statistics_item{margin-left:14px;padding:35px;}
	div.statistics_list div.statistics_item h3{font-size:23px;}
} */
/* @media (max-width: 1440px){
	div.statistics_list div.statistics_item{width:calc(33.3% - 11px);width:-webkit-calc(33.3% - 11px);width:-moz-calc(33.3% - 11px);flex:unset;margin:0 0 16px 16px;}
	div.statistics_list div.statistics_item:nth-child(3n+1){margin-left:0;}
} */
/* @media (max-width: 1024px){
	div#contents.tourism_statistics{background:#fff;padding-bottom:51px;}
	div.con_box{padding-top:48px;}
	div.con_box h2.cb_tit{font-size:32px;}
	div.con_box p{font-size:18px;}
	div.statistics_list div.statistics_item{width:calc(50% - 4px);flex:unset;margin:0 0 8px 8px;padding:24px;}
	div.statistics_list div.statistics_item:nth-child(3n+1){margin:0 0 8px 8px;}
	div.statistics_list div.statistics_item:nth-child(1){width:100%;margin:0 0 8px 0;}
	div.statistics_list div.statistics_item:nth-child(2n){margin:0 0 8px 0;}
	div.statistics_list div.statistics_item h3{font-size:18px;font-weight:700;}
	div.statistics_list div.statistics_item p{font-size:18px;font-weight:700;}
	div.statistics_list div.statistics_item p span{font-size:20px;font-weight:700;}
	div.statistics_graph_area{margin-top:36px;}
	div.statistics_graph_area h3.sga_tit{font-size:24px;font-weight:900;}
	div.statistics_graph_area div.statistics_graph{margin-top:16px;width:calc( 100vw - 11% );padding-right:16px;}
	div.statistics_graph_area div.statistics_graph img{min-width:1052px;}

	div.statistics_graph_area h3.sga_tit div.tooltip ul.dot_list{top:110%;left:0;width:60vw;min-width:auto;padding:8px;transform:none;}
	div.statistics_graph_area h3.sga_tit div.tooltip ul.dot_list:before{margin-top:-8px;left:20px;top:0;transform: rotate(90deg);-webkit-transform: rotate(90deg);-moz-transform: rotate(90deg);}
	div.statistics_graph_area h3.sga_tit div.tooltip ul.dot_list li{line-height:1.4;font-size:12px;}
	div.statistics_graph_area h3.sga_tit div.tooltip ul.dot_list li:before{top:5px;}
} */
/* @media (max-width: 768px){
	div.statistics_graph_area div.statistics_graph{width:100%;padding-right:0;}
} */
/* @media (max-width: 360px){
	div.statistics_list div.statistics_item{padding:16px;}
	div.statistics_list div.statistics_item h3{font-size:16px;}
	div.statistics_list div.statistics_item h3 img{width:25px;}
	div.statistics_list div.statistics_item p{font-size:16px;margin-top:12px;}
	div.statistics_list div.statistics_item p span{font-size:18px;}
	div.statistics_graph_area h3.sga_tit div.tooltip ul.dot_list:before{left:35vw;}
	div.statistics_graph_area h3.sga_tit div.tooltip ul.dot_list{left:-30vw;width:80vw;}
	div.statistics_graph_area h3.sga_tit div.tooltip ul.dot_list li{padding-left:10px;}
} */
/* 관광콘텐츠 통계 끝 */

/* 최신관광 콘텐츠 */
.masonry_textbox_cover{
	padding: 4px 8px;
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	min-height: 40px;

	background: rgba(0, 0, 0, 0.6);
	color: white;
}
.masonry_textbox{

	background-color: transparent;
	text-align: center;

	display: -webkit-box; /* 필수 */
	-webkit-box-orient: vertical; /* 필수 */
	-webkit-line-clamp: 2; /* 2줄로 제한 */
	overflow: hidden; /* 넘치는 부분 숨김 */
	text-overflow: ellipsis; /* 생략 부호 추가 */
	white-space: normal; /* 줄바꿈 허용 */
}

.reciente_swiper{ border-radius: 30px; padding: 15px; text-align: center; font-weight: 700;cursor: pointer;}
.masonry_box{
	cursor:pointer;
	transition: ease-in-out 0.3s;
	box-shadow :1px 1px 5px 2px #d9d9d9;
}
.masonry_box:hover{

	transform: translate( -3px, -3px);
	box-shadow :1px 2px 7px 5px #d9d9d9;
}
.tourism_bg{background:url('/public/images/tourism_recommend_bg.png')no-repeat right top #fff;}
/* 최신관광 콘텐츠 끝 */

/* 추천관광 콘텐츠 */
div#contents.tourism_recommend{background:url('/public/images/tourism_recommend_bg.png')no-repeat right top #fff;padding-bottom:127px;}

section.tr_list{}
section.tr_list div.recommend_slide{margin-top:48px;}
section.tr_list div.recommend_slide div.swiper-pagination span.swiper-pagination-bullet.swiper-pagination-bullet-active{width:24px;background:#313133;}
section.tr_list div.recommend_slide ul li div.info{background:#2658E2;}
section.tr_list div.recommend_slide ul li div.tag_box{display:flex;align-items:center;justify-content:space-between;}
section.tr_list div.recommend_slide ul li div.tag_box img.logo{max-width:50%;}
section.tr_list div.recommend_slide ul li div.info span{line-height:24px;padding:0 10px;display:inline-block;}
section.tr_list div.recommend_slide ul li div.info h4{color:#fff;}
section.tr_list div.recommend_slide ul li div.info p{color:#BECDF6;}
section.tr_list div.recommend_slide ul li:hover{box-shadow: 0px 4px 16px 0px #0000003D;}


section.tr_list div.recommend_slide div.info{background:#2658E2;}
section.tr_list div.recommend_slide div.tag_box{display:flex;align-items:center;justify-content:space-between;}
section.tr_list div.recommend_slide div.tag_box img.logo{max-width:50%;}
section.tr_list div.recommend_slide div.info span{line-height:24px;padding:0 10px;display:inline-block;}
section.tr_list div.recommend_slide div.info h4{color:#fff;}
section.tr_list div.recommend_slide div.info p{color:#BECDF6;}
section.tr_list div.recommend_slide div.swiper-slide div:hover {box-shadow: 0px 4px 16px 0px #0000003D;}

ul.public_relations_list{margin-top:40px;font-size:0;}
ul.public_relations_list li{display:inline-block;vertical-align:top;width:calc(25% - 12px);width:-webkit-calc(25% - 12px);width:-moz-calc(25% - 12px);margin:0 0 16px 16px;}
ul.public_relations_list li:nth-child(4n+1){margin-left:0;}
ul.public_relations_list li a{border-radius:24px;overflow:hidden;border:1px solid #e6e6eb;}
ul.public_relations_list li div.img_box{}
ul.public_relations_list li div.img_box img{width:100%;}
ul.public_relations_list li div.info_box{padding:16px 24px 24px 24px;}
ul.public_relations_list li div.info_box h4{font-size: 20px;font-weight: 700;line-height:1.3;letter-spacing: -0.2px;text-overflow:ellipsis;overflow:hidden;white-space:nowrap;}
ul.public_relations_list li div.info_box p{margin-top:8px;font-size:16px;font-weight:500;line-height:1.4;letter-spacing: -0.2px;color:#77777A;}
ul.public_relations_list li:hover a{box-shadow: 0px 4px 16px 0px #0000003D;}

@media (max-width: 1440px){
	ul.public_relations_list li{width:calc(33% - 8px);width:-webkit-calc(33% - 8px);width:-moz-calc(33% - 8px);}
	ul.public_relations_list li:nth-child(4n+1){margin:0 0 16px 16px;}
	ul.public_relations_list li:nth-child(3n+1){margin:0 0 16px 0;}
}

@media (max-width: 1024px){
	div#contents.tourism_recommend{background:none;padding-bottom:42px;}
	ul.public_relations_list li{width:calc(50% - 8px);width:-webkit-calc(50% - 8px);width:-moz-calc(50% - 8px);margin:0 0 8px 8px;}
	ul.public_relations_list li:nth-child(4n+1){margin:0 0 8px 8px;}
	ul.public_relations_list li:nth-child(3n+1){margin:0 0 8px 8px;}
	ul.public_relations_list li:nth-child(2n+1){margin:0 0 8px 0;}

	ul.public_relations_list li div.info_box h4{font-size:16px;display: -webkit-box;-webkit-line-clamp:3;-webkit-box-orient: vertical;white-space: normal;}
	ul.public_relations_list li div.info_box p{font-size:14px;}
}
@media (max-width: 360px){
	ul.public_relations_list li div.info_box{padding:10px 14px 14px 14px;}
	ul.public_relations_list li div.info_box h4{font-size:14px;}
	ul.public_relations_list li div.info_box p{font-size:12px;}
}
/* 추천관광 콘텐츠 끝 */

/* 온라인 서비스 */
ul.dot_list{}
ul.dot_list li{position:relative;padding-left:30px;line-height:1.4;}
ul.dot_list li:before{content:'';display:block;width:4px;height:4px;border-radius:50%;background:#454547;position:absolute;top:50%;left:11px;margin-top:-2px;}

div#contents.online_service{background:url('/public/images/online_service_bg.png')no-repeat right top #fff;padding-bottom:111px; background-position: top; background-size: 100%;}

section.os_list{}
div.right_side_content{padding-left:428px;}
ul.service_link_list{}
ul.service_link_list > li{margin-top:20px;padding:40px;background: #E9EEFC;border-radius:16px;overflow:hidden;}
ul.service_link_list > li:first-child{margin-top:0;}
ul.service_link_list > li div.info_box{}
ul.service_link_list > li div.info_box h3{font-size:36px;font-weight:900;letter-spacing:-0.2px;color:#313133;line-height:1.3;}
ul.service_link_list > li div.info_box ul.dot_list{margin-top:16px;}
ul.service_link_list > li div.info_box ul.dot_list li{margin-top:6px;font-size:18px;font-weight:500;letter-spacing:-0.2px;color:#454547;line-height:1.3;}
ul.service_link_list > li div.info_box ul.dot_list li:first-child{margin-top:0;}
ul.service_link_list > li div.info_box div.bts{margin-top:80px;font-size:0;}
ul.service_link_list > li div.info_box div.bts button{padding:0 16px;height:40px;margin-left:16px;border-radius:6px !important;}
ul.service_link_list > li div.info_box div.bts button:first-child{margin-left:0;}
ul.service_link_list > li div.img_box{display:none;text-align:center;margin-top:40px;}
ul.service_link_list > li div.img_box img{max-width:100%;}
ul.service_link_list > li.online_service01{background:url('/public/images/online_service01_bg.png')no-repeat right bottom #e9eefc;}
ul.service_link_list > li.online_service02{background:url('/public/images/online_service02_bg.png')no-repeat right bottom #e9eefc;}
ul.service_link_list > li.online_service03{background:url('/public/images/online_service03_bg.png')no-repeat right bottom #e9eefc;background-size: 500px 320px;}
/* ul.service_link_list > li.online_service03{background:url('/public/images/online_service03_bg.png')no-repeat right bottom #e9eefc;background-size: 500px 320px;} */
ul.service_link_list > li.online_service04{background:url('/public/images/online_service04_bg.png')no-repeat right bottom #e9eefc;background-size: 500px 320px;}

@media (max-width: 1440px){
	div.right_side_content{padding-left:16px;}
}

@media (max-width: 1024px){
	div#contents.online_service{background:none;}
	ul.service_link_list > li{background:#e9eefc !important;padding:24px 20px;}
	ul.service_link_list > li div.img_box{display:block;}

	ul.service_link_list > li div.info_box h3{font-size:24px;}
	ul.service_link_list > li div.info_box ul.dot_list{margin-top:16px;}
	ul.service_link_list > li div.info_box ul.dot_list li{margin-top:4px;font-size:14px;}
	ul.service_link_list > li div.info_box div.bts{margin-top:24px;}
	ul.service_link_list > li div.info_box div.bts button{padding:0 16px;height:40px;margin-left:8px;}
}
@media (max-width: 360px){
	ul.service_link_list > li div.info_box div.bts button{padding:0 8px;height:30px;margin-left:4px;font-size:14px;}
	ul.service_link_list > li div.info_box div.bts button img{width:14px;}
	ul.dot_list li{padding-left:10px;}
	ul.dot_list li:before{left:0;}
	ul.service_link_list > li div.info_box ul.dot_list li:before{top:7px;margin-top:0;}
}
/* 온라인 서비스 끝 */
/* 서비스 상세 팝업 */
div.service_detail_popup{position:fixed;top:0;left:0;width:100%;height:100%;z-index:99999;padding:37px 37px 52px 37px;display:none;}
div.service_detail_popup div.bg{position:fixed;top:0;left:0;width:100%;height:100%;overflow:hidden;z-index:-1;background:rgba(0,0,0,0.2);}
div.service_detail_popup div.center{max-width:1240px;background:#fff;border-radius:32px;overflow:hidden;height:100%;padding:0;box-shadow: 0px 6px 8px 0px #0000003D;}
div.service_detail_popup div.center button.btn_close{position:absolute;top:0;right:0;background:none;border:0;outline:0;padding:16px 19px 0 0;z-index:9;}
div.service_detail_popup div.center div.scroll_area{width:100%;height:100%;overflow:auto;position:relative;justify-content: stretch;align-items: flex-start;background-color:#fff;padding:32px;}
div.service_detail_popup div.center div.scroll_area h2{font-size: 36px;font-weight: 900;line-height:1.3;letter-spacing: -0.2px;padding-right:40px;}
div.service_detail_popup div.center div.scroll_area div.sdp_con{margin-top:24px;display:flex;}
div.service_detail_popup div.center div.scroll_area div.sdp_con div.img_box{flex:1;min-height:100%;padding:0 24px 0 0;}
div.service_detail_popup div.center div.scroll_area div.sdp_con div.img_box img{width:100%;}
div.service_detail_popup div.center div.scroll_area div.sdp_con div.visit_info_wrap{flex:0 0 520px;max-width:520px;min-height:100%;}
div.service_detail_popup div.center div.scroll_area div.sdp_con div.visit_info_wrap div.service_intro{margin-top:32px;}
div.service_detail_popup div.center div.scroll_area div.sdp_con div.visit_info_wrap div.service_intro img.copyright_img{margin-top:16px;max-width:100%;}
div.service_detail_popup div.center div.scroll_area div.sdp_con div.visit_info_wrap div.bts{margin-top:110px;}
div.service_detail_popup div.center div.scroll_area div.sdp_con div.visit_info_wrap div.bts button{height:52px;padding:0 16px;border-radius:8px !important;}

div.service_detail_popup div.center_search{max-width:1240px;background:#fff;border-radius:32px;overflow:hidden;height:100%;padding:0;box-shadow: 0px 6px 8px 0px #0000003D;}
div.service_detail_popup div.center_search button.btn_close{position:absolute;top:0;right:0;background:none;border:0;outline:0;padding:16px 19px 0 0;z-index:9;}
div.service_detail_popup div.center_search div.scroll_area{width:100%;height:100%;overflow:auto;position:relative;justify-content: stretch;align-items: flex-start;background-color:#fff;padding:32px;}
div.service_detail_popup div.center_search div.scroll_area h2{font-size: 36px;font-weight: 900;line-height:1.3;letter-spacing: -0.2px;padding-right:40px;}
div.service_detail_popup div.center_search div.scroll_area div.sdp_con{margin-top:24px;display:flex;}
div.service_detail_popup div.center_search div.scroll_area div.sdp_con div.img_box{flex:1;min-height:100%;padding:0 24px 0 0;}
div.service_detail_popup div.center_search div.scroll_area div.sdp_con div.img_box img{width:100%;}
div.service_detail_popup div.center_search div.scroll_area div.sdp_con div.visit_info_wrap{flex:0 0 520px;max-width:520px;min-height:100%;}
div.service_detail_popup div.center_search div.scroll_area div.sdp_con div.visit_info_wrap div.service_intro{margin-top:32px;}
div.service_detail_popup div.center_search div.scroll_area div.sdp_con div.visit_info_wrap div.service_intro img.copyright_img{margin-top:16px;max-width:100%;}
div.service_detail_popup div.center_search div.scroll_area div.sdp_con div.visit_info_wrap div.bts{margin-top:110px;}
div.service_detail_popup div.center_search div.scroll_area div.sdp_con div.visit_info_wrap div.bts button{height:52px;padding:0 16px;border-radius:8px !important;}

@media (max-width: 1280px){
	div.service_detail_popup div.center div.scroll_area div.sdp_con div.visit_info_wrap{flex:0 0 40%;max-width:40%;}

	div.service_detail_popup div.center_search div.scroll_area div.sdp_con div.visit_info_wrap{flex:0 0 40%;max-width:40%;}
}
@media (max-width: 1024px){
	div.service_detail_popup div.center div.scroll_area h2{font-size:22px;}
	div.service_detail_popup div.center div.scroll_area div.sdp_con div.visit_info_wrap div.bts{margin-top:10%;}
	div.service_detail_popup div.center div.scroll_area div.sdp_con div.visit_info_wrap div.bts button{height:32px;padding:0 12px;font-size:14px;}
	div.service_detail_popup div.center div.scroll_area div.sdp_con div.visit_info_wrap div.bts button img{width:20px;}

	div.service_detail_popup div.center_search div.scroll_area h2{font-size:22px;}
	div.service_detail_popup div.center_search div.scroll_area div.sdp_con div.visit_info_wrap div.bts{margin-top:10%;}
	div.service_detail_popup div.center_search div.scroll_area div.sdp_con div.visit_info_wrap div.bts button{height:32px;padding:0 12px;font-size:14px;}
	div.service_detail_popup div.center_search div.scroll_area div.sdp_con div.visit_info_wrap div.bts button img{width:20px;}
}
@media (max-width: 768px){
	div.service_detail_popup div.center div.scroll_area div.sdp_con{display:block;}
	div.service_detail_popup div.center div.scroll_area div.sdp_con div.img_box{padding:0;}
	div.service_detail_popup div.center div.scroll_area div.sdp_con div.visit_info_wrap{margin-top:32px;max-width:100%;width:100%;}

	div.service_detail_popup div.center_search div.scroll_area div.sdp_con{display:block;}
	div.service_detail_popup div.center_search div.scroll_area div.sdp_con div.img_box{padding:0;}
	div.service_detail_popup div.center_search div.scroll_area div.sdp_con div.visit_info_wrap{margin-top:32px;max-width:100%;width:100%;}
}
@media (max-width: 360px){
	div.service_detail_popup{padding:16px 10px;}
	div.service_detail_popup div.center div.scroll_area h2{font-size:18px;}
	div.service_detail_popup div.center div.scroll_area{padding:16px;}

	div.service_detail_popup div.center_search div.scroll_area h2{font-size:18px;}
	div.service_detail_popup div.center_search div.scroll_area{padding:16px;}
}
/* 서비스 상세 팝업 끝 */

/* 오프라인 서비스 */
ul.service_link_list > li.offline_service01{background:url('/public/images/offline_service01_bg.png')no-repeat right bottom #e9eefc;}
ul.service_link_list > li.offline_service02{background:url('/public/images/offline_service02_bg.png')no-repeat right bottom #e9eefc;}
/* 오프라인 서비스 끝 */

/* 통합검색 파일 리스트 */
div.board_wrap_file{max-width:1208px;width:1208px;}
/* div.board_wrap_file ul.board_write_list{margin-top:47px;} */
div.board_wrap_file ul.board_write_list li.img_add_area{width: 100%;}
div.board_wrap_file ul.board_write_list li.img_add_area table.file_list tr th{background:#F0F0F5;font-size:16px;color:#313133;font-weight:700;padding:18px 24px;border-bottom:0;}
div.board_wrap_file ul.board_write_list li.img_add_area table.file_list tr td{border-bottom:0;}

div.board_wrap_file ul.board_write_list li.img_add_area table.file_list tr td{border-bottom:1px solid #F0F0F5;padding:18px 24px;}

/* 공지사항 */
div#contents.notice{background:url('/public/images/notice_bg.png')no-repeat top left #fff;}
div#contents.notice ul.nav{padding-top:16px;}
section.board_area{padding:120px 0 110px 0;overflow:hidden;}
section.board_area div.center{display:flex;justify-content:space-between;align-items:flex-start;}

section.board_area div.center_search{display:flex;justify-content:space-between;align-items:flex-start;}

section.board_area h1.board_tit{font-size: 48px;font-weight: 900;line-height:1.2;width:408px;padding-right:8px;}
div.board_wrap{max-width:1208px;width:1208px;}
div.b_search_box_wrap{display:flex;}
div.b_search_box_wrap select{min-width:120px; text-align: center;}
div.b_search_box_wrap div.b_search_box{display:flex;margin-left:16px;}
div.b_search_box_wrap div.b_search_box input{flex:1;min-width:393px;height:40px;padding:0 16px;border-radius: 8px;border: 1px solid #E6E6EB;}
div.b_search_box_wrap div.b_search_box button{flex:0 0 120px;border-radius:6px !important;margin-left:16px;}

table{table-layout:fixed;border-collapse:collapse;border-spacing:0;width:100%;word-break:break-all;word-wrap:break-word;}
table tr th{padding:12px 5px;border-bottom:1px solid #77777A;text-align:center;font-size:16px;color:#313133;font-weight:700;}
table tr td{padding:21px 5px;text-align:center;border-bottom:1px solid #E6E6EB;font-size:16px;color:#313133;font-weight:500;}
table tr td a{display:inline-block;}
table.board{margin-top:40px;}
table.board tr td a{white-space:nowrap;text-overflow:ellipsis;overflow:hidden;max-width:100%;cursor: pointer;}
table.board tr td a span.icon{}
table.board tr td a span.icon img{margin-top:-2px;}
.col80{width:80px;}
.col120{width:120px;}

.t_left{text-align:left !important;}
.t_left_annoncement{
	text-align: center;
}

.pc_board{display:block;}
.m_board{display:none;margin-top:24px;}
div.m_board ul{}
/* div.m_board ul li{padding:21px 0px;border-bottom:1px solid #E6E6EB;} */
div.m_board ul li{padding:21px 0px;}
div.m_board ul li a{display:block;font-size:16px;color:#313133;font-weight:500;cursor: pointer;}
div.m_board ul li a p.date{margin-top:10px;font-size:14px;color:#77777A;font-weight:500;}
div.m_board button.more_board_bt{margin-top:24px;width:100%;border-radius:8px;height:52px;font-size:18px;color:#313133;font-weight:500;}
div.m_board div.more_board_bt{margin-top:24px;width:100%;border-radius:8px;height:52px;font-size:18px;color:#313133;font-weight:500;}
/*페이징*/
div.paging{margin-top:80px;font-size:0;text-align:left;padding:1px 0;}
div.paging button{width:24px;height:24px;display:inline-block;vertical-align:middle;margin:0 4px;border:0;outline:0;background:none;border-radius:50%;}
div.paging button:first-child{margin-left:0; height: 30px;}
div.paging button:hover{background:#F0F0F5;}
div.paging button:focus{outline:1px solid #000;}
div.paging button:active{background:#D7D7DB;}
div.paging button:disabled{opacity:0.4;}

div.paging{margin-top:80px;font-size:0;text-align:left;padding:1px 0;}
div.paging div{width:24px;height:24px;display:inline-block;vertical-align:middle;margin:0 4px;border:0;outline:0;background:none;border-radius:50%;}
div.paging div:first-child{margin-left:0; height: 30px;}
div.paging div:hover{background:#F0F0F5;}
div.paging div:focus{outline:1px solid #000;}
div.paging div:active{background:#D7D7DB;}
div.paging div:disabled{opacity:0.4;}


div.paging ul{font-size:0;display:inline-block;vertical-align:middle;}
div.paging ul li{display:inline-block;vertical-align:middle;margin:0 4px;}
div.paging ul li a{width:32px;height:32px;line-height:32px;text-align:center;font-size:16px;color:#313133;font-weight:500;border-radius:50%;}
div.paging ul li:hover a{background:#F0F0F5;}
div.paging ul li:focus a{outline:1px solid #000;}
div.paging ul li:active a{background:#D7D7DB;}
div.paging ul li.on a{color:#fff;background:#2658E2;}
/*페이징 끝*/

@media (max-width: 1440px){
	section.board_area div.center{display:block;}
	section.board_area div.center_search{display:block;}
	section.board_area h1.board_tit{width:100%;padding-right:0;}
	div.board_wrap{max-width:100%;width:100%;margin-top:32px;}
}

@media (max-width: 1440px){
	div.board_wrap_file{max-width:100%;width:100%;}
}

@media (max-width: 1024px){
	div#contents.notice{background:none;}
	section.board_area{padding:48px 0 28px 0;}
	section.board_area h1.board_tit{font-size:32px;}
	div.b_search_box_wrap{display:block;width:100%;}
	div.b_search_box_wrap select{min-width:50%;width:50%;}
	div.b_search_box_wrap div.b_search_box{display:flex;margin:8px 0 0 0;width:100%;max-width:100%;}
	div.b_search_box_wrap div.b_search_box input{min-width:50%;}
	div.b_search_box_wrap div.b_search_box button{flex:0 0 60px;margin-left:8px;}

	.pc_board{display:none;}
	.m_board{display:block;}
}
/* 공지사항 끝 */
/* 공지사항 글보기 */
button.st_clear.prev{height:40px;border-radius:6px !important;padding:0 16px;}
button.st_clear.prev img{margin-top:-2px;}

#the-gage-container{ width: 100% !important;
    margin: 0 auto!important;
    padding: 0px 0px 27px 0px;
    max-width: 750px!important;}

div.board_view_top{}
div.board_view_top h2{margin-top:24px;font-size: 36px;font-weight: 900;line-height:1.3;letter-spacing: -0.2px;color:#313133;}
div.board_view_top ul.board_info{margin-top:16px;font-size:0;}
div.board_view_top ul.board_info li{display:inline-block;vertical-align:top;padding:0 21px;border-left:2px solid #C3C3C7;line-height:1;font-size:16px;font-weight:500;color:#77777A;}
div.board_view_top ul.board_info li:first-child{padding-left:0;border-left:0;}
div.board_contents{padding:64px 0;}
div.board_contents img{max-width: 1500px; width: 100%;}
div.board_contents p{margin:0;line-height:1.6;width:100%;white-space: pre-line;font-size:18px;color:#313133;letter-spacing:-0.2px;font-weight:500;}
div.board_wrap div.files{margin: 32px 0;}
div.board_wrap div.files button{padding:0 16px;height:36px;border-radius:8px !important;}
div.board_wrap div.files button img{margin:0 0 0 4px;}

div.board_view_bottom{padding-top:28px;border-top:1px solid #D7D7DB;}
div.board_move{margin-top:24px;background:#F7F7FC;padding:8px 0;overflow:hidden;border-radius:8px;}
div.board_move a{overflow:hidden;}
div.board_move a span{float:left;padding:0 16px;font-size:14px;font-weight:700;color:#5E5E61;line-height:1.3;}
div.board_move a img{margin-top:-2px;}
div.board_move a span{display:inline-block;padding:0 16px;font-size:16px;font-weight:500;color:#5E5E61;line-height:1.3;}
div.board_move a.prev{float:left;}
div.board_move a.prev h5{float:left;}
div.board_move a.prev span{float:left;}
div.board_move a.prev img{margin-right:8px;}
div.board_move a.next{float:right;}
div.board_move a.next h5{float:right;}
div.board_move a.next img{margin-left:8px;}
div.board_move a.next span{float:left;}

@media (max-width: 1024px){
	button.st_clear.prev{height:24px;width:24px;border-radius:0 !important;padding:0;background:url('/public/images/icon_rd_view_prev.png')no-repeat center !important;font-size:0;border:0 !important;}
	button.st_clear.prev img{display:none;}
	div.board_view_top h2{font-size:24px;}
	div.board_view_top{padding-bottom:24px;border-bottom:1px solid #D7D7DB;}
	div.board_contents{padding-top:24px;}
	div.board_contents p{font-size:14px;font-weight:400;}
	div.board_contents div.files{margin-top:16px;}
	div.board_view_bottom{border-top:0;position:relative;padding-top:12px;}
	div.board_view_bottom:before{content:'';display:block;position:absolute;top:0;left:-15%;width:calc(100% + 30% );width:-webkit-calc(100% + 30% );width:-moz-calc(100% + 30% );height:12px;background:#F0F0F5;}
	div.board_view_bottom button.prev.st_clear{display:none;}
	div.board_move{background:#fff;margin-top:0;padding:0;}
	div.board_move a{display:block;width:100%;float:none !important;padding:16px 0;}
	div.board_move a.next{border-top:1px solid #F0F0F5;}
	div.board_move a.next img{float:left;margin:1px 8px 0 0;}
	div.board_move a.next h5{float:left;}
	div.board_move a h5{padding:0;}
	div.board_move a span{font-size:14px;padding:0 8px;}
	div#contents.notice.notice_view section.board_area{padding-bottom:0;}
}
/* 공지사항 글보기 끝 */

/* 자주묻는 질문 */
div#contents.faq{background:url('/public/images/faq_bg.png')no-repeat left top #fff;padding-bottom:28px;}
div.key_wrod_box{margin-top:56px;font-size:0;}
div.key_wrod_box button{display:inline-block;padding:0 16px;margin-left:8px;height:36px;border-radius:24px;font-size:16px;font-weight:500;}
div.key_wrod_box button:first-child{margin-left:0;}
ul.faq_list{margin-top:40px;}
ul.faq_list li{border-bottom:1px solid #c3c3c7;}
ul.faq_list li div.q{display:flex;width:100%;max-width:100%;align-items: center;cursor:pointer;}
ul.faq_list li div.q span.icon{flex:0 0 62px;text-align:center;font-size:32px;font-weight:800;}
ul.faq_list li div.q p.type{flex:0 0 160px;text-align:center;font-size: 16px;font-weight: 700;line-height:1.4;color:#313133;}
ul.faq_list li div.q h4.tit{flex:1;font-size: 16px;font-weight: 500;line-height:1.4;color:#313133;display:flex;align-items: center;padding:16px 0;}
ul.faq_list li div.q h4.tit span.type{flex:0 0 160px;vertical-align:middle;text-align:center;font-size: 16px;font-weight: 700;line-height:1.4;color:#313133;}
ul.faq_list li div.q h4.tit span.txt{flex:1;}

ul.faq_list li div.q span.arrow{flex:0 0 62px;}
ul.faq_list li:hover div.q{background:#E9EEFC;}
ul.faq_list li div.a{display:none;background:#f7f7fc;border-top:1px solid #e6e6eb;width:100%;max-width:100%;align-items: center;padding:16px 16px 16px 0;}
ul.faq_list li div.a span.icon{flex:0 0 62px;text-align:center;font-size:32px;font-weight:800;color:#2658E2;}
ul.faq_list li div.a p{flex:1;font-size: 16px;font-weight: 700;line-height:1.4;color:#313133;padding:19px 24px;}
ul.faq_list li.on div.a{display:flex;}
ul.faq_list li.on div.q span.arrow{transform:rotate(180deg);-webkit-transform:rotate(180deg);-moz-transform:rotate(180deg);}
@media screen and (max-width: 1024px){
	div#contents.faq{background:none;padding-bottom:0px;}
	div.key_wrod_box{display:none;}
	ul.faq_list{margin-top:24px;}
	ul.faq_list li{padding:0 !important;}
	ul.faq_list li div.q span.icon{flex:0 0 51px;font-size:24px;}
	ul.faq_list li div.q h4.tit{display:block;}
	ul.faq_list li div.q h4.tit span.type{display:block;font-size: 14px;text-align:left;}
	ul.faq_list li div.q h4.tit span.txt{margin-top:5px;display:block;font-size: 14px;text-align:left;}

	ul.faq_list li div.a span.icon{flex:0 0 51px;font-size:24px;}
	ul.faq_list li div.a p{font-size: 14px;font-weight:400;padding:0;}
}
/* 자주묻는 질문 끝 */

/* 1:1 문의 */
div#contents.inquiry{background:url('/public/images/inquiry_bg.png')no-repeat left top #fff;}
div#contents.inquiry div.board_bts{overflow:hidden;margin-top:24px;padding:1px;}
div#contents.inquiry div.board_bts button{width:120px;height:40px;border-radius:6px !important;float:right;}
div#contents.inquiry div.paging{margin-top:16px;}
@media (max-width: 1024px){
	div#contents.inquiry{background:none;}
	div#contents.inquiry div.key_wrod_box{display:block;margin-top:24px;}
}

ul.m_inquiry_list{}	
ul.m_inquiry_list li{padding:0 !important;}
ul.m_inquiry_list li a{padding:16px;}
ul.m_inquiry_list li div.head{font-size:0;}
ul.m_inquiry_list li div.head span.num{display:inline-block;vertical-align:middle;font-size: 14px;font-weight: 500;line-height:1.3;color:#77777A;margin-right:8px;}
ul.m_inquiry_list li div.head span.type{display:inline-block;vertical-align:middle;font-size: 14px;font-weight: 700;line-height:1.3;color:#313133;}
ul.m_inquiry_list li h4{width:100%;margin-top:5px;font-size:16px;font-weight:500;line-height:1.4;color:#313133;}
ul.m_inquiry_list li div.bottom{margin-top:5px;font-size:0;}
ul.m_inquiry_list li div.bottom span{display:inline-block;vertical-align:middle;}
ul.m_inquiry_list li div.bottom span.tag{margin-right:8px;}
ul.m_inquiry_list li div.bottom span.date{font-size: 14px;font-weight:500;line-height:1.3;color:#77777A;}
div#contents.inquiry button.board_write_bt{width:100%;height:52px;margin-top:8px;border-radius:8px !important;}
div#contents.inquiry button.board_write_bt img{margin:-2px 0 0 4px;}
/* 1:1 문의 끝 */

/* 1:1문의 글보기 */
div#contents.inquiry div.board_contents{padding-bottom:0;}
div.board_view_top h2 span.icon{margin:-12px 0 0 8px;}
div#contents.inquiry div.board_view_top ul.board_info li span.tag{line-height:1;font-size:16px;color:#77777A;border:0;padding:0;}

div.answer_box{margin-top:76px;background:#F0F0F5;padding:32px 24px;}
div.answer_box span.answer_icon{padding:0 16px;border-radius:24px;line-height:36px;background:#222224;color:#fff;font-size:16px;font-weight:500;}
div.answer_box p{margin-top:24px;}
div.answer_box p.date{color:#77777A;font-size:16px;}

@media (max-width: 1024px){
	div#contents.inquiry div.board_view_top ul.board_info{overflow:hidden;display:inline-block;}
	div#contents.inquiry div.board_view_top ul.board_info li{border-left:0;line-height:22px;}
	div#contents.inquiry div.board_view_top ul.board_info li:nth-child(1){float:right;padding-left:8px;}
	div#contents.inquiry div.board_view_top ul.board_info li:nth-child(2){float:left;padding:0;}
	div#contents.inquiry div.board_view_top ul.board_info li:nth-child(2) span.tag{padding:0 6px;line-height:22px;font-size:14px;}
	div#contents.inquiry div.board_view_top ul.board_info li span.tag.tag_info{border:1px solid #2658E2;color:#2658E2;}

	div.answer_box{margin-top:38px;background:#F0F0F5;padding:24px 16px;}
	div.answer_box p{margin-top:16px;}
	div.answer_box p.date{font-size:14px;}
	div#contents.inquiry div.board_view_bottom{display:none;}
}
/* 1:1문의 글보기 끝 */

/* 1:1문의 글쓰기 */
p.add_txt{font-size: 12px;font-weight: 500;line-height:1.4;letter-spacing: -0.4px;color:#5E5E61;}
div.board_write_area{}	
div.board_write_area div.board_view_top{}
div.board_write_area div.board_view_top button.st_clear.prev{display:none;}
div.board_write_area div.board_view_top h3{font-size: 24px;font-weight: 700;line-height:1.3;letter-spacing: -0.2px;color:#5E5E61;}
div.board_write_area div.board_view_top p.add_txt{margin-top:16px;}
div.board_write_area div.board_view_top p.add_txt span.dot{display:inline-block;width:6px;height:6px;border-radius:50%;background:#FB620B;margin-right:8px;}
div.board_write_area ul.board_write_list{margin-top:47px;}
div.board_write_area ul.board_write_list li{margin-top:24px;display:flex;max-width:100%;}
div.board_write_area ul.board_write_list li:first-child{margin-top:0;}
div.board_write_area ul.board_write_list li h3{flex:0 0 136px;}
div.board_write_area ul.board_write_list li h3 span.dot{display:inline-block;width:6px;height:6px;border-radius:50%;background:#FB620B;margin:-20px 0 0 0;}
div.board_write_area ul.board_write_list li div.con{flex:1;}
div.board_write_area ul.board_write_list li div.con select{min-width:256px;height:48px;}
div.board_write_area ul.board_write_list li div.con input{border-radius:8px;width:100%;height:48px;background:#F7F7FC;}
div.board_write_area ul.board_write_list li div.con textarea{border-radius:8px;height:280px;min-height:280px;padding:17px 16px;background:#F7F7FC;}
div.board_write_area ul.board_write_list li.img_add_area{margin-top:68px; width: 100%;}
div.board_write_area ul.board_write_list li.img_add_area button.add_file_bt{padding:0 16px;height:31px;border-radius:8px;}
div.board_write_area ul.board_write_list li.img_add_area button.add_file_bt img{margin-left:4px;}
div.board_write_area ul.board_write_list li.img_add_area table.file_list{margin-top:29px;border-radius:8px;overflow:hidden;border:1px solid #F0F0F5;border-collapse: unset;}
div.board_write_area ul.board_write_list li.img_add_area table.file_list tr th{background:#F0F0F5;font-size:16px;color:#313133;font-weight:700;padding:18px 24px;border-bottom:0;}
div.board_write_area ul.board_write_list li.img_add_area table.file_list tr td{border-bottom:1px solid #F0F0F5;padding:18px 24px;}
div.board_write_area ul.board_write_list li.img_add_area table.file_list tr:last-child td{border-bottom:0;}
div.board_write_area ul.board_write_list li.img_add_area table.file_list button.del{border:0;outline:0;background:none;padding:0 8px;}
div.board_write_area ul.board_write_list li.img_add_area p.add_txt{margin-top:16px;}

div.board_wrap_file ul.board_write_list li{margin-top:24px;display:flex;max-width:100%;}
div.board_wrap_file ul.board_write_list li:first-child{margin-top:0;}
div.board_wrap_file ul.board_write_list li h3{flex:0 0 136px;}
div.board_wrap_file ul.board_write_list li h3 span.dot{display:inline-block;width:6px;height:6px;border-radius:50%;background:#FB620B;margin:-20px 0 0 0;}
div.board_wrap_file ul.board_write_list li div.con{flex:1;}
div.board_wrap_file ul.board_write_list li div.con select{min-width:256px;height:48px;}
div.board_wrap_file ul.board_write_list li div.con input{border-radius:8px;width:100%;height:48px;background:#F7F7FC;}
div.board_wrap_file ul.board_write_list li div.con textarea{border-radius:8px;height:280px;min-height:280px;padding:17px 16px;background:#F7F7FC;}
div.board_wrap_file ul.board_write_list li.img_add_area{width: 100%;}
div.board_wrap_file ul.board_write_list li.img_add_area button.add_file_bt{padding:0 16px;height:31px;border-radius:8px;}
div.board_wrap_file ul.board_write_list li.img_add_area button.add_file_bt img{margin-left:4px;}
div.board_wrap_file ul.board_write_list li.img_add_area table.file_list{margin-top:29px;border-radius:8px;overflow:hidden;border:1px solid #F0F0F5;border-collapse: unset;}
div.board_wrap_file ul.board_write_list li.img_add_area table.file_list tr th{background:#F0F0F5;font-size:16px;color:#313133;font-weight:700;padding:18px 24px;border-bottom:0;}
div.board_wrap_file ul.board_write_list li.img_add_area table.file_list tr td{border-bottom:1px solid #F0F0F5;padding:18px 24px;}
div.board_wrap_file ul.board_write_list li.img_add_area table.file_list tr:last-child td{border-bottom:0;}
div.board_wrap_file ul.board_write_list li.img_add_area table.file_list button.del{border:0;outline:0;background:none;padding:0 8px;}
div.board_wrap_file ul.board_write_list li.img_add_area p.add_txt{margin-top:16px;}

div.board_write_area ul.board_write_list li div.agree_box{margin-top:66px;}
div.board_write_area ul.board_write_list li div.agree_box input{}
div.board_write_area ul.board_write_list li div.agree_box label{font-size:18px;font-weight:700;line-height:1.3;}
div.board_write_area ul.board_write_list li div.agree_box input[type='checkbox'] + label::before{width:24px;height:24px;background:url('/public/images/check_circle_off.png')no-repeat center;}
div.board_write_area ul.board_write_list li div.agree_box input[type='checkbox']:checked + label::before{background:url('/public/images/check_circle_on.png')no-repeat center;}
div.board_write_area ul.board_write_list li div.agree_box label span{color:#FB620B;vertical-align:top;}
div.board_write_area ul.board_write_list li div.agree_box p.agree_txt{margin-top:22px;white-space: pre-line;font-size:14px;font-weight:400;line-height:1.4;letter-spacing: -0.4px;color:#454547;padding-left:33px;}

div.board_write_bottom{margin-top:30px;border-top:1px solid #77777A;padding:28px 0;display:flex;align-items:center;justify-content:space-between;}
div.board_write_bottom button.write_bt{width:120px;height:52px;border-radius:8px !important;font-size:18px;color:#fff;font-weight:700;}
div.board_write_bottom_m{display:none;margin-top:35px;padding-top:25px;border-top:1px solid #77777A;}


@media (max-width: 1280px){
	div.board_wrap_file div.board_view_top{border-bottom:0;padding:0;}
	div.board_wrap_file div.board_view_top button.st_clear.prev{display:block;}
	div.board_wrap_file div.board_view_top h3{margin-top:40px;font-size:18px;}
	div.board_wrap_file div.board_view_top p.add_txt{margin-top:12px;}
	div.board_wrap_file ul.board_write_list{margin-top:30px;}
	div.board_wrap_file ul.board_write_list li{display:block;}
	div.board_wrap_file ul.board_write_list li h3{padding-bottom:16px;}
	div.board_wrap_file ul.board_write_list li div.con select{min-width:50%;max-width:100%;}
	div.board_wrap_file ul.board_write_list li.img_add_area{margin-top:40px;}
	div.board_wrap_file ul.board_write_list li.img_add_area button.add_file_bt{float:right;margin-top: -43px;}
	div.board_wrap_file ul.board_write_list li.img_add_area table.file_list{clear:both;}
	div.board_wrap_file ul.board_write_list li.img_add_area table.file_list tr th{font-size:14px;padding:8px 8px;}
	div.board_wrap_file ul.board_write_list li.img_add_area table.file_list tr td{font-size:14px;padding:8px 8px;}
	div.board_wrap_file ul.board_write_list li.img_add_area table.file_list col.col120{width:100px;}
	div.board_wrap_file ul.board_write_list li.img_add_area table.file_list col.col80{width:50px;}
	div.board_wrap_file ul.board_write_list li.img_add_area table.file_list tr td.t_left{text-overflow: ellipsis;white-space: nowrap;overflow: hidden;}
	div.board_write_bottom{display:none;}
	div.board_write_bottom_m{display:block;}
	div.board_write_bottom_m button.board_write_bt{font-size:18px;}
}

@media (max-width: 1024px){
	div.board_write_area div.board_view_top{border-bottom:0;padding:0;}
	div.board_write_area div.board_view_top button.st_clear.prev{display:block;}
	div.board_write_area div.board_view_top h3{margin-top:40px;font-size:18px;}
	div.board_write_area div.board_view_top p.add_txt{margin-top:12px;}
	div.board_write_area ul.board_write_list{margin-top:30px;}
	div.board_write_area ul.board_write_list li{display:block;}
	div.board_write_area ul.board_write_list li h3{padding-bottom:16px;}
	div.board_write_area ul.board_write_list li div.con select{min-width:50%;max-width:100%;}
	div.board_write_area ul.board_write_list li.img_add_area{margin-top:40px;}
	div.board_write_area ul.board_write_list li.img_add_area button.add_file_bt{float:right;margin-top: -43px;}
	div.board_write_area ul.board_write_list li.img_add_area table.file_list{clear:both;}
	div.board_write_area ul.board_write_list li.img_add_area table.file_list tr th{font-size:14px;padding:8px 8px;}
	div.board_write_area ul.board_write_list li.img_add_area table.file_list tr td{font-size:14px;padding:8px 8px;}
	div.board_write_area ul.board_write_list li.img_add_area table.file_list col.col120{width:100px;}
	div.board_write_area ul.board_write_list li.img_add_area table.file_list col.col80{width:50px;}
	div.board_write_area ul.board_write_list li.img_add_area table.file_list tr td.t_left{text-overflow: ellipsis;white-space: nowrap;overflow: hidden;}
	div.board_write_bottom{display:none;}
	div.board_write_bottom_m{display:block;}
	div.board_write_bottom_m button.board_write_bt{font-size:18px;}
}

@media (max-width: 1024px){
	div.board_wrap_file div.board_view_top{border-bottom:0;padding:0;}
	div.board_wrap_file div.board_view_top button.st_clear.prev{display:block;}
	div.board_wrap_file div.board_view_top h3{margin-top:40px;font-size:18px;}
	div.board_wrap_file div.board_view_top p.add_txt{margin-top:12px;}
	div.board_wrap_file ul.board_write_list{margin-top:30px;}
	div.board_wrap_file ul.board_write_list li{display:block;}
	div.board_wrap_file ul.board_write_list li h3{padding-bottom:16px;}
	div.board_wrap_file ul.board_write_list li div.con select{min-width:50%;max-width:100%;}
	div.board_wrap_file ul.board_write_list li.img_add_area{margin-top:40px;}
	div.board_wrap_file ul.board_write_list li.img_add_area button.add_file_bt{float:right;margin-top: -43px;}
	div.board_wrap_file ul.board_write_list li.img_add_area table.file_list{clear:both;}
	div.board_wrap_file ul.board_write_list li.img_add_area table.file_list tr th{font-size:14px;padding:8px 8px;  text-overflow: ellipsis; overflow: hidden; white-space: nowrap;}
	div.board_wrap_file ul.board_write_list li.img_add_area table.file_list tr td{font-size:14px;padding:8px 8px;  text-overflow: ellipsis; overflow: hidden; white-space: nowrap;}
	div.board_wrap_file ul.board_write_list li.img_add_area table.file_list col.col120{width:100px;}
	div.board_wrap_file ul.board_write_list li.img_add_area table.file_list col.col80{width:50px;}
	div.board_wrap_file ul.board_write_list li.img_add_area table.file_list tr td.t_left{text-overflow: ellipsis;white-space: nowrap;overflow: hidden;}
	div.board_write_bottom{display:none;}
	div.board_write_bottom_m{display:block;}
	div.board_write_bottom_m button.board_write_bt{font-size:18px;}
}
@media (max-width: 768px){
	div.board_write_area ul.board_write_list li.img_add_area table.file_list col.col120{width:72px;}
	div.board_write_area ul.board_write_list li.img_add_area table.file_list col.col80{width:40px;}
	div.board_write_area ul.board_write_list li.img_add_area table.file_list tr th{font-size:12px;padding:8px;  text-overflow: ellipsis; overflow: hidden; white-space: nowrap;}
	div.board_write_area ul.board_write_list li.img_add_area table.file_list tr td{font-size:12px;padding:8px;  text-overflow: ellipsis; overflow: hidden; white-space: nowrap;}
}
@media (max-width: 768px){
	div.board_wrap_file ul.board_write_list li.img_add_area table.file_list col.col120{width:72px;}
	div.board_wrap_file ul.board_write_list li.img_add_area table.file_list col.col80{width:40px;}
	div.board_wrap_file ul.board_write_list li.img_add_area table.file_list tr th{font-size:12px;padding:8px; text-overflow: ellipsis; overflow: hidden; white-space: nowrap;}
	div.board_wrap_file ul.board_write_list li.img_add_area table.file_list tr td{font-size:12px;padding:8px; text-overflow: ellipsis; overflow: hidden; white-space: nowrap;}
}
/* 1:1문의 글쓰기 끝 */

/* 통합검색 페이지 */
div#contents.overall_search_page{background:url('/public/images/overall_search_page_bg.png')repeat-x left top;}
section.overall_search_area{padding:120px 0 80px 0;}
section.overall_search_area > div.center{display:flex;}
section.overall_search_area div.center div.osa_left{flex:0 0 350px;}
section.overall_search_area div.center div.osa_left h1{font-size:48px;font-weight:900;line-height:1.2;}
section.overall_search_area div.center div.osa_left ul.tab_menu{margin-top:72px;}
section.overall_search_area div.center div.osa_left ul.tab_menu li{margin-top:24px;}
section.overall_search_area div.center div.osa_left ul.tab_menu li:first-child{margin-top:0;}
section.overall_search_area div.center div.osa_left ul.tab_menu li a{font-size: 24px;font-weight: 700;line-height:1.3;letter-spacing: -0.2px;color:#77777A;}
section.overall_search_area div.center div.osa_left ul.tab_menu li a span.line{width:24px;height:4px;background:#77777A;margin:0 11px 0 0;}
section.overall_search_area div.center div.osa_left ul.tab_menu li.on a{color:#2658E2;}
section.overall_search_area div.center div.osa_left ul.tab_menu li.on a span.line{background:#2658E2;}

section.overall_search_area > div.center_search{display:flex;}
section.overall_search_area div.center_search div.osa_left{flex:0 0 350px;}
section.overall_search_area div.center_search div.osa_left h1{font-size:48px;font-weight:900;line-height:1.2;}
section.overall_search_area div.center_search div.osa_left ul.tab_menu{margin-top:72px;}
section.overall_search_area div.center_search div.osa_left ul.tab_menu li{margin-top:24px;}
section.overall_search_area div.center_search div.osa_left ul.tab_menu li:first-child{margin-top:0;}
section.overall_search_area div.center_search div.osa_left ul.tab_menu li a{font-size: 24px;font-weight: 700;line-height:1.3;letter-spacing: -0.2px;color:#77777A;}
section.overall_search_area div.center_search div.osa_left ul.tab_menu li a span.line{width:24px;height:4px;background:#77777A;margin:0 11px 0 0;}
section.overall_search_area div.center_search div.osa_left ul.tab_menu li.on a{color:#2658E2;}
section.overall_search_area div.center_search div.osa_left ul.tab_menu li.on a span.line{background:#2658E2;}

section.overall_search_area div.center div.osa_right{flex:1;max-width:calc(100% - 408px);max-width:-webkit-calc(100% - 408px);max-width:-moz-calc(100% - 408px);}

section.overall_search_area div.center_search div.osa_right{flex:1;max-width:calc(100% - 408px);max-width:-webkit-calc(100% - 408px);max-width:-moz-calc(100% - 408px);}

div.overall_search_box_wrap{width:100%;max-width:800px;display:flex;padding-bottom:20px;border-bottom:1px solid #77777A;}
div.overall_search_box_wrap label{display:block;flex:1;position:relative;padding-right:40px;}
div.overall_search_box_wrap label input{border:0;outline:0;width:100%;height:100%;background:none;color:#313133;font-size:20px;font-weight:700;letter-spacing: -0.2px;}
div.overall_search_box_wrap label input:focus,
div.overall_search_box_wrap label input:active{border:0 !important;outline:0 !important;}
div.overall_search_box_wrap label button.clear_bt{width:40px;height:40px;text-align:center;position:absolute;top:0;right:0;border:0;outline:0;background:none;}
div.overall_search_box_wrap button.refresh_bt{width:40px;height:40px;margin:0 16px;border:0;outline:0;background:none;}
div.overall_search_box_wrap button.search_bt{border-radius:6px !important;height:40px;width:84px;font-size:16px;font-weight:700;}
div.overall_search_box_wrap button.search_bt img{margin:-1px 5px 0 0;}

section.overall_search_area div.tab_contents{margin-top:56px;}
section.overall_search_area div.tab_contents div.tab_con{margin-top:37px;display:none;}
section.overall_search_area div.tab_contents div.tab_con:first-child{margin-top:0;}
section.overall_search_area div.tab_contents div.tab_con h3.tourist_Info_tit{font-size: 24px;font-weight: 900;line-height:1.3;color:#000;margin-top:37px;overflow:hidden;}
section.overall_search_area div.tab_contents div.tab_con h3.tourist_Info_tit:first-child{margin-top:0;}
section.overall_search_area div.tab_contents div.tab_con h3.tourist_Info_tit a.view_more_bt{float:right;font-size:16px;font-weight:500;color:#313133;padding:4px 0 0 0;}
section.overall_search_area div.tab_contents div.tab_con h3.tourist_Info_tit a.view_more_bt img{margin:-2px 0 0 4px;}

section.overall_search_area div.tab_contents div.tab_con h3.tourist_Info_tit a.formal_list_bt{float:right;font-size:16px;font-weight:500;color:#313133;padding:4px 0 0 0;}
section.overall_search_area div.tab_contents div.tab_con h3.tourist_Info_tit a.formal_list_bt img{margin:-2px 0 0 4px;}

section.overall_search_area div.tab_contents div.tab_con.on{display:block;}

div.tourist_Info_box_wrap{margin-top:16px;margin-bottom:16px;border:1px solid #E6E6EB;border-radius:8px;}
div.tourist_Info_box{border-top:1px solid #E6E6EB;}
div.tourist_Info_box:first-child{border-top:0;}
div.tourist_Info_box a{padding:24px 24px 24px 24px;display:block;font-size:0;}
div.tourist_Info_box div.Info_box{display:inline-block;vertical-align:bottom;width:45%;}
div.tourist_Info_box div.Info_box div.tit_area{font-size:0;}
div.tourist_Info_box div.Info_box div.tit_area h4{font-size: 24px;font-weight: 900;line-height:1.4;color:#2658E2;display:inline-block;vertical-align:middle;}
div.tourist_Info_box div.Info_box div.tit_area ul.nav_info{display:inline-block;vertical-align:middle;font-size:0;padding:8px 0;}
div.tourist_Info_box div.Info_box div.tit_area ul.nav_info li{display:inline-block;vertical-align:middle;font-size: 14px;font-weight: 400;line-height: 1.3;color:#454547;position:relative;padding:0 16px;}
div.tourist_Info_box div.Info_box div.tit_area ul.nav_info li:first-child{padding-left:0;}
div.tourist_Info_box div.Info_box div.tit_area ul.nav_info li:last-child{padding-right:0;}
div.tourist_Info_box div.Info_box div.tit_area ul.nav_info li:before{content:'';display:block;position:absolute;top:50%;left:-7px;margin-top:-8px;width:16px;height:16px;background:url('/public/images/icon_nav_move_next_S.png')no-repeat center;}
div.tourist_Info_box div.Info_box div.tit_area ul.nav_info li:first-child:before{display:none;}
div.tourist_Info_box div.Info_box div.info_list_wrap{display:block;font-size:0;margin-top:16px;}
div.tourist_Info_box div.Info_box div.info_list_wrap ul.info_list{width:calc(50% - 8px);width:-webkit-calc(50% - 8px);width:-moz-calc(50% - 8px);display:inline-block;vertical-align:top;}
div.tourist_Info_box div.Info_box div.info_list_wrap ul.info_list:first-child{margin-right:16px;}
div.tourist_Info_box div.Info_box div.info_list_wrap ul.info_list li{display:block;margin-top:4px;width:100%;line-height:1.6;}
div.tourist_Info_box div.Info_box div.info_list_wrap ul.info_list li:first-child{margin-top:0;}
div.tourist_Info_box div.Info_box div.info_list_wrap ul.info_list li p{color:#454547;font-size:14px;font-weight:400;letter-spacing: -0.4px;
text-overflow:ellipsis;overflow:hidden;white-space:nowrap;width:100%;}
div.tourist_Info_box div.Info_box div.info_list_wrap ul.info_list li p span.line{background:#c3c3c7;width:1px;height:12px;margin:0 8px;display:inline-block;vertical-align:middle;}
div.tourist_Info_box div.Info_box p.info_txt{margin-top:16px;font-size:12px;font-weight: 500;line-height:1.3;letter-spacing: -0.0px;color:#454547;text-overflow:ellipsis;overflow:hidden;display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 3;}

div.tourist_Info_box div.img_box{width:55%;display:inline-block;vertical-align:bottom;padding-left:16px;text-align:right;}
div.tourist_Info_box div.img_box img{margin-bottom: 10px; margin-left:8px;width:calc(33% - 4px);width:-webkit-calc(33% - 4px);width:-moz-calc(33% - 4px);}
div.tourist_Info_box div.img_box img:first-child{margin-left:0;margin-bottom: 10px;}

div.tourist_Info_box_wrap.divide2{display:flex;flex-wrap:wrap;}
div.tourist_Info_box_wrap.divide2 div.tourist_Info_box{width:50%;border:0;}
div.tourist_Info_box_wrap.divide2 div.tourist_Info_box:nth-child(1){border-top:0 !important;}
div.tourist_Info_box_wrap.divide2 div.tourist_Info_box:nth-child(2){border-top:0 !important;}
div.tourist_Info_box_wrap.divide2 div.tourist_Info_box:nth-child(2n-1){border-right:1px solid #E6E6EB;}
div.tourist_Info_box_wrap.divide2 div.tourist_Info_box:nth-child(2n+1){border-top:1px solid #E6E6EB;}
div.tourist_Info_box_wrap.divide2 div.tourist_Info_box:nth-child(2n+2){border-top:1px solid #E6E6EB;}
div.tourist_Info_box_wrap.divide2 div.tourist_Info_box a{padding:24px;font-size:0;}
div.tourist_Info_box_wrap.divide2 div.tourist_Info_box div.Info_box{width:62% !important;}
div.tourist_Info_box_wrap.divide2 div.tourist_Info_box div.img_box{width:38%;max-width:208px;padding:0;}
div.tourist_Info_box_wrap.divide2 div.tourist_Info_box div.Info_box div.info_list_wrap ul.info_list{width:100%;}
div.tourist_Info_box_wrap.divide2 div.tourist_Info_box div.Info_box div.tit_area ul.nav_info li{padding:0 10px;}
div.tourist_Info_box_wrap.divide2 div.tourist_Info_box div.Info_box div.tit_area ul.nav_info li:first-child{padding-left: 0;}
div.tourist_Info_box_wrap.divide2 div.tourist_Info_box div.img_box img{width:100%;}
@media (max-width: 1660px){
	section.overall_search_area div.center div.osa_left{flex:0 0 250px;}
	section.overall_search_area div.center div.osa_right{max-width:calc(100% - 250px);max-width:-webkit-calc(100% - 250px);max-width:-moz-calc(100% - 250px);}

	section.overall_search_area div.center_search div.osa_left{flex:0 0 250px;}
	section.overall_search_area div.center_search div.osa_right{max-width:calc(100% - 250px);max-width:-webkit-calc(100% - 250px);max-width:-moz-calc(100% - 250px);}
}
@media (max-width: 1440px){
	div.tourist_Info_box div.Info_box{width:100% !important;}
	div.tourist_Info_box div.img_box{width:100%;padding:16px 0 0 0;text-align:left;}
	div.tourist_Info_box_wrap.divide2 div.tourist_Info_box div.Info_box{width:62%;}
	div.tourist_Info_box_wrap.divide2 div.tourist_Info_box div.img_box{width:38%;padding:0;}
}
@media (max-width: 1024px){
	div#contents.overall_search_page{background: url('/public/images/overall_search_page_bg_m.png')repeat-x left top;}
	section.overall_search_area{padding:40px 0;}
	section.overall_search_area div.center{display:block;padding-top:140px;}
	section.overall_search_area div.center div.osa_left h1{display:none;}
	section.overall_search_area div.center div.osa_left ul.tab_menu{margin-top:0;font-size:0;position:absolute;top:77px;left:0;width:100%;white-space: nowrap;overflow-x: auto;padding-left:16px;}
	section.overall_search_area div.center div.osa_left ul.tab_menu li{margin:0 0 0 16px;display:inline-block;vertical-align:top;}
	section.overall_search_area div.center div.osa_left ul.tab_menu li:first-child{margin-left:0;}
	section.overall_search_area div.center div.osa_left ul.tab_menu li a{font-size:18px;position:relative;line-height:45px;}
	section.overall_search_area div.center div.osa_left ul.tab_menu li a span.line{width:100%;height:2px;background:#fff;margin:0;position:absolute;bottom:0;}
	section.overall_search_area div.center div.osa_left ul.tab_menu li.on a{color:#2658E2;}
	section.overall_search_area div.center div.osa_left ul.tab_menu li.on a span.line{background:#2658E2;display:block;}
	section.overall_search_area div.center div.osa_right{width:100%;max-width:100%;}

	section.overall_search_area div.center_search{display:block;}
	section.overall_search_area div.center_search div.osa_left h1{display:none;}
	section.overall_search_area div.center_search div.osa_left ul.tab_menu{margin-top:0;font-size:0;position:absolute;top:77px;left:0;width:100%;white-space: nowrap;overflow-x: auto;padding-left:16px;}
	section.overall_search_area div.center_search div.osa_left ul.tab_menu li{margin:0 0 0 16px;display:inline-block;vertical-align:top;}
	section.overall_search_area div.center_search div.osa_left ul.tab_menu li:first-child{margin-left:0;}
	section.overall_search_area div.center_search div.osa_left ul.tab_menu li a{font-size:18px;position:relative;line-height:45px;}
	section.overall_search_area div.center_search div.osa_left ul.tab_menu li a span.line{width:100%;height:2px;background:#fff;margin:0;position:absolute;bottom:0;}
	section.overall_search_area div.center_search div.osa_left ul.tab_menu li.on a{color:#2658E2;}
	section.overall_search_area div.center_search div.osa_left ul.tab_menu li.on a span.line{background:#2658E2;display:block;}
	section.overall_search_area div.center_search div.osa_right{width:100%;max-width:100%;}

	div.overall_search_box_wrap{position:absolute;top:-170px;left:0;right:0;width:100%;margin:0 auto;padding-bottom:10px;max-width:100%;}
	div.overall_search_box_wrap label input{padding:0 8px 0 0;font-size:16px;}
	div.overall_search_box_wrap button.refresh_bt{margin:0px;}
	div.overall_search_box_wrap button.search_bt{width:60px;}
	div.overall_search_box_wrap button.search_bt img{display:none;}

	section.overall_search_area div.tab_contents{margin-top:32px;}
	section.overall_search_area div.tab_contents div.tab_con{margin-top:34px;}

	section.overall_search_area div.tab_contents div.tab_con h3.tourist_Info_tit{font-size:20px;margin-top:34px;}

	div.tourist_Info_box a{padding:18px 0 18px 18px;}
	div.tourist_Info_box div.Info_box{padding-right:16px;}
	div.tourist_Info_box div.Info_box div.tit_area ul.nav_info li{padding:0 12px;}
	div.tourist_Info_box div.Info_box div.info_list_wrap ul.info_list{width:100%;margin:4px 0 0 0;}
	div.tourist_Info_box div.Info_box div.info_list_wrap ul.info_list:first-child{margin-top:0;}

	div.tourist_Info_box div.img_box{white-space:nowrap;overflow-x:auto;}
	div.tourist_Info_box div.img_box img{max-width:70%;width:auto;}

	div.tourist_Info_box_wrap.divide2 div.tourist_Info_box{width:100%;border:0 !important;border-top:1px solid #E6E6EB !important;}
	div.tourist_Info_box_wrap.divide2 div.tourist_Info_box:nth-child(1){border-top:0 !important;}
	div.tourist_Info_box_wrap.divide2 div.tourist_Info_box:nth-child(2){border-top:1px solid #E6E6EB !important;}
	div.tourist_Info_box_wrap.divide2 div.tourist_Info_box a{padding:18px;}
	div.tourist_Info_box_wrap.divide2 div.tourist_Info_box div.Info_box{width:100%;}
	div.tourist_Info_box_wrap.divide2 div.tourist_Info_box div.Info_box div.info_list_wrap ul.info_list{width:100%;}
	div.tourist_Info_box_wrap.divide2 div.tourist_Info_box div.img_box{width:100%;max-width:100%;padding:16px 0 0 0;text-align:left}
	div.tourist_Info_box_wrap.divide2 div.tourist_Info_box div.img_box img{width:auto;max-width:100%;}
}
@media (max-width: 768px){
	section.overall_search_area div.center div.osa_left ul.tab_menu{padding-left:16px;}
	section.overall_search_area div.center_search div.osa_left ul.tab_menu{padding-left:16px;}
}

div.overall_search_box_cover{position:relative;}
div.overall_search_box_cover div.search_page_body{position:absolute;top:100%;left:0;width:100%;max-width:800px;display:none;}
div.overall_search_box_cover div.search_page_body div.center{width:100%;max-width:800px;z-index:1;padding:40px;}

div.overall_search_box_cover div.search_page_body div.center_search{width:100%;max-width:800px;z-index:1;padding:40px;}

div.overall_search_box_cover div.search_page_body div.word_wrap > div{width:100% !important;}
div.overall_search_box_cover div.search_page_body div.word_wrap div.latest{margin-top:28px;}
div.overall_search_box_cover div.search_page_body div.key_word > div h4{display:block;}
div.overall_search_box_cover div.search_page_body div.key_word > div div.list{width:100%;margin-top:8px;}
@media screen and (max-width: 1024px){
	div.overall_search_box_cover div.search_page_body{top:-119px;max-width:100%;}

	div.overall_search_box_cover div.search_page_body div.center{padding:16px;max-width:100%;}

	div.overall_search_box_cover div.search_page_body div.center_search{padding:16px;max-width:100%;}

	div.overall_search_box_cover div.search_page_body div.word_wrap h3{width:100%;display:block;font-size:16px;}
	div.overall_search_box_cover div.search_page_body div.word_wrap .list_box{width:100%;display:block;}
	div.overall_search_box_cover div.search_page_body div.word_wrap div.latest{width:100%;margin-top:24px;}
	div.overall_search_box_cover div.search_page_body div.key_word{margin-top:24px;}
	div.overall_search_box_cover div.search_page_body div.key_word > div h4{font-size:16px;}
	div.overall_search_box_cover div.search_page_body div.key_word > div div.list{margin-top:4px;}
	div.overall_search_box_cover div.search_page_body div.key_word > div div.list button{margin:4px 8px 4px 0;}
}

/* 통합검색 페이지 ( 이미지 ) */
ul.oas_img_list{padding-top:35px;display:flex;flex-wrap:wrap;max-width:1072px;}
ul.oas_img_list li{width:calc(25% - 12px);width:-webkit-calc(25% - 12px);width:-moz-calc(25% - 12px);margin:0 0 24px 16px;}
ul.oas_img_list li:nth-child(4n+1){margin-left:0;}
ul.oas_img_list li a div.img_box{width:100%;border-radius:16px;overflow:hidden;}
ul.oas_img_list li a div.img_box img{width:100%; height: 160px; object-fit: cover;}
ul.oas_img_list li a h5{margin-top:16px;font-size:14px;font-weight:500;color:#313133;line-height:1.3;text-overflow:ellipsis;overflow:hidden;white-space:nowrap;}
div#contents.overall_search_page div.paging{display:block;}
div#contents.overall_search_page .m_board{margin-top:0;}
div#contents.overall_search_page .m_board button.more_board_bt{margin-top:0;}
@media (max-width: 1024px){
	ul.oas_img_list{padding-top:0;}
	ul.oas_img_list li{width:calc(33% - 12px);width:-webkit-calc(33% - 12px);width:-moz-calc(33% - 12px);margin:0 0 24px 16px;}
	ul.oas_img_list li:nth-child(4n+1){margin-left:16px;}
	ul.oas_img_list li:nth-child(3n+1){margin-left:0;}
	div#contents.overall_search_page div.paging{display:none;}
}
@media (max-width: 768px){
	ul.oas_img_list li{width:calc(50% - 4px);width:-webkit-calc(50% - 4px);width:-moz-calc(50% - 4px);margin:0 0 24px 8px;}
	ul.oas_img_list li:nth-child(4n+1){margin-left:8px;}
	ul.oas_img_list li:nth-child(3n+1){margin-left:8px;}
	ul.oas_img_list li:nth-child(2n+1){margin-left:0px;}
}
/* 통합검색 페이지 ( 이미지 ) 끝 */

/* 통합검색 페이지 ( 동영상 ) */
ul.oas_img_list.oas_video_list li{width:calc(33% - 9px);width:-webkit-calc(33% - 9px);width:-moz-calc(33% - 9px);margin:0 0 24px 16px;}
ul.oas_img_list.oas_video_list li:nth-child(3n+1){margin-left:0;}
@media (max-width: 768px){
	ul.oas_img_list.oas_video_list li{width:calc(50% - 4px);width:-webkit-calc(50% - 4px);width:-moz-calc(50% - 4px);margin:0 0 24px 8px;}
	ul.oas_img_list.oas_video_list li:nth-child(3n+1){margin-left:8px;}
	ul.oas_img_list.oas_video_list li:nth-child(2n+1){margin-left:0px;}
}
@media (max-width: 360px){
	ul.oas_img_list.oas_video_list li{width:100%;margin:0 0 24px 0;}
	ul.oas_img_list.oas_video_list li:nth-child(3n+1){margin-left:0;}
	ul.oas_img_list.oas_video_list li:nth-child(2n+1){margin-left:0;}
}
/* 통합검색 페이지 ( 동영상 ) 끝 */

/* 통합검색 페이지 ( 파일 ) */
div.no_data_wrap{display:flex;align-items:center;justify-content:center;padding:160px 0 190px 0;}
div.no_data_wrap div.no_data{text-align:center;}
div.no_data_wrap div.no_data img{max-width:100%;}
div.no_data_wrap div.no_data p.no_data_txt{margin-top:16px;font-size: 24px;font-weight: 700;line-height:1.4;letter-spacing: -0.2px;text-align: center;color:#313133;}
@media (max-width: 1024px){
	div.no_data_wrap{padding:70px 0 100px 0;}
	div.no_data_wrap div.no_data img{width:120px;}
	div.no_data_wrap div.no_data p.no_data_txt{font-size:18px;}
}
/* 통합검색 페이지 ( 파일 ) 끝 */
/* 통합검색 페이지 끝 */

/* 통합검색 상세페이지 ( 관광정보 ) */
div.tab_contents_detail{max-width:1104px;}
div.contents_detail_page_wrap{margin-top:16px;padding:32px;}
div.tab_contents_detail div.board_view_bottom{border-top:0;}
div.tab_contents_detail div.div.contents_detail_page{padding:0;}
div#contents.overall_search_detail div.board_view_top{overflow:hidden;}
div#contents.overall_search_detail div.board_view_top button.close_bt{display:none;float: right;border: 0;outline: 0;background: none;}
div#contents.overall_search_detail div.download_area{margin-top:24px;}
@media (max-width: 1024px){
	div#contents.overall_search_detail{background:none !important;}
	div#contents.overall_search_detail section.overall_search_area{padding:0;}
	div.contents_detail_page_wrap{margin-top:0px;padding:16px 0;}
	div.contents_detail_page_wrap div.language_list{padding-left:16px;}
	div#contents.overall_search_detail div.board_view_top{border-bottom:0;padding:16px;}
	div#contents.overall_search_detail div.board_view_top button.close_bt{display:block;}
	div#contents.overall_search_detail div.board_view_top button.st_clear.prev{display:none;}
	div#contents.overall_search_detail section.overall_search_area div.center{padding:0;}
	div#contents.overall_search_detail section.overall_search_area div.center div.osa_left ul.tab_menu{display:none;}
	div#contents.overall_search_detail section.overall_search_area div.center div.osa_right{width:100%;max-width:100%;}

	div#contents.overall_search_detail section.overall_search_area div.center_search{padding:0;}
	div#contents.overall_search_detail section.overall_search_area div.center_search div.osa_left ul.tab_menu{display:none;}
	div#contents.overall_search_detail section.overall_search_area div.center_search div.osa_right{width:100%;max-width:100%;}

	div#contents.overall_search_detail div.board_view_bottom{display:none;}
	div#contents.overall_search_detail div.board_view_bottom:before{display:none;}
	div#contents.overall_search_detail div.download_area button{width:auto;}
}
/* 통합검색 상세페이지 ( 관광정보 ) 끝 */

/* footer */
footer{background:#F0F0F5;padding:20px 0;}
div.ft_top{position:relative;}
div.ft_top:after{content:'';display:block;clear:both;}
div.ft_links{float:left;font-size:0; margin-top: 2%;}
div.ft_links a{line-height:36px;font-size:18px;font-weight:700;color:#5E5E61;margin-right:32px;display:inline-block;vertical-align:top;}
div.ft_links a:last-child{margin-right:0;}
div.family_site_box{float:right;position:relative;}
div.family_site_box button.family_site_bt{height:36px;padding:0 16px; margin-top: 26%;}
div.family_site_box ul.family_site_list{padding:8px 0;background:#fff;width:130px;box-shadow: 0px 4px 16px 0px #0000003D;position:absolute;bottom:48px;left:0;border-radius:8px;z-index:1;display:none;}
div.family_site_box ul.family_site_list li{}
div.family_site_box ul.family_site_list li a{padding:12px 14px;font-size:14px;font-weight:500;color:#313133;}

div.ft_bottom{margin-top:40px;position:relative;padding-top:40px;border-top:1px solid #C3C3C7;}
div.ft_bottom:after{content:'';display:block;clear:both;}
div.ft_bottom div.ft_info{float:left;}
div.ft_bottom div.ft_info p{font-size: 16px;font-weight: 500;line-height:1.4;letter-spacing: -0.2px;color:#5E5E61;margin-top:8px;}
div.ft_bottom div.ft_info p:first-child{margin-top:0;}
div.ft_bottom h1.ft_logo{float:right;}
@media (max-width: 1024px){
	footer{padding:20px 0;}
	div.ft_bottom div.ft_info{float:none;}
	div.ft_bottom h1.ft_logo{float:none;display:block;margin-top:24px;}
	div.ft_links a{font-size:14px;margin-right:8px;}
	div.family_site_box{margin-top:8px;}
}

@media (max-width: 768px){
	div.ft_bottom div.ft_info p{font-size:14px;}
}
@media (max-width: 360px){
}
a.formal_list_bt{
	display: none;
}

/* footer 끝 */

/* flex */
.flex{display:flex}
.column{flex-direction:column !important}
.item-start{align-items:start !important}
.item-center{align-items:center !important}
.item-end{align-items:flex-end !important}
.js-l{justify-content:left !important}
.js-c{justify-content:center !important}
.js-r{justify-content:right !important}
.js-ed{justify-content:flex-end !important}
.js-sb{justify-content:space-between !important}
.fx-wrap{flex-wrap:wrap !important}
.fx-nowrap{flex-wrap:nowrap !important}

/* 3/16 통계 추가 */

/*20231225 작업(아래 내용만 추가해주시면 됩니다.*/

.statistics_menu{display: none}
.statistics_area .statistics_graph_area .city-select{display: none}
.statistics_area .statistics_graph_area .month-select{display: none}

@media screen and (max-width: 768px){
	div.con_box{padding-top:50px;}
	div.tooltip ul.dot_list_region{position:absolute; top:255px; width: 90vw; border-radius:4px;padding:12px;z-index:1;background:#fff; box-shadow: 0px 0px 4px 0px #0000003D; -webkit-box-shadow: 0px 0px 4px 0px #0000003D; -moz-box-shadow: 0px 0px 4px 0px #0000003D; transform:translateY(-50%); -webkit-transform:translateY(-50%); -moz-transform:translateY(-50%); display:none;}
	div.tooltip ul.dot_list_region li{font-size:14px;font-weight:400;color:#313133;letter-spacing:-0.4px;padding-left:25px;line-height:2;}
	div.tooltip ul.dot_list_region li:before{margin-top:0;top:12px;}

	div.con_box{padding-top:50px;}
	div.tooltip ul.dot_list_category{position:absolute; top:215px; width: 90vw; border-radius:4px;padding:12px;z-index:1;background:#fff; box-shadow: 0px 0px 4px 0px #0000003D; -webkit-box-shadow: 0px 0px 4px 0px #0000003D; -moz-box-shadow: 0px 0px 4px 0px #0000003D; transform:translateY(-50%); -webkit-transform:translateY(-50%); -moz-transform:translateY(-50%); display:none;}
	div.tooltip ul.dot_list_category li{font-size:14px;font-weight:400;color:#313133;letter-spacing:-0.4px;padding-left:25px;line-height:2;}
	div.tooltip ul.dot_list_category li:before{margin-top:0;top:12px;}

	div.con_box{padding-top:50px;}
	div.tooltip ul.dot_list_language{position:absolute; top:245px; width: 90vw; border-radius:4px;padding:12px;z-index:1;background:#fff; box-shadow: 0px 0px 4px 0px #0000003D; -webkit-box-shadow: 0px 0px 4px 0px #0000003D; -moz-box-shadow: 0px 0px 4px 0px #0000003D; transform:translateY(-50%); -webkit-transform:translateY(-50%); -moz-transform:translateY(-50%); display:none;}
	div.tooltip ul.dot_list_language li{font-size:14px;font-weight:400;color:#313133;letter-spacing:-0.4px;padding-left:25px;line-height:2;}
	div.tooltip ul.dot_list_language li:before{margin-top:0;top:12px;}

	.region_select_box{position:fixed;overflow-y:auto;bottom:0;width:100%;min-height:74vh;max-height:74vh;padding:4vw 6vw;background-color:#FFF;z-index:1300;-ms-overflow-style:none;scrollbar-width:none;}
    .region_select_box::-webkit-scrollbar{display:none;}
    .region_select_box ul li{margin-top:4.5vw;cursor: pointer;}
    .region_select_box ul li:first-child{margin-top:0}
    .region_select_box ul li a{display:block;padding:2vw 4.5vw;color:#242322}
    .region_select_box ul li a.selected{background-color:#242322;color:#FCF9F7;}
    .backdrop{position:fixed;top:0;width:100%;height:100%;background-color:rgba(0,0,0,0.4);z-index:1200}

	.region_box_arrow{background:url('/public/images/ic_arrow_drop_down.png')no-repeat center; width: 24px;height: 12px;}
	.region_top_menu{display:flex;align-items:center; justify-content: space-between;min-height:54px;padding:10px 20px;background-color:#F1F5F8;border-bottom:1px solid #D2DADF;}
	.region_tap .region_period{margin: unset;font-size:16px;font-weight:500;line-height:1.4;color:#454547;letter-spacing:-0.2px;}
	.region_backdrop{position:fixed;top:0;width:100%;height:100%;background-color:rgba(0,0,0,0.4);z-index:99}

    .st-box-wrap{height: auto!important;}
    .sga_tit{display: none}
    .stts-wrap .st-wrap{margin-top: 40px!important;}

    .statistics_menu{display: inline-block; padding: 10px 0; border-top: 1px solid #ccc; width: 100%; margin-top: 10px;overflow-x: scroll}
    .statistics_menu ul{display: inline-block; width: 525px;}
    .statistics_menu ul li{display: inline-block; font-size: 20px; margin-right: 20px; cursor: pointer; position: relative}
    .statistics_menu ul li i{height: 30px!important; width: 25px!important;}

	.statistics_menu ul li.active{color:#2658E2}

	.registration_color-guide li{display:flex;align-items:center;margin-left: 15px;margin-bottom: 20px;}
	.registration_color-guide span{font-family:var(--nt-m);color:#575757;font-size:12px;}
	.registration_color-guide .color-circle{flex:none;display:block;width:12px;height:12px;margin-right:8px;border-radius:10px;}

	.standard_color-guide li{display:flex;align-items:center;margin-left: 15px;margin-bottom: 20px;}
	.standard_color-guide span{font-family:var(--nt-m);color:#575757;font-size:12px;}
	.standard_color-guide .color-circle{flex:none;display:block;width:12px;height:12px;margin-right:8px;border-radius:10px;}

	.region-sigungu-chart-view-mobile .recharts-wrapper .recharts-legend-wrapper{left: 5px; bottom: 25px;}

	/* 총 콘텐츠 건수 */
	div.statistics_list_mobile{width: 100%;margin-top:20px; display: inline-block}
    .statistics_list_mobile .statistics_item_mobile{margin-left: 0!important; margin-right: 3%; margin-top: 16px; display: inline-block!important; width: 48% !important; vertical-align: top; padding: 20px!important; text-align: left; position: relative}
    .statistics_list_mobile .statistics_item_mobile:nth-child(2), .statistics_list .statistics_item:nth-child(4){margin-right: 0}
	.statistics_list_mobile .statistics_item_mobile:nth-child(4), .statistics_list .statistics_item:nth-child(6){margin-right: 0}

    .statistics_list_mobile .statistics_item_mobile h3{padding-top: 50px}
    .statistics_list_mobile .statistics_item_mobile h3 img{display: inline-block; float: initial!important; position: absolute; left: 20px; top: 20px}

	div.statistics_list_mobile div.statistics_item_mobile{flex:1;margin-left:16px;padding:40px;border-radius:24px;background:#E9EEFC;}
	div.statistics_list_mobile div.statistics_item_mobile:first-child{margin-left:0px;}
	div.statistics_list_mobile div.statistics_item_mobile h3{font-size:24px;font-weight:900;line-height:1.4;color:#454547;overflow:hidden;}
	div.statistics_list_mobile div.statistics_item_mobile h3 img{float:right;}
	div.statistics_list_mobile div.statistics_item_mobile p{margin-top:16px;font-size:16px;font-weight:500;line-height:1.4;color:#313133;letter-spacing:-0.2px;}
	div.statistics_list_mobile div.statistics_item_mobile p span{font-size:18px;font-weight:900;padding-right:8px;}
	div.statistics_list_mobile div.statistics_item_mobile.all{background:#2658E2;}
	div.statistics_list_mobile div.statistics_item_mobile.all h3{color:#fff;}
	div.statistics_list_mobile div.statistics_item_mobile.all p{color:#fff;}
	div.statistics_list_mobile div.statistics_item_mobile.all p span{color:#fff;}

	/* 유형별 차트 */
	div.statistics_list_category_mobile{width: 100%;margin-top:20px; display: inline-block}
    .statistics_list_category_mobile .statistics_item_category_mobile{margin-left: 0; margin-right: 3%; margin-top: 16px; display: inline-block;width: 100%;vertical-align: top; padding: 20px;text-align: left; position: relative}
    .statistics_list_category_mobile .statistics_item_category_mobile:nth-child(2), .statistics_list .statistics_item:nth-child(4){margin-right: 0}
	div.statistics_list_category_mobile div.statistics_item_category_mobile{border-radius:24px;}
	div.statistics_list_category_mobile div.statistics_item_category_mobile:first-child{margin-left:0px;}

	/* 언어별별 차트 */
	div.statistics_list_language_mobile{width: 100%;margin-top:20px; display: inline-block}
	.statistics_list_language_mobile .statistics_item_language_mobile{margin-left: 0; margin-right: 3%; margin-top: 16px; display: inline-block;width: 100%;vertical-align: top; padding: 20px;text-align: left; position: relative}
	.statistics_list_language_mobile .statistics_item_language_mobile:nth-child(2), .statistics_list .statistics_item:nth-child(4){margin-right: 0}
	div.statistics_list_language_mobile div.statistics_item_language_mobile{border-radius:24px;}
	div.statistics_list_language_mobile div.statistics_item_language_mobile:first-child{margin-left:0px;}

	.statistics_area .status-type-graph{flex-direction:row}
	.statistics_area .status-type-graph .st-box-cnt{display:flex;flex-grow:1;padding:30px 20px}
	.statistics_area .status-type-graph .st-box-cnt > div{display:flex;flex-direction:column;justify-content:space-between;height:367px}
	.statistics_area .status-type-graph .left{width:585px}
	.statistics_area .status-type-graph .right{width:635px}
	.statistics_area .status-type-graph .st-box-tit{text-align:center}
	
    .statistics_area{max-width: 100%!important;}
    .statistics_area .center{width: 100%!important; max-width: 100%!important;}
    .statistics_area .st-box:nth-child(1){display: none}
    .statistics_area .statistics_graph_area{display: none; margin-top: 0}
    .statistics_area .statistics_graph_area .st-box-top{display: none}
    .statistics_area .statistics_graph_area .city-select{margin-bottom: 40px; display: inline-block;}
    .statistics_area .statistics_graph_area .month-select{margin-bottom: 40px; display: inline-block; position: absolute; right: 20px}
    .statistics_area .statistics_graph_area .month-select select{display: inline-block}
    .statistics_area .statistics_graph_area .flex{flex-direction: column;}
    .statistics_area .statistics_graph_area .flex .st-box-cnt{height: initial; border: 0; width: 100%; position: relative}
    .statistics_area .statistics_graph_area .flex .st-box-cnt .info-txt{}
    .statistics_area .statistics_graph_area .flex .st-box-cnt .region-sigungu-chart-view-mobile{width: 100%!important; text-align: center}
    .statistics_area .statistics_graph_area .flex .st-box-cnt .region-sigungu-chart-view-mobile .recharts-wrapper{display: inline-block!important; width: 100%!important; height: auto!important;}
    .statistics_area .statistics_graph_area .flex .st-box-cnt .region-sigungu-chart-view-mobile{width: 100%!important; text-align: center}
    .statistics_area .statistics_graph_area .flex .st-box-cnt .region-sigungu-chart-view-mobile .recharts-wrapper{display: inline-block!important; width: 100%!important; height: auto!important;}
    .statistics_area .st-layer:nth-child(3){display: none}
    .statistics_area .st-layer:nth-child(4){display: none}
    .statistics_area .statistics_graph_area .flex .st-box-cnt .region-standard-chart-view{width: 100%!important; text-align: center}
    .statistics_area .statistics_graph_area .flex .st-box-cnt .region-standard-chart-view .recharts-wrapper{display: inline-block!important; width: 100%!important; height: auto!important;}
    .st-box-tit{width: 100%}
    .st-box{flex-direction: column!important; height: auto!important;}
}

/* flex */
.fx-grw{flex-grow:1}
.gap-hz5{gap:0 5px}
.gap-hz10{gap:0 10px}
.gap-hz30{gap:0 30px}
/* margin */
.mt-10px{margin-top:20px !important}
.mt-20px{margin-top:20px !important}
.mt-30px{margin-top:20px !important}
.mt-40px{margin-top:40px !important}
.mt-50px{margin-top:20px !important}
.mt-60px{margin-top:20px !important}
.mt-100px{margin-top:20px !important}
/* icon */
.ico{display:inline-block;width:1.25vw;height:1.25vw;background-repeat:no-repeat;background-size:contain;background-position:center}
.ico-share-gy{width:24px;height:24px;background-image:url('/public/images/ico-share-gy.svg')}
.ico-exel-gy{width:24px;height:24px;background-image:url('/public/images/ico-exel-gy.svg')}
.ico-word-gy{width:24px;height:24px;background-image:url('/public/images/ico-word-gy.svg')}
.ico-info-gy{width:18px;height:18px;background-image:url('/public/images/ico-info-gy.svg')}
.ico-reset{width:24px;height:24px;background-image:url('/public/images/ico-reset.svg')}
.ico-tooltip-gy{width:22px;height:22px;background-image:url('/public/images/ico-tooltip-gy.svg')}
/* 현재 기준 콘텐츠 현황 */
.stts-wrap{margin-bottom:50px}
.ttl-cnt-stts p{color:#797979}
.ttl-cnt-item li{display:flex;flex-direction:column;width:230px;padding:20px 30px;border-radius:8px;}
.ttl-cnt-item li span, .ttl-cnt-item li p{color:var(--white);font-size:20px;}
.ttl-cnt-item li span{margin-top:8px;font-family:var(--nt-m);font-size:30px;line-height:43px}
.ttl-cnt-item li:nth-child(1){background-color:#37DBC8}
.ttl-cnt-item li:nth-child(2){background-color:#59D3FA}
.ttl-cnt-item li:nth-child(3){background-color:#437AFD}
.ttl-cnt-item li:nth-child(4){background-color:#7C64FF}
.ttl-cnt-item li:nth-child(5){background-color:#FF58F8}
/* 레이아웃 */
.bd-b-tit{padding-bottom:25px;margin-bottom:25px;border-bottom:3px solid #999;font-family:var(--nt-b);font-size:30px;color:var(--black)}
.em-tit{display:flex;align-items:center;margin-bottom:15px;font-family:var(--nt-b);font-size:26px;color:var(--black);}
.em-tit em{color:#437AFD}
.st-box{display:flex;flex-direction:column;border:1px solid #D2DADF;border-radius:15px;}
.st-box-top{display:flex;align-items:center;min-height:54px;padding:10px 20px;background-color:#F1F5F8;border-bottom:1px solid #D2DADF;border-top-left-radius:15px;border-top-right-radius:15px;}
.st-box-top > p{font-family:var(--nt-b);font-size:20px}
.st-box-top.blue{background-color:#437AFD;color:#fff;}
.st-box-cnt{padding:20px}
.st-box-tit{margin-bottom:20px;font-family:var(--nt-b);font-size:18px;color:#575757}
/* 데코 */
.tooltip-wrap{position:relative;width:385px;margin-left:10px;font-size:0;}
.tooltip-layer{display:none;position:absolute;top:-13px;left:36px;padding:14px 20px;max-width:354px;border-radius:10px;background-color:rgba(40,40,40,.8);font-family:var(--nt-m);font-size:12px;color:#fff;line-height:17px;letter-spacing:-0.02rem;word-break:keep-all;
box-shadow: 0 4px 7px rgba(0, 0, 0, 0.25);color:#fff;z-index:10}
.tooltip-layer ul{list-style:disc;margin-left:20px}
.tooltip-layer li{line-height:17px}
.tooltip-layer:after {content:'';position:absolute;left:0;top:16px;width:0;height:0;border:8px solid transparent;border-right-color:rgba(40,40,40,.8);border-left:0;margin-left:-8px;}
.tooltip-wrap button:hover ~ .tooltip-layer{display:block}
.info-txt{display:flex;align-items:center;font-family:var(--nt-m);font-size:12px;color:#B4B4B4}
.info-txt .ico{margin-right:5px}
.graph-color-guide{display:flex;flex-direction:column;gap:20px 0}
.graph-color-guide.row{justify-content:center;flex-direction:row;gap:0 20px;position: relative;top: 60px;}
.graph-color-guide li{display:flex;align-items:center;}
.graph-color-guide span{font-family:var(--nt-m);color:#575757;font-size:12px;}
.graph-color-guide .color-circle{flex:none;display:block;width:12px;height:12px;margin-right:8px;border-radius:12px;}
.cFF58F8{background-color:#FF58F8}
.cFFCF52{background-color:#FFCF52}
.c7C64FF{background-color:#7C64FF}
.c437AFD{background-color:#437AFD}
.c59D3FA{background-color:#59D3FA}
.c37DBC8{background-color:#37DBC8}
.cFFD770{background-color:#FFD770}
.cFF8652{background-color:#FF8652}
.cFF667C{background-color:#FF667C}
.cEB348C{background-color:#EB348C}
.cFF58F8{background-color:#FF58F8}
.c7C64FF{background-color:#7C64FF}

.test{display:flex;flex-direction:column;gap:20px 0}
.test.row{justify-content:center;flex-direction:row;gap:0 20px;}
.test li{display:flex;align-items:center;}
.test span{font-family:var(--nt-m);color:#575757;font-size:12px;}
.test .color-circle{flex:none;display:block;width:12px;height:12px;margin-right:8px;border-radius:10px;}

.color-guide{margin-left: 5px; display: flex; top: -46px; position: relative; left: 300px;}
.color-guide li{display:flex;align-items:center;margin-left: 15px;margin-bottom: 20px;}
.color-guide span{font-family:var(--nt-m);color:#575757;font-size:12px;}
.color-guide .color-circle{flex:none;display:block;width:12px;height:12px;margin-right:8px;border-radius:10px;}
.region-category-chart-legend{top: -150px;position: relative; padding-left: 25px;margin-left: 28px;}
.use-detail-chart-legend{position: relative;left: 380px;display: flex;flex-direction: column;gap: 13px;top: 20px;}
.calendar-head{background-color: #fbfbfb; border-top-left-radius: 20px; border-top-right-radius: 20px; display: flex; justify-content: space-around;}
.cal-month{display: flex; flex-wrap: wrap; justify-content: center; position: relative; bottom: -4px; left: 5px; width: 255px;}
.cal-month button{height: 40px; font-size: 16px !important; margin-bottom: 20px !important; color: #575757; width: 64px;}
.calendar-select-button{border-bottom-left-radius: 20px; border-bottom-right-radius: 20px; position: absolute; width: 266px; bottom: 0px; background-color: #437afe; height: 54px; flex-wrap: wrap; justify-content: center; display: flex;}
.calendar-select-title{font-size: 18px; color: white;}
.MuiDialog-paper{border-radius: 20px !important;}
.cal-month-date{font-size: 16px !important; margin-bottom: 20px !important; color: #575757 !important; margin-left: 2px !important; width: 60px !important;}

.recharts-legend-item-text{cursor: pointer; font-family: var(--nt-m); color: #575757 !important; font-size: 12px;}
.recharts-polar-angle-axis-ticks{cursor: pointer;}
.info-txt-gy{position: relative;top: -51px;display: -webkit-flex;display: flex;-webkit-align-items: center;align-items: center;font-family: var(--nt-m);font-size: 12px; color: #B4B4B4;}
.st-box-tit-gy{font-family: var(--nt-b);font-size: 18px;color: #575757;position: relative;top: -83px;}
.info-txt-gy .ico {margin-right: 5px;}
.recharts-cartesian-axis-ticks{cursor: pointer;}
.category-accumulate-bar .recharts-bar-rectangles{cursor: unset;}
.region-standard-bar .recharts-bar-rectangles{cursor: unset;}
.region-category-chart-view .recharts-default-legend{justify-content: center; display: flex; gap: 10px; height: 45px; position: relative;}
.region-category-chart-view .recharts-legend-item-text{cursor: unset;}
.region-standard-chart-view .recharts-legend-item-text{cursor: unset;}
.region-standard-chart-view .recharts-default-legend{position: relative; top: -190px; left: 655px;}

.recharts-cartesian-axis-tick {font-size: 10px;}
.recharts-layer .recharts-label-list {font-size: 15px;}

.accumulate-color-guide.row{justify-content: center; flex-direction: row; grid-gap: 0 20px; display: flex; gap: 0 20px; padding-top: 60px; padding-left: 25px; position: relative; top: -116px;}
.accumulate-color-guide .color-circle{position: relative; top: -2px; flex: none; display: inline-flex; width: 12px; height: 12px; margin-right: 8px; border-radius: 10px; font-family: var(--nt-m); color: #575757; font-size: 12px;}
.accumulate-color-guide span{font-family: var(--nt-m); color: #575757; font-size: 12px; position: relative; top: -4px;}

.radial-bar .recharts-radial-bar-background{display: none;}
.language-content-chart-view .recharts-wrapper{left: -26px}
.recharts-layer .recharts-label-list{font-size: 13px;}

.region-category-chart-view .recharts-wrapper .recharts-legend-wrapper{left: 30px !important;}

/* .statistics-share {
@include flexYCenter;
position: absolute;
right: 30px;
white-space: nowrap;
height: 40px;
padding: 0 20px;
color: $white;
border-radius: 5px;
background-color: rgba(0, 0, 0, 0.5);
font-size: 14px;
z-index: 20;
top : -9px
} */

.custom-tooltip{border: 1px solid #D2DADF; gap: 10px; background-color:white; height:100px; display: flex; flex-direction: column; padding: 10px; box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)}

.language-content-chart-view-button{width: 115px; color: rgba(0, 0, 0, 0.87); box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);}

.use-detail-chart-view .recharts-default-legend{width: 182px; position: relative; bottom: 180px;}
.use-detail-chart-view .recharts-default-legend .recharts-legend-item{overflow: hidden; text-overflow: ellipsis !important; white-space: nowrap;}

.active-button{background-color:'#437aff'; color:'#8c8f96'}
.deactive-button{background-color:'#437afe'; color:'#fff'}

.language-content-legend{display: inline-flex; grid-gap: 10px; position: relative; left: 210px; top: -15px;}
.custom-legend-color .color-circle{flex:none;display:block;width:12px;height:12px;margin-right:8px;border-radius:10px;}

.language-content-bar-chart .recharts-default-legend{font-size: 12px; left: 260px; text-align: unset !important; bottom: 9px; display: inline-block; position: relative;}

.cal-month-start-color{transition: 2.0s; background-color: #5687fe; color: white !important; border-top-left-radius: 20px; border-bottom-left-radius: 20px;}
.cal-month-end-color{transition: 2.0s; background-color: #5687fe; color: white !important; border-top-right-radius: 20px; border-bottom-right-radius: 20px;}
.cal-year-month-between-color{transition: 2.0s; background-color: #b7cdff; color: white !important;}
.cal-month-between-color{transition: 2.0s; background-color: #b7cdff; color: white !important;}

.cal-month-active-color{background-color: #5687fe; color: white !important; border-radius: 20px;}

.language-average-chart-view{position: relative; left: -20px;}

.seoul-bubble{position: absolute; z-index: 10; transition: all 1s;}
.seoul-bubble:hover{transform: scale(1.1); background-color: #ef0707 !important;}

.gangwon-bubble{position: absolute; z-index: 10; transition: all 1s;}
.gangwon-bubble:hover{transform: scale(1.1); background-color: #ef0707 !important;}

.gyeongbuk-bubble{position: absolute; z-index: 10; transition: all 1s;}
.gyeongbuk-bubble:hover{transform: scale(1.1); background-color: #ef0707 !important;}

.chungbuk-bubble{position: absolute; z-index: 10; transition: all 1s;}
.chungbuk-bubble:hover{transform: scale(1.1); background-color: #ef0707 !important;}

.gyeonggi-bubble{position: absolute; z-index: 10; transition: all 1s;}
.gyeonggi-bubble:hover{transform: scale(1.1); background-color: #ef0707 !important;}

.incheon-bubble{position: absolute; z-index: 10; transition: all 1s}
.incheon-bubble:hover{transform: scale(1.1); background-color: #ef0707 !important;}

.sejong-bubble{position: absolute; z-index: 10; transition: all 1s;}
.sejong-bubble:hover{transform: scale(1.1); background-color: #ef0707 !important;}

.chungnam-bubble{position: absolute; z-index: 10; transition: all 1s;}
.chungnam-bubble:hover{transform: scale(1.1); background-color: #ef0707 !important;}

.daejeon-bubble{position: absolute; z-index: 10; transition: all 1s;}
.daejeon-bubble:hover{transform: scale(1.1); background-color: #ef0707 !important;}

.jeonbuk-bubble{position: absolute; z-index: 10; transition: all 1s;}
.jeonbuk-bubble:hover{transform: scale(1.1); background-color: #ef0707 !important;}

.daegu-bubble{position: absolute; z-index: 10; transition: all 1s;}
.daegu-bubble:hover{transform: scale(1.1); background-color: #ef0707 !important;}

.ulsan-bubble{position: absolute; z-index: 10; transition: all 1s;}
.ulsan-bubble:hover{transform: scale(1.1); background-color: #ef0707 !important;}

.gwangju-bubble{position: absolute; z-index: 10; transition: all 1s;}
.gwangju-bubble:hover{transform: scale(1.1); background-color: #ef0707 !important;}

.gyeongnam-bubble{position: absolute; z-index: 10; transition: all 1s;}
.gyeongnam-bubble:hover{transform: scale(1.1); background-color: #ef0707 !important;}

.busan-bubble{position: absolute; z-index: 10; transition: all 1s;}
.busan-bubble:hover{transform: scale(1.1); background-color: #ef0707 !important;}

.jeonnam-bubble{position: absolute; z-index: 10; transition: all 1s;}
.jeonnam-bubble:hover{transform: scale(1.1); background-color: #ef0707 !important;}

.jeju-bubble{position: absolute; z-index: 10; transition: all 1s;}
.jeju-bubble:hover{transform: scale(1.1); background-color: #ef0707 !important;}

#KR-11:hover{fill: #00a8da;}
#KR-26:hover{fill: #00a8da;}
#KR-27:hover{fill: #00a8da;}
#KR-28:hover{fill: #00a8da;}
#KR-29:hover{fill: #00a8da;}
#KR-30:hover{fill: #00a8da;}
#KR-31:hover{fill: #00a8da;}
#KR-41:hover{fill: #00a8da;}
#KR-42:hover{fill: #00a8da;}
#KR-43:hover{fill: #00a8da;}
#KR-44:hover{fill: #00a8da;}
#KR-45:hover{fill: #00a8da;}
#KR-46:hover{fill: #00a8da;}
#KR-47:hover{fill: #00a8da;}
#KR-48:hover{fill: #00a8da;}
#KR-49:hover{fill: #00a8da;}
#KR-50:hover{fill: #00a8da;}

.cFF58S9{background-color:#92d89c;}
.cFF58S3{background-color:#5687fe;}
.cFF58F9{background-color:#61c6c6;}
.cFFCF59{background-color:#5687fe;}
.cFF58F8{background-color:#5fc6c5;}
.cFFCF52{background-color:#5587fd;}
.c7C64FF{background-color:#eb7182;}
.c437AFD{background-color:#437AFD;}
.c59D3FA{background-color:#59D3FA;}
.c37DBC8{background-color:#37DBC8;}
.cFFD770{background-color:#FFD770;}
.cFF8652{background-color:#FF8652;}
.cFF667C{background-color:#FF667C;}
.cEB348C{background-color:#EB348C;}
.cFF28F1{background-color:#8886d8;}
.cFF2F2{background-color:#82ca9d;}

/* 박스 상단 탭 */
.tab-wrap, .tabs{display:flex;align-items:center;position:relative}
.tab-wrap p{font-family:var(--nt-m);color:#777;font-size:16px}
.tabs li{display:flex;align-items:center;justify-content:center;min-width:54px;height:33px;padding:0 12px;border-radius:4px;background-color:#D2DADF;font-family:var(--nt-m);color:#8C8F96;cursor:pointer}
.tabs li.active{background-color:#437AFD;color:#fff}
/* 지역별 현황 */
.map-hover{display:none;position:absolute;top:104px;left:240px;}
.map-default:hover .map-hover{display:block;}
.calendar-hover{position:absolute;top:38px;left:85px;z-index:3}
/* 시군구, 유형별 콘텐츠 */
.st-box-cnt.ccd-graph{width:429px}
.content-type-graph{width:301px}
.st-box-cnt .info-txt{margin-bottom:20px}
.ccd-graph, .content-type-graph{display:flex;flex-direction:column;justify-content:space-between;height:364px}
.ccd-graph{border-right:1px solid #D2DADF}
/* 표준분류체계별 콘텐츠 */
.standard-graph{width:100%;height:332px;border-top:1px solid #D2DADF}
.standard-graph .graph-color-guide{margin-left:30px}
/* 유형별 현황 */
.status-type-graph{flex-direction:row}
.status-type-graph .st-box-cnt{display:flex;flex-grow:1;padding:30px 20px}
.status-type-graph .st-box-cnt > div{display:flex;flex-direction:column;justify-content:space-between;height:367px}
.status-type-graph .left{width:585px}
.status-type-graph .right{width:635px}
.status-type-graph .st-box-tit{text-align:center}
/* 어권별 현황 */
.status-language .st-box-cnt{padding:30px}
.status-language .st-box-tit{text-align:center}
/* 콘텐츠 조회수 */
.content-views-graph{flex-direction:row}
.content-views-graph .st-box-cnt{display:flex;flex-grow:1;padding:30px 20px}
.content-views-graph .st-box-cnt .st-box-tit{text-align:left;margin-bottom:10px}
.content-views-graph .left{width:585px}
.content-views-graph .right{width:635px}
.content-views-graph .st-box-tit{text-align:center}
.left-graph-wrap{width:436px}
.right-graph-wrap{width:584px;margin-top:47px}

.btn-dt-carousel-next{width: 20px;height: 20px; right:-20px;background-image:url('/public/images/icon-item-arrow-blue-r.svg'); background-repeat: no-repeat; background-position: center;display: inline-block; position: absolute; right: 20%; bottom: -4px; cursor: pointer;}
.btn-dt-carousel-prev{width: 20px;height: 20px; left:-20px;background-image:url('/public/images/icon-item-arrow-blue-l.svg'); background-repeat: no-repeat; background-position: center;display: inline-block; position: absolute; left: 20%; bottom: -4px; cursor: pointer;}

/* join-background */
#join.main{margin-top:16px;
	background-image:url('../../public/images/join-background.png'), url('../../public/images/main_bg2.png');
    background-size: 52% auto, 48% auto;
    background-position: 123% 4%, -40% 23%;
    background-repeat: no-repeat;
	/* background:url('../images/main_bg.png')no-repeat center top;background-size:contain; */
}

/* 콘텐츠상세보기 스크롤  */
.left_rd_list{
	overflow-y: scroll;
	height: 100%;
	width: 33%;
}

.right_rd_view_content{
	overflow-y: scroll;
	height: 100%;
	width: 65%;
	margin-left: 30px;
	margin-bottom: 20px;
	margin-top: 12px;
}

.main-what-title-text{
	font-size: 25px;
	font-weight: bolder;
}

/* 회원가입 넘버 아이콘 */
.material-symbols-outlined {
  font-variation-settings:
  'FILL' 0,
  'wght' 400,
  'GRAD' 0,
  'opsz' 24
}

/* 전자우편-background */
#email.main{margin-top:16px;
	background-image:url('/public/images/main_bg1.jpg'), url('/public/images/main_bg2.png');
    background-size: 52% auto, 48% auto;
    background-position: 123% 4%, -40% 23%;
    background-repeat: no-repeat;
	/* background:url('/public/images/main_bg.png')no-repeat center top;background-size:contain; */
}
/* 이용약관-background */
#terms-of-use.main{margin-top:16px;
	background-image:url('/public/images/main_bg1.jpg'), url('/public/images/main_bg2.png');
    background-size: 52% auto, 48% auto;
    background-position: 123% 4%, -40% 23%;
    background-repeat: no-repeat;
	/* background:url('/public/images/main_bg.png')no-repeat center top;background-size:contain; */
}

.join_03 .join_content{width:605px}
.join_content_02{width:100%!important;text-align:left;}
.join_content_02 .join_item_01, 
.join_content_02 .join_item_02, 
.join_content_02 .join_item_03,
.join_content_02 .join_item_04{float:initial!important; display: inline-block; text-align:center;margin:10px 5px!important; width:140px;vertical-align: top;}

.join_content_03{margin-top:30px!important}

.join_content_04{width:100%!important; text-align:left;}
.join_content_04 .join_item_01,
.join_content_04 .join_item_02, 
.join_content_04 .join_item_03, 
.join_content_04 .join_item_04, 
.join_content_04 .join_item_05,
.join_content_04 .join_item_06{float:initial!important; display: inline-block; text-align:center; margin:10px 5px!important; width:140px;vertical-align: top;}

/* 회원가입 페이지 */
@media screen and (max-width: 1024px){
	
	#join.main{padding: 20px!important}
	.public-title .title{margin:0!important}

	.join_01{}
	.join_01 .join_title{float:initial!important;width:100%!important; text-align:left!important; margin-top:20px!important; height:auto!important}
	.join_01 .join_title div{margin:0!important}
	.join_01 .join_content{float:initial!important;width:100%!important; margin:0!important}
	
	.join_02{}
	.join_02 .join_title{float:initial!important;width:100%!important; text-align:left!important; margin:20px 0!important; height:auto!important}
	.join_02 div{margin:5px 0!important; width:100%!important;}
	
	.join_03{}
	.join_03 .size-txt16{width:100%!important}
	.join_03 .join_title{float:initial!important;width:100%!important; text-align:left!important; margin-top:20px!important; height:auto!important}
	.join_03 .join_title div{margin:0!important}
	.join_03 .join_content{float:initial!important;width:100%!important; margin:0!important}
	
	.login_bts{width:100%!important; display: inline-flex;flex-flow: column;gap: 10px;}
	.login_bts button{width:100%!important; margin:0!important}
}

/* 콘랩의 다양한 기능을 경험해보세요 */
section.nw_wcid{margin-top:128px;}	
section.nw_wcid h3{font-size: 54px;font-weight: 900;line-height:1.2;}
section.nw_wcid ul{display:flex;margin-top:76px;}
section.nw_wcid ul button{flex:1;background:#F0F0F5;margin-left:16px;padding:32px 32px 164px 32px;border-radius:32px;font-size: 18px;font-weight: 500;line-height: 27px;letter-spacing: -0.6px;word-break:break-all;background-size:140px;text-align: left;}
section.nw_wcid ul button:first-child{margin-left:0;}
section.nw_wcid ul button:nth-child(1){background:url('/public/images/Language_Translate.png')no-repeat 95% 85% #F0F0F5;}
section.nw_wcid ul button:nth-child(2){background:url('/public/images/wcid_Download_Image.png')no-repeat 95% 85% #F0F0F5;}
section.nw_wcid ul button:nth-child(3){background:url('/public/images/wcid_Search_File.png')no-repeat 95% 85% #F0F0F5;}
section.nw_wcid ul button:nth-child(4){background:url('/public/images/wcid_Profile_t.png')no-repeat 95% 85% #F0F0F5;}
section.nw_wcid ul button li span{font-weight: 800; font-size: 24px;}

@media (max-width: 1440px){
	section.nw_wcid ul{display:block;font-size:0;}
	section.nw_wcid ul button{display:inline-block;vertical-align:top;width:48%;margin:0 0 4% 0;}
	section.nw_wcid ul button:nth-child(2n){margin:0 0 4% 4%;}
	section.nw_wcid ul button li span{font-weight: 800; font-size: 24px;}
}
@media (max-width: 1024px){
	
	section.nw_wcid{margin-top:80px;}	
	section.nw_wcid h3{font-size:25px;}
	section.nw_wcid ul{margin-top:24px;border-radius:24px;overflow:hidden;}
	section.nw_wcid ul button{display:block;width:100%;margin:0;background-size:80px !important;padding:50px 50px 50px 100px;border-radius:0;border-top:1px solid #D7D7DB;font-size:13px;line-height:1.4;text-align: left;}
	section.nw_wcid ul button:first-child{border-top:0;}
	section.nw_wcid ul button:nth-child(2n){margin:0;padding:50px 120px 50px 50px;background-position:95% center !important;}
	section.nw_wcid ul button:nth-child(2n-1){background-position:5% center !important;padding-left:120px;}
	section.nw_wcid ul button li span{font-weight: 800; font-size: 20px;}
}
@media (max-width: 360px){
	section.nw_wcid ul button{display:block;width:100%;margin:0;background-size:80px !important;padding:24px 24px 24px 100px;border-radius:0;border-top:1px solid #D7D7DB;font-size:13px;line-height:1.4;text-align: left;}
	section.nw_wcid ul button:first-child{border-top:0;}
	section.nw_wcid ul button:nth-child(2n){margin:0;padding:24px 120px 24px 24px;background-position:95% center !important;}
	section.nw_wcid ul button:nth-child(2n-1){background-position:5% center !important;padding-left:120px;}
	section.nw_wcid ul button li span{font-weight: 800; font-size: 15px;}
}

/* 관광콘텐츠 유형 더보기 버튼 */
.detailsBtn {border-radius:6px;height:40px;margin-left: 16px;padding:0 16px;background:#2658E2;border:1px solid #2658E2;color:"#fff";float:right;color: white;}

/* 24.02.01 수정 */
body{overflow-x: hidden;}
.layout-gnb{position: relative; z-index: 1;}
.recommend_detail_page.detail_page .right{display: none;}
.list_wrap{flex:0 0 544px;max-width:544px;min-height:100%;padding:16px;background:#F0F0F5; overflow: scroll; height: 100%; -ms-overflow-style: none; scrollbar-width: none;}
.list_wrap .left.fixed .popup_box{position: relative;}
.list_wrap .left.fixed .popup_box .video_area{position: sticky; left: 0; right: 0; top: -188px; z-index: 999;}
.list_wrap .left.fixed .recommend_list_wrap{position: relative; top: 0; overflow-y: scroll;}
.list_wrap .left.fixed .recommend_list_wrap::-webkit-scrollbar {display: none;}
.list_wrap .left.fixed .recommend_list_wrap{-ms-overflow-style: none;scrollbar-width: none; }
.list_wrap .left.fixed .recommend_list{margin: 0; padding-bottom: 120px;}
div.video_area{width: 100%!important;}
div.video_area img{object-fit: cover;}
.recommend_detail_page .center{width: 100%;}
.list_wrap .left .share_copy_theme{top: 60px;}
.list_wrap .left.fixed .share_copy_theme{top:50px; right:0;}
.list_wrap .left.fixed div.video_area div.va_top_bts{padding-top: 11px; right: 15px; width:auto!important; top: 181px; z-index: 999; padding-right: 0!important;}
.list_wrap .left.fixed div.video_area div.va_top_bts button{margin-left: 0;}
@media (max-width: 1440px){
	div.recommend_detail_page div.center div.scroll_area{gap: 0 20px;}
	div.contents_detail_info div.left ul.registration_info li{margin: 5px auto; flex:none!important; max-width: none!important; width: 100%;}
}

@media (max-width: 1200px){
	div.recommend_detail_page div.center div.scroll_area{gap: 0;}
	div.contents_detail_info div.left ul.registration_info li{flex:none!important; max-width: none!important; width: 100%;}
	div.contents_detail_info{flex-direction: column;}
}

@media (max-width: 1024px){
	div.recommend_detail_page div.center button.btn_close{top: -13px;}
	div.recommend_detail_page div.center div.scroll_area > div.right{z-index: 99999;}
	.list_wrap .left{padding-top: 30px;}
	.list_wrap .left.fixed{padding-top: 0;}
	.list_wrap .left.fixed .recommend_list{padding-bottom: 50px;}
	.list_wrap .left.fixed div.video_area div.va_top_bts{padding-top: 24px;}
	.list_wrap{ max-width: none;}

	.list_wrap .left .share_copy_theme{top: 60px;}
	.list_wrap .left.fixed .share_copy_theme{top:60px; }

	.layout-gnb .logo img{width: 80%;}
	
}

@media (max-width: 414px){

	div.video_area div.va_top_bts button{margin-left: 0;}
	.list_wrap .left.fixed div.video_area div.va_top_bts{right: auto; left:19px; top:156px!important;}
	.list_wrap .left.fixed div.video_area div.va_top_bts button{width: 26px; height: 26px;}
	.list_wrap .left.fixed div.video_area div.va_info{bottom: -9px;}
	.share_copy_theme{right: 50px; padding: 0 10px; font-size: 12px;}

	.list_wrap .left.fixed .share_copy_theme{top:60px; right:auto;}
	.list_wrap .left.fixed div.video_area div.va_info h3{padding-right: 0;}

}


/* 2024.04.02 콘랩 배너 슬라이드 */
.main_visual_wrap h5 .box{display: flex; align-items: center;}
.main_visual_wrap div.main_login_box{display: block;}
.main_visual_wrap div.login_box{height: 100%;}
.main_visual_wrap .slick{position: relative; width: 100%;  height: 100%;}
.main_visual_wrap div.main_visual_inner{padding: 0!important; position: absolute!important; height: auto!important; width :auto!important; top: 54px;
    left: 59px;}
.main_visual_wrap div.statistics_area span.add_txt img{display: inline-block; margin: -3px 3px 0 0;}
.main_visual_wrap .slick-slide .slide_box{position: relative; height: 0; padding-top: 59%; border-radius: 35px; overflow: hidden;}
.main_visual_wrap .slick-slide .slide_box > div{position: absolute; top:0; left:0; right:0; bottom: 0;}
.main_visual_wrap .slick-slide .slide_box > div:hover{cursor: pointer;}
.main_visual_wrap .slick-slide video{position: static;}
.main_visual_wrap .slick-slide > div{position: relative; height: 100%;}
.main_visual_wrap .slick-slide a{display: block; width: 100%; height: 100%;}
.main_visual_wrap .slick-slide .img{width: 100%; height: 100%;}
.main_visual_wrap .slick-slide .pcImg{display: block;}
.main_visual_wrap .slick-slide .mImg{display: none;}
.main_visual_wrap .main_visual h2 img.icon{display: inline-block;}
.main_visual_wrap .slick-dots{display: flex!important; gap: 0 8px!important; justify-content: center; position: absolute; left: 50%!important; transform: translateX(-50%); bottom: 20px!important; z-index: 999;}
.main_visual_wrap .slick-dots li{width: auto!important; height: auto!important; margin: 0!important}
.main_visual_wrap .slick-dots li button{display: block!important; width: 8px!important; height: 8px!important; border-radius: 50px!important; padding: 0!important;  background-color: #fff!important; opacity: .2;}
.main_visual_wrap .slick-dots li button:before{display: none;}
.main_visual_wrap .slick-dots li.slick-active button{opacity: 1;}
.main_visual_wrap .slick-prev{left: 21px; z-index: 99;}
.main_visual_wrap .slick-prev:after {left: 10px; content: ''; width: 4px; height: 38px; background-color: #fff; position: absolute; top: 50%; transition: none; transform: translateY(-87%) rotate( 45deg);    border-radius: 50px 50px 0 0;}
.main_visual_wrap .slick-prev::before {content: ''; width: 4px; height: 38px; background-color: #fff; position: absolute; top: 50%; transform: translateY(-22%) rotate(-45deg);
    border-radius: 0 0 50px 50px; transition: none; opacity: 1}
.main_visual_wrap .slick-next{right: 21px;}
.main_visual_wrap .slick-next:after {left: 10px; content: ''; width: 4px; height: 38px; background-color: #fff; position: absolute; top: 50%; transform: translateY(-87%) rotate(-45deg);transition: none;    border-radius: 50px 50px 0 0;}
.main_visual_wrap .slick-next::before {content: ''; width: 4px; height: 38px; background-color: #fff; position: absolute; top: 50%; transform: translateY(-22%) rotate(45deg);
    border-radius: 0 0 50px 50px; transition: none; opacity: 1;}
.main_visual_wrap .slick-prev.slick-disabled:before,
.main_visual_wrap .slick-prev.slick-disabled::after,
.main_visual_wrap .slick-next.slick-disabled::before,
.main_visual_wrap .slick-next.slick-disabled::after{display: none!important;}

@media (max-width: 1440px){

	div.main_visual div.main_visual_inner{top: 3.5vw;	left: 3vw;}
	div.main_visual{border-radius: 2.2vw;}
	div.main_visual h2 {font-size: 3.1vw;	}
	div.statistics_area ul li h4 {line-height: normal; padding: .5vw 1.3vw; border-radius: 2.7vw; background: #fff; font-size: 1.2vw;}
	div.statistics_area ul li h5 {line-height: normal; padding: .5vw 1.1vw;	border-radius: 2.7vw;	font-size: 1.2vw !important;	border: 0.069vw solid #E6E6EB;}
	div.statistics_area ul li span {font-size: 2.5vw;}
	section.top_desc ul {margin: 2.7vw 0vw;}
	div.statistics_area ul li {margin-top: 1vw;}
	section.nw_wcid{margin-top: 9vw;}
	div.statistics_area ul li div.statistics_info{display: inline-block; margin-right: 32px;}
	div.statistics_area{margin-top: 10vw;}

}

@media (max-width: 1300px){

	div.statistics_area{margin-top: 10vw;}

}

@media (max-width: 1200px){

	.main_visual_wrap div.main_visual div.main_visual_inner{top: 3.2vw; left: 3.2vw;}
	div.statistics_area{margin-top: 9vw;}
	.main_visual_wrap div.statistics_area ul li {margin-top: 0.7vw;}
	.main_visual_wrap div.main_visual h2 {font-size: 3vw;}
	.main_visual_wrap div.statistics_area span.add_txt{margin-left: 31px; font-size: 15px;}
	.main_visual_wrap div.statistics_area span.add_txt img{margin: -2px 3px 0 0;}
	.main_visual_wrap div.statistics_area ul li span{font-size: 2.5vw;}
	.main_visual_wrap div.statistics_area ul li h5{height: auto; padding: 0.3vw 1vw; line-height: normal;}
	.main_visual_wrap div.statistics_area ul li div.statistics_info{margin: 0 32px .4vw 0;}
	.main_visual_wrap div.main_visual_inner {padding: 0 !important;	height: auto !important;width: auto !important;	top: 3.750vw; left: 4.097vw;}
	.main_visual_wrap div.login_box h2 {font-size: 2.2vw; letter-spacing: -0.014vw;	height: 5.556vw;}
	.main_visual_wrap section.top_desc ul li {flex: 1 1;	background: #f0f0f5; padding: 2.153vw; border-radius: 2vw; font-size: 1.25vw;	line-height: 1.8vw;
		letter-spacing: -0.042vw;word-break: break-all;	background-size: 9.722vw;}
	.main_visual_wrap  div.login_bts button {	background: #fff; border: 1px solid #FB620B; margin-bottom: 1.1vw; height: 3.6vw;
			border-radius: 0.556vw;	font-size: 1.250vw;	}
	.main_visual_wrap section.nw_wcid{margin-top: 99px;}
	.main_visual_wrap .slick-slide .slide_box{border-radius: 2vw;}


}

@media (max-width: 1150px){

	div.statistics_area{margin-top: 7.5vw;}

}

@media (max-width: 1100px){

	div.statistics_area{margin-top: 6.5vw;}

}

@media (max-width: 1050px){

	div.statistics_area{margin-top: 5.5vw;}

}

@media (max-width: 1024px){
	
	.main_visual_wrap div.main_visual h2{font-size: 4vw;}
	.main_visual_wrap div.statistics_area ul li h4{height: auto; line-height: normal; font-size: 1.6vw;}
	.main_visual_wrap div.statistics_area ul li h5{padding: 0.5vw 1.4vw; font-size: 1.6vw!important;}
	.main_visual_wrap div.statistics_area ul li span{font-size: 3.5vw;}
	.main_visual_wrap section.nw_wcid{margin-top: 10vw;}
	.main_visual_wrap div.statistics_area span.add_txt{font-size: 16px;}
	.main_visual_wrap div.statistics_area{margin-top: 18vw;}
	.main_visual_wrap div.main_visual div.main_visual_inner{top: 4.2vw; left: 5.1vw;}

}

@media (max-width: 768px){

	.main_visual_wrap div.statistics_area ul li h5{padding: 0.3vw 1.4vw; font-size: 1.8vw !important;}
	.main_visual_wrap div.statistics_area ul li h4{padding: .7vw 1.3vw; font-size: 1.8vw;}
	.main_visual_wrap div.statistics_area span.add_txt{font-size: 14px;}
	.main_visual_wrap div.statistics_area ul li h5 img{transform: scale(.8); image-rendering: -webkit-optimize-contrast;}
	.main_visual_wrap div.main_visual div.main_visual_inner{left: 4.6vw;}

}

@media (max-width: 660px){

	.main_visual_wrap div.statistics_area ul li h4{padding: .7vw 1.7vw; font-size: 1.9vw;}
	.main_visual_wrap div.statistics_area ul li h5{font-size: 2vw!important;}
	.main_visual_wrap div.statistics_area ul li{margin-top: 1.8vw;}
	.main_visual_wrap div.statistics_area{margin-top: 15vw;}

}

@media (max-width: 576px){

	.main_visual_wrap .slick-slide .pcImg{display: none;}
	.main_visual_wrap .slick-slide .mImg{display: block;}
	.main_visual_wrap div.main_visual h2{font-size: 6vw;}
	.main_visual_wrap div.statistics_area{margin-top: 25vw;}
	.main_visual_wrap div.statistics_area ul li h4{font-size: 2.5vw;}
	.main_visual_wrap div.statistics_area ul li h5{padding: .28vw 2vw .28vw 1.4vw; font-size: 2.4vw !important;}
	.main_visual_wrap .swiper-pagination{bottom: 14px!important;}
	.main_visual_wrap div.statistics_area ul li:nth-child(2){margin-top: 1vw;}
	.main_visual_wrap div.statistics_area span.add_txt{font-size: 15px;}
	.main_visual_wrap div.statistics_area ul li div.statistics_info{margin:0 0 1vw;}
	.main_visual_wrap div.statistics_area ul li span{font-size: 5vw;}
	div.statistics_area ul li div.statistics_info{display: block;}
	.main_visual_wrap div.main_visual div.main_visual_inner{left: 6.8vw; top: 7vw}
	.main_visual_wrap .slick-slide .slide_box{padding-top: 103%;}

}

@media (max-width: 480px){

	/* .main_visual_wrap div.statistics_area{margin-top: 8vw;} */
	.main_visual_wrap div.statistics_area ul li h5{padding: 0 1.4vw; font-size: 2.5vw !important;}
	.main_visual_wrap div.statistics_area ul li h5 img{margin: 0;}
	.main_visual_wrap div.statistics_area span.add_txt{font-size: 12px;}
	.main_visual_wrap div.statistics_area ul li h4{padding: .8vw 1.8vw .7vw 1.8vw;}
	.main_visual_wrap div.statistics_area span.add_txt{margin-left: 20px;}

}

@media (max-width: 414px){

	.main_visual_wrap div.statistics_area ul li h4,
	.main_visual_wrap div.statistics_area ul li h5{font-size: 3vw!important;}
	.main_visual_wrap div.statistics_area{margin-top: 19vw;}
	.main_visual_wrap div.statistics_area ul li h5{border-radius: 3.5vw;}
	.main_visual_wrap div.statistics_area ul li div.statistics_info{margin: 0 0 2vw;}

}

@media (max-width: 375px){

	.main_visual_wrap div.statistics_area{margin-top: 10vw;}
	.main_visual_wrap div.statistics_area ul li:nth-child(2),
	.main_visual_wrap div.statistics_area ul li:nth-child(3){display: block;}
	.main_visual_wrap div.main_visual h2{font-size: 6.6vw;}
	.main_visual_wrap div.statistics_area ul li span{font-size: 6vw;}    
	.main_visual_wrap div.statistics_area ul li h4,
	.main_visual_wrap div.statistics_area ul li h5{font-size: 3.3vw!important;}
	.main_visual_wrap div.statistics_area ul li h5{border-radius: 4vw;}
	.main_visual_wrap div.statistics_area ul li h4{padding: 1vw 3vw;}

}

@media (max-width: 350px){

	.main_visual_wrap div.statistics_area{margin-top: 9vw;}

}

/* masonry */
.my-masonry-grid {
	display: -webkit-box; /* Not needed if autoprefixing */
	display: -ms-flexbox; /* Not needed if autoprefixing */
	display: flex;
	 /* gutter size offset */
	width: auto;
	justify-content: center;
	min-height: 700px;

  }
  .my-masonry-grid_column {
	padding-left: 15px; /* gutter size */
	background-clip: padding-box;

  }
  
  /* Style your items */
  .my-masonry-grid_column > div { /* change div to reference your elements you put in <Masonry> */

	margin-bottom: 15px;
	position:relative; border: 1px solid black; border-radius: 15px; overflow:hidden;
  }

  /* 스와이퍼 스크롤 */
  .swiper-scrollbar-horizontal{
	cursor: pointer;
  }
  .reciente_container .swiper-scrollbar {
    opacity: 0; /* 기본적으로 숨김 */
    transition: ease-in-out 0.2s; /* 부드러운 전환 효과 */
}

.reciente_container .swiper-container:hover .swiper-scrollbar {
    opacity: 1; /* 마우스를 올렸을 때 보이게 */
}


/*공통*/
.btn-outline{padding: 10px 50px 9px;border-radius: 8px; background: #fff; font-size: 18px;  display: inline-block; border: 1px solid #F0F0F5}
.btn-default{padding: 10px 50px 9px;border-radius: 8px; background: #F0F0F5; font-size: 18px; display: inline-block; border: 1px solid #F0F0F5}
.btn-default:hover{background: #2658E2; border: 1px solid #2658E2; color: #fff}
.btn-memberjoin{padding: 15px 32px 14px;border-radius: 8px; background: #2658E2; font-size: 18px; color: #fff!important; display: inline-block}
.alert-text{padding: 8px 16px;align-items: center;gap: 16px;border-radius: 8px;background: #F7F7FC; color: #FF3B30; font-size: 16px; margin-top: 40px}
.mt-100{margin-top: 100px}
.mt-50{margin-top: 50px}
.mt-30{margin-top: 30px}
.mt-20{margin-top: 20px}
.mb-100{margin-bottom: 100px}
.mb-50{margin-bottom: 50px}
.mb-30{margin-bottom: 30px}
.mb-20{margin-bottom: 20px}

.pc{}
@media screen and (max-width: 1280px){
    .pc{display: none}
}
/*폼*/
input[type='checkbox'] + label::before {background:url('/public/images/checkbox_blank.png')no-repeat; width: 32px; height: 32px}
input[type='checkbox']:checked + label::before {background: url('/public/images/checkbox.png')no-repeat ;z-index:9; width: 32px; height: 32px}

/*jquery 커스터마이징*/
.ui-accordion .ui-accordion-header{display: inline-block;background: none;border: 0;position: absolute;right: 0; top: 0; padding: 0}
.ui-state-active .ui-icon, .ui-button:active .ui-icon{background: url('/public/images/expand_less.jpg') 0 0 no-repeat; width: 24px; height: 24px;}
.ui-icon, .ui-widget-content .ui-icon{background: url('/public/images/expand_more.jpg') 0 0 no-repeat; width: 24px; height: 24px;}
.ui-state-hover .ui-icon, .ui-state-focus .ui-icon, .ui-button:hover .ui-icon, .ui-button:focus .ui-icon {background: url('/public/images/expand_more.jpg') 0 0 no-repeat;}
.ui-accordion .ui-accordion-content{background: #F0F0F5; border-radius: 8px; padding: 16px; margin: 10px 0 30px; height: auto}
.ui-accordion .ui-accordion-content pre{padding: 0; margin: 0; width: 100%; text-wrap: balance;}

/*회원가입*/
.memberjoin-wrap{}
.memberjoin{align-items: flex-start; display: flex;margin: 0 auto;max-width: 1648px;padding: 0 16px;width: 90%;padding-top: 180px; font-size: 24px; font-weight: 700; color:#5E5E61;}
.memberjoin .memberjoin-title{flex: 0 0 256px;}
.memberjoin .memberjoin-title h1{font-size: 48px; font-weight: 900}

.memberjoin .memberjoin-content{margin-left: 65px;max-width: calc(100% - 2px); width: 100%}
.memberjoin .memberjoin-content h2{font-size: 36px;font-weight: 900}
.memberjoin .memberjoin-content .h2-comment{margin-top: 32px; border-bottom: 1px solid #D7D7DB; padding-bottom: 55px; margin-bottom: 55px}
.memberjoin .memberjoin-content .h2-comment li{margin-top: 32px}
.memberjoin .memberjoin-content .member-type{--bs-gutter-x: 1.5rem;--bs-gutter-y: 1rem;display: flex;flex-wrap: wrap; margin-right: calc(-.5 * var(--bs-gutter-x));margin-left: calc(-.5 * var(--bs-gutter-x)); margin-top: 48px}
.memberjoin .memberjoin-content .member-type li{flex: 1 0 0%;width: 100%;max-width: 100%;padding-right: calc(var(--bs-gutter-x) * .5);padding-left: calc(var(--bs-gutter-x) * .5);margin-top: var(--bs-gutter-y);}
.memberjoin .memberjoin-content .member-type li .member-box{padding: 40px; background: #E9EEFC; border-radius: 16px; position: relative}
.memberjoin .memberjoin-content .member-type li .member-box h3{font-size: 32px; font-weight: 900}
.memberjoin .memberjoin-content .member-type li .member-box .h3-comment{font-size: 18px; line-height: 160%; margin: 16px 0}
.memberjoin .memberjoin-content .member-type li .member-box img{position: absolute; right: 40px; top: 45px}
@media screen and (max-width: 1280px){
    .memberjoin{display: block; padding-top: 80px; width: 100%}
    .memberjoin .memberjoin-title{display: inline-block; width: 100%}
    .memberjoin .memberjoin-content{display: inline-block; width: 100%; margin-left: 0; margin-top: 50px}
    .memberjoin .memberjoin-content .member-type{display: block}
    .memberjoin .memberjoin-content .member-type li{display: block;}
    .memberjoin .memberjoin-content .member-type li .member-box{padding: 40px 20px 30px; text-align: center}
    .memberjoin .memberjoin-content .member-type li .member-box img{position: initial}
    .memberjoin .memberjoin-content .member-type li .member-box h3{text-align: left}
    .memberjoin .memberjoin-content .member-type li .member-box .h3-comment{text-align: left}
    .memberjoin .memberjoin-content .member-type li .member-box .btn-memberjoin{width: 100%; text-align: center}
}
/*가입단계*/
.location{background: #E9EEFC; height: 12px; position: relative}
.location .location-base{background: #2658E2; height: 12px; position: absolute; left: 0; width: 50%; z-index: 1;}
.location ul{max-width: 720px; margin: 0 auto; font-size: 0; display: flex; justify-content: flex-start; height: 12px; z-index: 2; position: relative; background: #E9EEFC}
.location ul::before{content:''; height: 8px; width: 8px; border: 2px solid #2658E2; background: #fff; position: absolute; border-radius: 20px; left: -5px; z-index: 2}
.location ul li.active{background: #2658E2; position: relative;}
.location ul li.active::after{content:''; height: 8px; width: 8px; border: 2px solid #2658E2; background: #fff; position: absolute; border-radius: 20px; right: -5px; z-index: 2}
.location-1 ul li.active{width: 50%}
.location-2 ul li.active{width: 33.33%}
.location-text ul{max-width: 760px; margin: 0 auto; font-size: 0; display: flex; justify-content: space-between; margin-top: 10px}
.location-text ul li{display: inline-block; font-size: 14px}

.step{display: block;margin: 0 auto;max-width: 830px;padding: 0 15px;width: 90%;padding-top: 60px; font-size: 24px; font-weight: 900; color:#5E5E61;}
.step .step-title{display: block; position: relative; padding-bottom: 20px; border-bottom: 1px solid #D7D7DB}
.step .step-title h2{font-size: 24px; color: #2658E2; display: block; font-weight: 900}
.step .step-title .point-comment{font-size: 14px; font-weight: 400; position: absolute; right: 0; top: 10px;}
.step .step-title .point-comment::before{content:''; display: inline-block; background: url('/public/images/point.png') center center/cover; position: absolute; left: -12px; width: 8px; height: 8px; top: 4px}
.step .step-content{display: block; width: 100%; font-size: 20px; font-weight: 700; }
.step .step-content .agree-list{position: relative; margin: 30px 0 10px}
.step .step-content .agree-list label{position: relative;}
.step .step-btns{margin:50px 0 100px; display: flex; justify-content: space-between;}
.required{content:''; display: inline-block; background: url('/public/images/point.png') center center/cover; position: absolute; right: -15px; width: 8px; height: 8px; top: 4px}
@media screen and (max-width: 1280px){
    .location ul{max-width: 80%;}
    .location-text ul{width: 93%}
    .step{width: 100%}
}


/*등록된기업or신규기업*/
.step .step-content .member-company{--bs-gutter-x: 1.5rem;--bs-gutter-y: 1rem;display: flex;flex-wrap: wrap; margin-right: calc(-.5 * var(--bs-gutter-x));margin-left: calc(-.5 * var(--bs-gutter-x)); margin-top: 290px; margin-bottom: 300px}
.step .step-content .member-company li{flex: 1 0 0%;width: 100%;max-width: 100%;padding-right: calc(var(--bs-gutter-x) * .5);padding-left: calc(var(--bs-gutter-x) * .5);margin-top: var(--bs-gutter-y);}
.step .step-content .member-company li .member-box{padding: 40px; background: #E9EEFC; border-radius: 16px; position: relative}
.step .step-content .member-company li .member-box img{position: absolute; right: 15px; top: 15px}
@media screen and (max-width: 1280px){
    .step .step-content .member-company{display: block; margin-top: 30px}
}
/*기업&단체정보*/
.step .step-content .info-list{position: relative; margin: 10px 0}
.step .step-content .info-list label{position: relative; font-size: 16px; font-weight: 600}
.step .step-content .info-list .input-box{width: 100%; padding: 14px 16px 15px 16px; border-radius: 8px; border: 1px solid #D7D7DB; margin: 10px 0}
.step .step-content .info-list .input-box.with-btn{width: 87%}
.step .step-content .info-list .input-box.auth-input{color: #A8A8AD; background: #E9EEFC}
.step .step-content .info-list .btn-search{width: 12%; margin-left: 1%; padding: 10px 16px 11px 16px; background: #2658E2; color: #fff;border-radius: 6px;margin-top: 10px}
.step .step-content .info-list .file-list{background: #E6E6EB; padding: 5px 16px; border-radius: 8px}
.step .step-content .info-list .file-list li{border-bottom: 1px solid #D7D7DB; padding: 15px 0; font-size: 16px}
.step .step-content .info-list .file-list li:last-child{border-bottom: 0}
.step .step-content .info-list .file-list li .state-text{color:#77777A; font-weight: 400; font-size: 14px; margin-left: 10px}
.step .step-content .info-list .file-list li .kb-text{color:#77777A; font-weight: 400; font-size: 14px; margin-left: 10px}
.step .step-content .info-list .file-btns{display: flex;justify-content: space-between;}
.btn-delete{display: inline-block; padding: 10px 16px 11px 16px;; border-radius: 6px; width: 12%;}
.btn-attach{display: inline-block; padding: 10px 16px 11px 16px;; border-radius: 6px; background: #2658E2; color: #fff; width: 12%;}
.add-text{font-size: 14px;font-weight: 500;}
.add-text b{color:#2658E2; font-size: 18px; font-weight: 700}
@media screen and (max-width: 1280px){
    .step .step-content .info-list .input-box.with-btn{width: 70%}
    .step .step-content .info-list .btn-search{width: 29%; margin-left: 1%;}
    .btn-delete{width: 29%}
    .btn-attach{width: 29%}
}

/*종료*/
.step .step-content .member-complete{width: 328px; text-align: center; margin: 100px auto}
.step .step-content .member-complete h4{font-size: 24px; font-weight: 900; margin-top: 20px}
.step .step-content .member-complete h4 b{color: #2658E2}
.step .step-content .member-complete .done-comment{font-size: 18px; padding: 16px; font-weight: 500; letter-spacing: -0.3px; background: #E9EEFC; border-radius: 8px}
.step .tomain-wrap {margin:80px 0 260px; display: flex; justify-content: center;}
.step .tomain-wrap .btn-tomain{width: 328px; display:inline-block; border-radius: 8px; background: #2658E2; color: #fff;padding: 20px 16px 19px 16px;}